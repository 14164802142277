import {
  Box,
  MenuItem,
  MenuList,
  Pagination,
  useMediaQuery,
  Typography,
  CircularProgress,
  Grid,
  Tooltip,
  IconButton,
  LinearProgress,
} from "@mui/material";
import CachedIcon from "@mui/icons-material/Cached";
import VerifiedIcon from "@mui/icons-material/Verified";

import { useSelector } from "react-redux";

const RequestsNotifications = (props) => {
  const currentSession = useSelector((state) => state.sessions.currentSession);
  const currentUser = useSelector((state) => state[currentSession].user);
  const notifications = useSelector((state) => state.notifications);
  const nocCreatingStatus = useSelector((state) => state.nocData.pdfProgress);

  const isMd = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const startHandlePageChange = async (event, page) => {
    props?.handlePageChange(event, page, "noc");
  };

  const handleDotStyle = (notificationStatus, foundStatus) => {
    const colorMap = {
      "More results": "#8B4513",
      "In Queue":
        foundStatus?.queue_data?.in_processing_window === false
          ? "#0a4e78"
          : "#e9c603",
      "In Progress": "#e9c603",
      Failed: "#e84034",
      Completed: "#10b981",
    };

    return colorMap[notificationStatus] || "#f4f4f4"; // Default color if not found
  };

  return (
    <>
      <MenuList
        disablePadding
        dense
        sx={
          isMd
            ? {
                width: "100vw",
                p: "8px",
                maxHeight: "20rem",
                overflow: "auto",
                "& > *": {
                  borderRadius: 1,
                },
              }
            : {
                width: "100%",
                p: "8px",

                maxHeight: "20rem",
                overflow: "auto",
                "& > *": {
                  borderRadius: 1,
                },
              }
        }
      >
        {notifications?.requests?.data?.length === 0 ? (
          <Typography align="center" variant="body2" fontWeight="bold" my={3}>
            Notifications will appear here once available
          </Typography>
        ) : (
          notifications?.requests?.data?.map((task, index) => {
            let tempProgress = null;
            const isCountyAvailable = currentUser.user.selectedCountiesNOC.some(
              (county) => county?.name === task?.property?.county
            );
            const foundStatus = nocCreatingStatus.find(
              (item) => item.id === task?.pp_task_id
            );

            if (task.status === "In Queue") {
              if (foundStatus?.queue_data?.in_processing_window === true) {
                tempProgress = 40;
              } else {
                const currPosition = foundStatus?.queue_data?.position;

                const progressBarValue = 100 / (currPosition + 1);

                tempProgress = progressBarValue;
              }
            }
            const isLoading = props?.notificationLoading === task?.pp_task_id;

            return (
              <MenuItem
                sx={{
                  mr: isMd ? "2rem" : 0,
                  borderBottom: isMd ? undefined : "3px solid #f3f3f3",
                  backgroundColor: isCountyAvailable ? "inherit" : "#f3f3f3",
                  cursor: Boolean(props?.notificationLoading)
                    ? "default"
                    : "pointer",
                  "&:hover": {
                    backgroundColor: Boolean(props?.notificationLoading)
                      ? "inherit"
                      : "#f3f3f3",
                  },
                }}
                disableRipple={true}
                key={index}
                onClick={() => {
                  if (!Boolean(props?.notificationLoading)) {
                    props?.handleNotificationClick(task);
                  }
                }}
              >
                {isLoading && (
                  <Box
                    sx={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      backgroundColor: "rgba(0, 0, 0, 0.2)",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      zIndex: 10, // Ensure it appears above other content
                      pointerEvents: "none", // Prevent interaction with underlying content
                    }}
                  >
                    <Typography variant="body2" color="textSecondary">
                      <CircularProgress size={25} />
                    </Typography>
                  </Box>
                )}
                <Grid container>
                  <Grid item xs={6}>
                    {new Date(task.created_at).toLocaleDateString("en-US", {
                      day: "2-digit",
                      month: "2-digit",
                      year: "numeric",
                    })}{" "}
                    {new Date(task.created_at).toLocaleTimeString("en-US", {
                      hour: "2-digit",
                      minute: "2-digit",
                      timeZone: props?.timezone,
                      hour12: true,
                    })}
                  </Grid>

                  <Grid item xs={6} sx={{ ml: "auto" }}>
                    {task?.new && (
                      <div
                        style={{
                          marginTop: "2px",
                          marginLeft: "auto",
                          minWidth: "0.8rem",
                          width: "0.8rem", // Set width
                          height: "0.8rem", // Set height
                          borderRadius: "50%", // To make it a circle
                          backgroundColor: handleDotStyle(
                            task?.status,
                            foundStatus
                          ),
                        }}
                      ></div>
                    )}
                  </Grid>
                  <Grid item xs={11}>
                    <Typography variant="body2" fontWeight="bold">
                      {task.property.address}
                    </Typography>
                  </Grid>
                  <Grid item xs={1}>
                    {task?.notary?.is_active ? (
                      <Tooltip title="Notarization in Progress" arrow>
                        <IconButton
                          className="notary-rotate"
                          variant="contained"
                          sx={{ color: "#5C2C06", cursor: "wait" }}
                        >
                          <CachedIcon width={25} />
                        </IconButton>
                      </Tooltip>
                    ) : task?.notary?.is_notarized ? (
                      <Tooltip title="Notarized Document" arrow>
                        <IconButton
                          sx={{ cursor: "auto" }}
                          variant="contained"
                          color="success"
                        >
                          <VerifiedIcon width={25} />
                        </IconButton>
                      </Tooltip>
                    ) : (
                      <div></div>
                    )}
                  </Grid>

                  <Grid item xs={12}>
                    {task.status !== "Waiting for Appraiser Website" && (
                      <LinearProgress
                        variant="determinate"
                        value={
                          tempProgress !== null
                            ? tempProgress
                            : task.progress_bar ?? 0
                        }
                        sx={{ mt: 1 }}
                      />
                    )}
                  </Grid>
                  {task?.notary?.is_active ? (
                    <Grid item xs={6}>
                      Notarization in Progress
                    </Grid>
                  ) : task?.notary?.is_notarized ? (
                    <Grid item xs={6}>
                      Notarized Document
                    </Grid>
                  ) : (
                    <Grid
                      item
                      xs={7}
                      sx={
                        task.status === "Waiting for Appraiser Website"
                          ? {
                              backgroundColor: "red",
                              padding: "3px",
                              borderRadius: "6px",
                              color: "white",
                              fontWeight: "bold",
                              textAlign: "center",
                            }
                          : isCountyAvailable
                          ? {}
                          : { color: "red", fontWeight: "bold" }
                      }
                    >
                      {task.status === "In Queue" ? (
                        <>
                          {foundStatus?.queue_data?.in_processing_window ===
                          true
                            ? "In Progress"
                            : `In Queue Position: ${foundStatus?.queue_data?.position}`}
                        </>
                      ) : isCountyAvailable ? (
                        task.status
                      ) : (
                        "County is Unselected"
                      )}
                    </Grid>
                  )}

                  <Grid item xs={5}>
                    <Typography variant="body2" align="right">
                      {task.property.county}
                    </Typography>
                  </Grid>
                </Grid>
              </MenuItem>
            );
          })
        )}
      </MenuList>
      <Box
        sx={{
          borderTop: "3px solid #d3d3d3",
          p: "8px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Pagination
          count={notifications?.requests?.pagination?.totalPages} // Number of pages
          page={notifications?.requests?.pagination?.page}
          color="primary"
          onChange={startHandlePageChange}
        />
      </Box>
    </>
  );
};

export default RequestsNotifications;
