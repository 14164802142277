import React, { useState, useEffect, useRef } from "react";
import { DateTime } from "luxon";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import TimezoneSelect from "../../layout/TimezoneSelect";
import { timezoneMappings } from "../../../data/timezones";
import { useSelector } from "react-redux";
import validator from "validator";
import { useFormik } from "formik";
import * as Yup from "yup";
import YupPassword from "yup-password";
import {
  Box,
  TextField,
  Typography,
  Grid2 as Grid,
  useMediaQuery,
  useTheme,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  InputAdornment,
  Tooltip,
  IconButton,
  Button,
  Collapse,
} from "@mui/material";
import TableOverview from "./TableOverview";
import NotarizedModal from "../../NotarizedModal";
import LottieAnimation from "../../../animation/LottieAnimation";
import ExpandableTimezone from "../../modals/ExpandableTimezone";
import SigneeRegisterInfo from "../../popover/SigneeRegisterInfo";
import { usStates } from "../../../data/counties";
import ScheduleContractorRonModal from "../../modals/ScheduleContractorRonModal";
import SessionWillExpireBox from "../../modals/SessionWillExpireBox";
import SessionForcedLogoutBox from "../../modals/SessionForcedLogoutBox";
import { useNavigate } from "react-router-dom";
import { authorityTypes, signerTitles } from "../../../data/ronData";

YupPassword(Yup);

const RegisterSignee = () => {
  const theme = useTheme();
  const currentSession = useSelector((state) => state.sessions.currentSession);
  const guestUser = useSelector((state) => state.guestSession);
  const sessionForcedLogout = useSelector(
    (state) => state.sessions.sessionForcedLogout
  );
  const scrollableElementRef = useRef(null);

  const mobileSize = useMediaQuery(theme.breakpoints.down("sm"));
  const tabletSize = useMediaQuery(theme.breakpoints.down("md"));
  const largeTabletSize = useMediaQuery(theme.breakpoints.down("lg"));
  const [pageLoading, setPageLoading] = useState(true);
  const [propertyDetails, setPropertyDetails] = useState([]);
  const [propertyImage, setPropertyImage] = useState([]);
  const [ids, setIds] = useState();
  const [documentModal, setDocumentModal] = useState(false);
  const [availableChecked, setAvailableChecked] = useState(true);
  const [scheduleTimeLeft, setScheduleTimeLeft] = useState(60);
  const [startTimer, setStartTimer] = useState(false);
  const [startInstantTimer, setStartInstantTimer] = useState(false);
  const [checkDateLoading, setCheckDateLoading] = useState(false);
  const [isAvailable, setIsAvailable] = useState(false);
  const [slots, setSlots] = useState([]);
  const [unavailableText, setUnavailableText] = useState("");
  const [instantTimeLeft, setInstantTimeLeft] = useState(60);
  const [checkInstantLoading, setCheckInstantLoading] = useState(false);
  const [isInstantAvailable, setIsInstantAvailable] = useState(false);
  const [instantError, setInstantError] = useState("");
  const [ownerTypes, setOwnerTypes] = useState({});
  const [fullOwners, setFullOwners] = useState([]);
  const [ownerships, setOwnerships] = useState([]);
  const [immediateRequested, setImmediateRequested] = useState(false);

  const [submitting, setSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState("");

  const [signerType, setSignerType] = useState("ORGANIZATION");

  const [instantAccepted, setInstantAccepted] = useState(false);

  const [tempDate, setTempDate] = useState(null);
  const [tempTime, setTempTime] = useState(null);
  const [furtherDateObj, setFurtherDateObj] = useState(null);

  const [typeInfo, setTypeInfo] = useState(false);
  const [titleInfo, setTitleInfo] = useState(false);
  const [infoText, setInfoText] = useState("");
  const [isFadingOut, setIsFadingOut] = useState(false);
  const [titleIsFadingOut, setTitleIsFadingOut] = useState(false);

  const [userTimezone, setUserTimeZone] = useState("America/New_York");
  const initialValuesSet = useRef(false);
  const [open, setOpen] = useState(false);
  const [timezoneOpen, setTimezoneOpen] = useState(false);
  const [initialFormValues, setInitialFormValues] = useState({
    owners: [],
    ownerAddress: "",
    fullOwnerSelect: [],
    ownersSelect: [],
    name: "",
    signatoryTitle: "",
    authority: "",
    ownerAddress: "",
    address: "",
    city: "",
    state: "",
    zipCode: "",
    dob: null,
    email: "",
    phone: "",
    idType: "",
    scheduledDate: null,
  });
  const [copyOwners, setCopyOwners] = useState([]);
  const isFieldsDisabled = () =>
    (immediateRequested && startInstantTimer) ||
    instantAccepted ||
    checkInstantLoading;
  const selectedRow = { task_id: guestUser?.registerData?.task_id };
  const [willExpireSoon, setWillExpireSoon] = useState(false);

  const navigate = useNavigate();

  const handleBeforeNavigation = (event) => {
    const confirmation = window.confirm(
      "If you navigate away from this page, your session will end, and you'll need to start over. Do you want to proceed?"
    );
    if (confirmation) {
      // If the user confirms, navigate them to the login page
      navigate("/login");
    } else {
      // If the user cancels, push the current state back to prevent navigation
      window.history.pushState(null, "", window.location.href);
      event.preventDefault();
    }
  };

  useEffect(() => {
    const handlePopState = (event) => handleBeforeNavigation(event);

    // Push the current state so the back button triggers `popstate`
    window.history.pushState(null, "", window.location.href);

    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  useEffect(() => {
    const checkWillExpire = () => {
      const isExpiring = sessionStorage.getItem("willExpire") === "true";
      setWillExpireSoon(isExpiring);
    };

    const handleExpireUpdate = () => {
      checkWillExpire();
    };

    // Add listener
    window.addEventListener("expireUpdate", handleExpireUpdate);

    // Initial check
    checkWillExpire();

    return () => {
      window.removeEventListener("expireUpdate", handleExpireUpdate);
    };
  }, []);

  useEffect(() => {
    if (tempDate && tempTime) {
      const dateObj = {
        day_of_month: tempDate.date(), // Get the day of the month
        month: tempDate.month() + 1, // Months are zero-indexed, add 1 for 1-12 format
        year: tempDate.year(),
        time: tempTime.format("HH:mm"), // Formats the time in 'HH:mm' format
      };

      // Convert tempDate and tempTime to the user's timezone
      const userDateTime = DateTime.fromObject(
        {
          year: tempDate.year(),
          month: tempDate.month() + 1, // Adjust for zero-indexed months
          day: tempDate.date(),
          hour: tempTime.hour(),
          minute: tempTime.minute(),
          second: tempTime.second(),
        },
        { zone: userTimezone }
      );

      // Check if the date and time is in the past
      if (userDateTime < DateTime.now().setZone(userTimezone)) {
        setUnavailableText("Selected datetime is in the past.");
        setFurtherDateObj(null);
      } else {
        setFurtherDateObj(dateObj);
        setUnavailableText("");
        formik.setFieldValue("scheduledDate", dateObj, true);
      }
    }
  }, [tempDate, tempTime, userTimezone]);
  useEffect(() => {
    if (typeInfo) {
      // First timeout to start the fade-out effect after 4500ms
      const timeout = setTimeout(() => {
        setIsFadingOut(true); // Start fading out
        // Second timeout to hide the alert and reset isFadingOut after the fade-out duration
        const fadeOutTimeout = setTimeout(() => {
          setTypeInfo(false); // Hide alert after fade-out completes
          setIsFadingOut(false); // Reset fading state
        }, 1000); // This duration should match the CSS fade-out duration

        return () => clearTimeout(fadeOutTimeout);
      }, 3500); // Show the alert for 5 seconds minus fade duration

      return () => clearTimeout(timeout);
    } else if (titleInfo) {
      const titleTimeout = setTimeout(() => {
        setTitleIsFadingOut(true); // Start fading out
        // Second timeout to hide the alert and reset isFadingOut after the fade-out duration
        const fadeOutTitleTimeout = setTimeout(() => {
          setTitleInfo(false); // Hide alert after fade-out completes
          setTitleIsFadingOut(false); // Reset fading state
        }, 1000); // This duration should match the CSS fade-out duration

        return () => clearTimeout(fadeOutTitleTimeout);
      }, 3500); // Show the alert for 5 seconds minus fade duration

      return () => clearTimeout(titleTimeout);
    }
  }, [typeInfo, titleInfo]);

  const extractOwnersData = (overviewData) => {
    const owners = [];
    const ownersSelect = [];
    let signerName = "";
    let ownerAddress = "";
    let ownerPhone = "";

    overviewData.forEach((item) => {
      if (
        item.label.startsWith("Owner") &&
        item.label !== "Owner Address" &&
        item.label !== "Ownership Type"
      ) {
        // Add to owners array
        owners.push(item);
        ownersSelect.push({ label: item.label, value: item.type });
      } else if (item.label === "Owner Address") {
        ownerAddress = item.value;
      } else if (item.label === "ownerPhone") {
        ownerPhone = item.value;
      }
    });

    for (const owner of owners) {
      if (owner.type === "PERSON") {
        signerName = owner.value.PERSON;
        setSignerType("PERSON");
        break;
      }
    }

    return {
      owners,
      ownersSelect,
      signerName,
      // firstPersonMiddleName,
      // firstPersonLastName,
      ownerAddress,
      ownerPhone,
    };
  };

  useEffect(() => {
    if (slots.length > 0 || isAvailable) {
      // Assuming `slots` is your state variable
      scrollToBottom();
    }
  }, [slots, isAvailable]);

  const scrollToBottom = () => {
    const scrollableElement = scrollableElementRef.current;
    if (scrollableElement) {
      scrollableElement.scrollTop = scrollableElement.scrollHeight;
    }
  };

  useEffect(() => {
    if (!initialValuesSet.current && guestUser?.registerData) {
      initialValuesSet.current = true;
      const { owners, ownersSelect, signerName, ownerAddress, ownerPhone } =
        extractOwnersData(
          guestUser?.registerData?.overview_data.affected_property
        );
      let address = "";
      let city = "";
      let state = "";
      let zipCode = "";

      if (guestUser?.registerData?.ownerAddressParts) {
        address = guestUser?.registerData?.ownerAddressParts?.address;
        city = guestUser?.registerData?.ownerAddressParts?.city;
        state = guestUser?.registerData?.ownerAddressParts?.state;
        zipCode = guestUser?.registerData?.ownerAddressParts?.zipCode;
      }
      setCopyOwners(owners);
      setOwnerships(guestUser?.registerData?.ownerships);
      setFullOwners(guestUser?.registerData?.ownersList);
      setInitialFormValues((prevState) => ({
        ...prevState,
        owners: owners,
        fullOwnerSelect: guestUser?.registerData?.ownersList,
        ownersSelect: ownersSelect,
        name: signerName,
        email: guestUser?.registerData?.ownerEmail,
        ownerAddress: ownerAddress,
        address: address,
        city: city,
        state: state,
        zipCode: zipCode,
        phone: ownerPhone,
      }));
      setPropertyDetails(guestUser?.registerData?.overview_data);

      setPropertyImage(guestUser?.registerData?.img);
      setIds(guestUser?.registerData?.id);

      setPageLoading(false);
    }
  }, [guestUser?.registerData]);

  const handlePhoneInputChange = (event) => {
    const { value } = event.target;

    // Existing code for phone number formatting
    const digitsOnly = value.replace(/\D/g, ""); // Remove non-numeric characters
    let formattedPhoneNumber = "";

    // Add dashes after 3rd and 6th digits
    for (let i = 0; i < digitsOnly.length; i++) {
      if (i === 3 || i === 6) {
        formattedPhoneNumber += "-";
      }
      formattedPhoneNumber += digitsOnly[i];
    }

    formik.setFieldValue("phone", formattedPhoneNumber);
  };

  const createValidationSchema = (availableChecked, isInstantAvailable) => {
    const ownerSchema = Yup.object().shape({
      value: Yup.object()
        .shape({
          PERSON: Yup.string(),
          ORGANIZATION: Yup.string(),
        })
        .test(
          "type-dependent-validation",
          "Invalid type dependent data",
          function (value) {
            // const { type } = this.parent;
            if (signerType === "PERSON") {
              if (!value.PERSON) {
                return this.createError({
                  path: `${this.path}.PERSON`,
                  message: "Individual Name is required",
                });
              }
            } else if (signerType === "ORGANIZATION") {
              if (!value.ORGANIZATION) {
                return this.createError({
                  path: `${this.path}.ORGANIZATION`,
                  message: "Organization name is required",
                });
              }
            }
            return true;
          }
        ),
    });

    const schemaFields = {
      name: Yup.string()
        .min(3, "Name is too short")
        .required("Name is required")
        .test(
          "two-parts", // Name of the test
          "Please enter at least first and last name, each with at least 3 characters", // Error message
          (value) => {
            // Split the name into parts based on spaces
            const parts = value ? value.split(" ").filter((part) => part) : [];
            // Check that there are at least two parts
            if (parts.length < 2) {
              return false;
            }
            // Check the first and last parts for minimum length of 3 characters
            const firstName = parts[0];
            const lastName = parts[parts.length - 1];
            return firstName.length >= 3 && lastName.length >= 3;
          }
        ),
      signatoryTitle:
        signerType === "PERSON"
          ? Yup.string()
          : Yup.string()
              .min(3, "Signer Title is too short")
              .required("Signer Title is required"),
      authority:
        signerType === "PERSON"
          ? Yup.string()
          : Yup.string()
              .min(3, "Type of Authority is too short")
              .required("Type of Authority is required"),
      ownerAddress: Yup.string()
        .min(5, "Owner address is too short")
        .required("Owner address is required"),
      address: Yup.string()
        .min(3, "Address is too short")
        .required("Address is required"),
      city: Yup.string()
        .min(3, "City is too short")
        .required("City is required"),
      state: Yup.string()
        .min(3, "State is too short")
        .required("State is required"),
      zipCode: Yup.string()
        .required("Zip Code is required")
        .test("zipCode", "Invalid zip code", function (value) {
          return validator.isPostalCode(value, "US");
        }),
      owners: Yup.array().of(ownerSchema),
    };

    return Yup.object(schemaFields);
  };
  const formik = useFormik({
    key: ids,
    enableReinitialize: true,
    initialValues: initialFormValues,
    validationSchema: createValidationSchema(
      availableChecked,
      isInstantAvailable
    ),
    validateOnBlur: true, // Validate only when an input field is blurred
    validateOnChange: false,
    onSubmit: async (values, helpers) => {},
  });

  const formikRef = useRef(formik);
  formikRef.current = formik;

  useEffect(() => {
    if (!formik?.values?.owners?.length) {
      formik.setFieldValue("owners", copyOwners);
    }
  }, [formik?.values?.owners]);

  // Effect for the countdown timer
  useEffect(() => {
    let intervalId;

    if (startTimer && scheduleTimeLeft > 0) {
      intervalId = setInterval(() => {
        setScheduleTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
    }

    return () => clearInterval(intervalId);
  }, [startTimer, scheduleTimeLeft]);

  useEffect(() => {
    let intervalIdInstant;

    if (startInstantTimer && instantTimeLeft > 0) {
      intervalIdInstant = setInterval(() => {
        setInstantTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
    }

    return () => clearInterval(intervalIdInstant);
  }, [startInstantTimer, instantTimeLeft]);

  useEffect(() => {
    if (
      submitError !== "" &&
      submitError !==
        "** Warning ** You should not leave the page or refresh the page while we are assigning you a notary. The process will take less than a minute."
    ) {
      setSubmitting(false);
      const interv = setInterval(() => {
        setSubmitError("");
      }, 5000);
      return () => clearInterval(interv);
    }
  }, [submitError]);

  return (
    <>
      {pageLoading ? (
        <>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              height: "100vh",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            {tabletSize ? (
              <>
                <Box sx={{ mb: 2 }}>
                  <img
                    style={{
                      width: "10rem",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                    alt="Permit Rockstar Logo"
                    src="/img/website_img/PermitRockstar.png"
                  />
                </Box>
                <LottieAnimation />
              </>
            ) : (
              <CircularProgress color="warning" size="10rem" />
            )}
          </Box>
        </>
      ) : (
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "100vh", // Full viewport height
              overflow: "hidden", // Prevents scrolling outside the main box
              backgroundImage: tabletSize
                ? undefined
                : "url('/img/website_img/homeImg.jpeg')",
              backgroundRepeat: "repeat",
              justifyContent: "center",
              alignItems: tabletSize ? undefined : "center",
            }}
          >
            {sessionForcedLogout && (
              <SessionForcedLogoutBox
                onNavigateToLogin={() => {
                  navigate("/login");
                }}
              />
            )}
            {/* Top Fixed Area */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                pt: 1,
                pb: 0,
                maxWidth: "90rem",
                ml: "auto",
                mr: "auto",
                backgroundColor: "#fff",
                zIndex: 1,
                width: "100%",
                position: "relative",
                boxShadow: "2px 2px 14px 1px #d3d3d3",
              }}
            >
              <img
                style={{
                  width: mobileSize ? "90%" : tabletSize ? "8rem" : "8rem",
                  maxWidth: mobileSize ? "4rem" : undefined,
                }}
                alt="Permit Rockstar Logo"
                src="/img/website_img/PermitRockstar.png"
              />
              {!mobileSize && (
                <Box
                  sx={{
                    position: "absolute",
                    top: tabletSize ? 18 : 27,
                    right: tabletSize ? 10 : 20,
                  }}
                >
                  <TimezoneSelect
                    options={timezoneMappings}
                    timezone={userTimezone}
                    setTimezone={setUserTimeZone}
                    changeLocal={true}
                    specificWidth={largeTabletSize ? "11rem" : undefined}
                    specialDisable={
                      isInstantAvailable &&
                      !checkInstantLoading &&
                      !instantAccepted
                    }
                    specialDisabled2={
                      formik.values.scheduledDate ||
                      slots.length !== 0 ||
                      (instantError !== "" &&
                        instantError !==
                          "No available notaries at this moment.") ||
                      checkDateLoading
                    }
                  />
                </Box>
              )}
              <Box sx={{ px: 1 }}>
                <Typography
                  color="primary"
                  variant={mobileSize ? "h6" : "h4"}
                  sx={{ mb: mobileSize ? 1 : 2, mt: 1 }}
                  align="center"
                >
                  Schedule Your Remote Online Notarization (RON) Session
                </Typography>

                {mobileSize && (
                  <ExpandableTimezone
                    open={timezoneOpen}
                    setOpen={setTimezoneOpen}
                    timezoneMappings={timezoneMappings}
                    userTimezone={userTimezone}
                    setUserTimeZone={setUserTimeZone}
                    largeTabletSize={largeTabletSize}
                    isInstantAvailable={isInstantAvailable}
                    checkInstantLoading={checkInstantLoading}
                    instantAccepted={instantAccepted}
                    formik={formik}
                    slots={slots}
                    instantError={instantError}
                    checkDateLoading={checkDateLoading}
                  />
                )}
              </Box>
            </Box>

            {/* Scrollable Content Area */}
            <Box
              sx={{
                flexGrow: 2,
                overflowY: "auto",
                paddingRight: "calc(100% - 100vw)",
                py: 2,
                bgcolor: "background.paper",
                maxWidth: "90rem",
                width: "100%",
                ml: "auto",
                mr: "auto",
                mb: "70px",
              }}
            >
              <TableOverview
                propertyDetails={propertyDetails}
                setDocumentModal={setDocumentModal}
                ownerTypes={ownerTypes}
                setOwnerTypes={setOwnerTypes}
                formik={formik}
                fullOwners={fullOwners}
                copyOwners={copyOwners}
                setCopyOwners={setCopyOwners}
                ownerships={ownerships}
                isFieldsDisabled={isFieldsDisabled}
                setSignerType={setSignerType}
                mobileSize={mobileSize}
                tabletSize={tabletSize}
                largeTabletSize={largeTabletSize}
              />
              <Box
                sx={{
                  borderBottom: largeTabletSize ? "none" : "3px solid #f3f3f3",
                  pb: 1,
                  mb: largeTabletSize ? 0 : 2,
                  mt: 2,
                  mx: mobileSize ? 0.5 : 2,
                }}
              >
                <Typography variant={mobileSize ? "h5" : "h4"} align="center">
                  Property Owner or Authorized Agent
                </Typography>
                <Typography
                  sx={{
                    mt: largeTabletSize ? 0.2 : 1,
                  }}
                  variant={mobileSize ? "body2" : "body1"}
                  align="center"
                >
                  Please Enter the Information of the Person Signing the
                  Document
                </Typography>
              </Box>
              <Box sx={{ px: mobileSize ? 0.5 : 2, pb: 4 }}>
                <Grid container spacing={largeTabletSize ? 1 : 2}>
                  <Grid size={{ xs: 12, lg: 4 }}>
                    <TextField
                      disabled={isFieldsDisabled()}
                      error={Boolean(formik.touched.name && formik.errors.name)}
                      helperText={formik.touched.name && formik.errors.name}
                      name="name"
                      label="Signer Name"
                      variant="filled"
                      fullWidth
                      onBlur={formik.handleBlur}
                      value={formik.values.name}
                      onChange={(e) => {
                        formik.handleChange(e);
                      }}
                    />
                  </Grid>
                  <Grid size={{ xs: 12, lg: 4 }} sx={{ position: "relative" }}>
                    <FormControl fullWidth variant="filled">
                      <InputLabel>Signer Title</InputLabel>
                      <Select
                        error={Boolean(
                          formik.touched.signatoryTitle &&
                            formik.errors.signatoryTitle
                        )}
                        disabled={isFieldsDisabled()}
                        value={formik.values.signatoryTitle}
                        name="signatoryTitle"
                        variant="filled"
                        fullWidth
                        onBlur={formik.handleBlur}
                        onChange={(e) => {
                          if (e.target.value === "None") {
                            formik.setFieldValue("signatoryTitle", "");
                          } else {
                            formik.handleChange(e);
                          }
                        }}
                        MenuProps={{
                          PaperProps: {
                            style: {
                              marginTop: "1.5px",
                              maxHeight: 150, // Set the desired max height for the dropdown menu
                              width: "auto",
                            },
                          },
                        }}
                        sx={{
                          maxWidth: "100%", // Set the desired max-width for the input element
                          "& .MuiTypography-body2": {
                            whiteSpace: "wrap", // Prevent text from wrapping or shrinking
                          },
                        }}
                      >
                        {signerTitles?.map(function (item, i) {
                          return (
                            <MenuItem key={i} value={item}>
                              {item}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                    <FormHelperText sx={{ color: "red" }}>
                      {formik.touched.signatoryTitle &&
                        formik.errors.signatoryTitle}
                    </FormHelperText>
                    {titleInfo && (
                      <SigneeRegisterInfo
                        infoText={infoText}
                        isFadingOut={titleIsFadingOut}
                        top={mobileSize ? -180 : largeTabletSize ? -80 : -145}
                      />
                    )}
                    <Box sx={{ position: "absolute", right: 30, top: 10 }}>
                      <IconButton
                        onClick={() => {
                          if (typeInfo) {
                            setTypeInfo(false);
                          }
                          if (titleInfo) {
                            setTitleInfo(false);
                          }
                          if (!titleInfo) {
                            setTitleInfo(true);
                            setInfoText(
                              "Please provide the job title of the person in the organization that owns the property, and who will sign the documents (e.g., Owner, President, Manager). If signing as an individual's agent specify your authority (like Agent or Trustee). Leave blank if signing for yourself."
                            );
                          }
                        }}
                        sx={{ pr: 0 }}
                      >
                        <HelpOutlineIcon color="success" />
                      </IconButton>
                    </Box>
                  </Grid>

                  <Grid size={{ xs: 12, lg: 4 }} sx={{ position: "relative" }}>
                    <FormControl fullWidth variant="filled">
                      <InputLabel>Type of authority</InputLabel>
                      <Select
                        error={Boolean(
                          formik.touched.authority && formik.errors.authority
                        )}
                        disabled={isFieldsDisabled()}
                        value={formik.values.authority}
                        name="authority"
                        variant="filled"
                        fullWidth
                        onBlur={formik.handleBlur}
                        onChange={(e) => {
                          if (e.target.value === "None") {
                            formik.setFieldValue("authority", "");
                          } else {
                            formik.handleChange(e);
                          }
                        }}
                        MenuProps={{
                          PaperProps: {
                            style: {
                              marginTop: "1.5px",
                              maxHeight: 150, // Set the desired max height for the dropdown menu
                              width: "auto",
                            },
                          },
                        }}
                        sx={{
                          maxWidth: "100%", // Set the desired max-width for the input element
                          "& .MuiTypography-body2": {
                            whiteSpace: "wrap", // Prevent text from wrapping or shrinking
                          },
                        }}
                      >
                        {authorityTypes?.map(function (item, i) {
                          return (
                            <MenuItem key={i} value={item}>
                              {item}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                    <FormHelperText sx={{ color: "red" }}>
                      {formik.touched.authority && formik.errors.authority}
                    </FormHelperText>
                    {typeInfo && (
                      <SigneeRegisterInfo
                        infoText={infoText}
                        isFadingOut={isFadingOut}
                        top={mobileSize ? -130 : largeTabletSize ? -80 : -125}
                      />
                    )}
                    <Box sx={{ position: "absolute", right: 30, top: 10 }}>
                      <IconButton
                        onClick={() => {
                          if (titleInfo) {
                            setTitleInfo(false);
                          }
                          if (typeInfo) {
                            setTypeInfo(false);
                          }
                          if (!typeInfo) {
                            setTypeInfo(true);
                            setInfoText(
                              "This is the authority under which you are signing this document. It could be as an Agent, trustee, or anything else on behalf of the Owner. Leave blank if signing for yourself."
                            );
                          }
                        }}
                        sx={{ pr: 0 }}
                      >
                        <HelpOutlineIcon color="success" />
                      </IconButton>
                    </Box>
                  </Grid>
                  <Grid size={{ xs: 12, lg: 3 }}>
                    <TextField
                      disabled={isFieldsDisabled()}
                      error={Boolean(
                        formik.touched.address && formik.errors.address
                      )}
                      helperText={
                        formik.touched.address && formik.errors.address
                      }
                      name="address"
                      label="Address"
                      variant="filled"
                      fullWidth
                      onBlur={formik.handleBlur}
                      value={formik.values.address}
                      onChange={(e) => {
                        formik.handleChange(e);
                      }}
                    />
                  </Grid>
                  <Grid size={{ xs: 12, lg: 3 }}>
                    <TextField
                      disabled={isFieldsDisabled()}
                      error={Boolean(formik.touched.city && formik.errors.city)}
                      helperText={formik.touched.city && formik.errors.city}
                      name="city"
                      label="City"
                      variant="filled"
                      fullWidth
                      onBlur={formik.handleBlur}
                      value={formik.values.city}
                      onChange={(e) => {
                        formik.handleChange(e);
                      }}
                    />
                  </Grid>
                  <Grid size={{ xs: 12, lg: 3 }}>
                    <FormControl
                      variant="filled"
                      fullWidth
                      error={Boolean(
                        formik.touched.state && formik.errors.state
                      )}
                    >
                      <InputLabel
                        sx={{
                          color:
                            formik.touched.state && formik.errors.state
                              ? "#f04438"
                              : "auto",
                          maxWidth: "90%", // Limit the label's width
                          whiteSpace: "nowrap", // Prevent label from wrapping
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                        id="fullOwnerSelect-label"
                      >
                        State
                      </InputLabel>
                      <Select
                        disabled={isFieldsDisabled()}
                        error={Boolean(
                          formik.touched.state && formik.errors.state
                        )}
                        fullWidth
                        name="state"
                        value={formik.values.state}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        MenuProps={{
                          PaperProps: {
                            style: {
                              marginTop: "1.5px",
                              maxHeight: 150, // Set the desired max height for the dropdown menu
                              width: "auto",
                              maxWidth: "100%",
                            },
                          },
                        }}
                        sx={{
                          maxWidth: "100%", // Set the desired max-width for the input element
                          "& .MuiTypography-body2": {
                            whiteSpace: "wrap", // Prevent text from wrapping or shrinking
                          },
                        }}
                      >
                        {usStates.map((item, index) => (
                          <MenuItem
                            key={`${item.name}-${index}`}
                            value={item.name}
                          >
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText sx={{ color: "#f04438" }}>
                        {formik.touched.state && formik.errors.state}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid size={{ xs: 12, lg: 3 }}>
                    <TextField
                      disabled={isFieldsDisabled()}
                      error={Boolean(
                        formik.touched.zipCode && formik.errors.zipCode
                      )}
                      helperText={
                        formik.touched.zipCode && formik.errors.zipCode
                      }
                      name="zipCode"
                      label="Zip Code"
                      variant="filled"
                      fullWidth
                      onBlur={formik.handleBlur}
                      value={formik.values.zipCode}
                      onChange={(e) => {
                        formik.handleChange(e);
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box>
                <ScheduleContractorRonModal
                  open={open}
                  setOpen={setOpen}
                  selectedRow={selectedRow}
                  setRonStarted={false}
                  setSnackOpen={null}
                  ownerData={formik?.values}
                  ownerZone={userTimezone}
                  mobileSize={mobileSize}
                />
              </Box>
            </Box>

            {/* Bottom Fixed Area */}
            <Box
              sx={{
                py: 0.5,
                position: "fixed",
                bgcolor: "#fff",
                maxWidth: "90rem",
                ml: "auto",
                mr: "auto",
                bottom: 0,
                left: 0,
                right: 0,
                opacity: 1,
                zIndex: 1100, // Higher index to ensure it's above other content
              }}
            >
              <Box
                sx={{
                  px: mobileSize ? 1 : 2,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  pb: 2,
                  boxShadow: "2px 2px 14px 1px #d3d3d3",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    mt: 2,
                    mb: 0,
                  }}
                >
                  {submitError ? (
                    <Typography
                      sx={{ mt: 1, mb: 1 }}
                      fontWeight="bold"
                      align="center"
                      variant={tabletSize ? "body2" : "h6"}
                      color="error"
                    >
                      {submitError}
                    </Typography>
                  ) : (
                    <Button
                      onClick={async () => {
                        const errors = await formik.validateForm();
                        // Check if the errors object is empty
                        if (!errors || Object.keys(errors).length === 0) {
                          setOpen(true);
                        } else {
                          // Iterate through the errors and log them
                          for (const [field, error] of Object.entries(errors)) {
                            await formik.setFieldTouched(field, true, true);
                          }
                          setSubmitError(
                            "Some fields are incomplete. Please review the form carefully before proceeding."
                          );
                        }
                      }}
                      color="success"
                      sx={{ width: "20rem" }}
                      size={mobileSize ? "small" : "large"}
                      variant="contained"
                    >
                      Schedule your session
                    </Button>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
          <NotarizedModal
            notarizedModal={documentModal}
            setNotarizedModal={setDocumentModal}
            pdfImg={propertyImage?.[0]}
            pdfImg2={propertyImage?.[1]}
            previewImg=""
            preview={false}
            largeTabletSize={largeTabletSize}
            mobileSize={mobileSize}
            tabletSize={tabletSize}
            type={"registerSignee"}
          />
          {currentSession && (
            <SessionWillExpireBox
              willExpireSoon={willExpireSoon}
              setWillExpireSoon={setWillExpireSoon}
            />
          )}
        </>
        //! ////////////////////////////////////////////////////////////
      )}
    </>
  );
};

export default RegisterSignee;
