import { Box, IconButton, Modal, Typography } from "@mui/material";
import React, { useState } from "react";
import { useSwipeable } from "react-swipeable";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Magnifier from "../customComponents/Magnifier";
import CloseIcon from "@mui/icons-material/Close";

const ReviewCreatedFormsModal = ({
  open,
  setOpen,
  selectedRow,
  docNotaryTypeValue,
}) => {
  const [activeStep, setActiveStep] = useState(0);

  const filteredDocuments = Object.entries(selectedRow?.documents || {}).filter(
    ([key, doc]) => {
      if (docNotaryTypeValue === 0) return true; // Include all documents
      if (docNotaryTypeValue === 1)
        return key === "noc" || key === "noc_affidavit"; // Only "noc" documents
      if (docNotaryTypeValue === 2) return key !== "noc"; // Exclude "noc" documents
      return false;
    }
  );

  // Flatten the array of signed URLs from all filtered documents
  const totalImages = filteredDocuments.reduce((count, [, doc]) => {
    return count + (doc?.signed?.length || 0);
  }, 0);

  const handleNext = () => {
    if (activeStep < totalImages - 1) {
      setActiveStep(activeStep + 1);
    }
  };

  const handleBack = () => {
    if (activeStep > 0) {
      setActiveStep(activeStep - 1);
    }
  };

  const handlers = useSwipeable({
    onSwipedLeft: handleNext,
    onSwipedRight: handleBack,
    preventScrollOnSwipe: true,
    trackMouse: true,
  });

  return (
    <Modal
      open={open}
      onClose={() => {
        setOpen(false);
        setActiveStep(0);
      }}
      sx={{ overflow: "auto" }}
    >
      <Box
        sx={{
          overflowY: "auto",
          position: "relative",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          height: "95%",
          maxWidth: "70rem",
          bgcolor: "background.paper",
          borderRadius: "18px",
          boxShadow: 24,
          px: 4,
          py: 2,
          animation: "fadeIn 0.5s ease-in-out",
        }}
      >
        <Box sx={{ position: "relative" }} {...handlers}>
          <Box sx={{ position: "absolute", right: 10, top: 10, zIndex: 999 }}>
            <IconButton
              onClick={() => {
                setOpen(false);
                setActiveStep(0);
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Box sx={{ cursor: "default" }}>
            <Box sx={{ width: "100%", overflow: "hidden" }}>
              {filteredDocuments?.flatMap(([key, doc], docIndex) => {
                const selectedDocument = selectedRow.selected_documents?.find(
                  (item) => item.name === key
                );

                const label = selectedDocument?.label;

                // Calculate cumulative offset for the current document
                const cumulativeOffset = filteredDocuments
                  .slice(0, docIndex) // Take all previous documents
                  .reduce(
                    (count, [, prevDoc]) =>
                      count + (prevDoc?.signed?.length || 0),
                    0
                  );

                return doc?.signed?.length > 0
                  ? doc?.signed?.map((url, index) =>
                      activeStep === cumulativeOffset + index ? ( // Compare with cumulative index
                        <React.Fragment key={`${docIndex}-${index}`}>
                          <Box
                            sx={{
                              width: "100%",
                              zIndex: 999,
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <Typography variant="h5" color="primary">
                              {label} Form
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              width: "100%",
                              zIndex: 999,
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <Typography variant="h5" color="primary">
                              Page {index + 1} of {doc?.signed?.length}
                            </Typography>
                          </Box>

                          <Box
                            key={`${docIndex}-${index}`}
                            sx={{
                              p: 2,
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              maxHeight: "83vh",
                            }}
                          >
                            <Magnifier
                              src={url}
                              lensSize={800}
                              zoomFactor={3}
                              customStyle={{
                                maxWidth: "100%", // Restrict width to 100% of container
                                maxHeight: "82vh", // Restrict height to 75% of the viewport height
                                objectFit: "contain", // Ensure image scales properly
                                boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                              }}
                            />
                          </Box>
                        </React.Fragment>
                      ) : null
                    )
                  : null;
              })}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Typography sx={{ fontWeight: "bold" }} color="primary">
                  Left click and hold over any part of the document to use a
                  magnifying glass to inspect the document
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: 0,
              display: activeStep === 0 ? "none" : undefined,
            }}
          >
            <IconButton onClick={handleBack}>
              <ArrowBackIcon sx={{ fontSize: "40px" }} />
            </IconButton>
          </Box>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              right: 0,
              display:
                totalImages - 1 === activeStep || totalImages === 0
                  ? "none"
                  : undefined,
            }}
          >
            <IconButton onClick={handleNext}>
              <ArrowForwardIcon sx={{ fontSize: "40px" }} />
            </IconButton>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default ReviewCreatedFormsModal;
