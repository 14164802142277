import { Box, Container, Grid2 } from "@mui/material";
import CustomersGrid from "../../admin/CustomersGrid";

const Customers = () => {
  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 1,
          backgroundColor: "#EEF2F6",
        }}
      >
        <Container maxWidth="xl">
          <Grid2 container spacing={3}>
            <Grid2 xs={12} md={12} lg={12}>
              <CustomersGrid
                // countyData={countyData}
                payments={false}
                title="Customers"
              />
            </Grid2>
          </Grid2>
        </Container>
      </Box>
    </>
  );
};

export default Customers;
