import {
  Card,
  CardContent,
  Button,
  Box,
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  IconButton,
  Tooltip,
} from "@mui/material";
import { useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import axios from "axios";
import DeleteIcon from "@mui/icons-material/Delete";
import { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import { LoadingButton } from "@mui/lab";

const NotariesGrid = ({
  handleFetch,
  notariesRow,
  setNotariesRow,
  currentUser,
  formik,
  selectedNotary,
  addNotaryError,
  setSelectedNotary,
  removeNotaryLoading,
  setRemoveNotaryLoading,
  addNotaryLoading,
  isExpanded,
  setIsExpanded,
  isCancelExpanded,
  setIsCancelExpanded,
  addedText,
  selectionModel,
  setSelectionModel,
  setFirstTData,
  setSecondTData,
  setNotaryRow,
  setNotaryRequestRows,
}) => {
  const currentSession = useSelector((state) => state.sessions.currentSession);
  const [loadingRows, setLoadingRows] = useState([]);
  const [gridKey, setGridKey] = useState(0);

  const tableData = (row) => {
    let commission_expiry;

    if (typeof row.commission_expiry === "number") {
      commission_expiry = new Date(
        row.commission_expiry * 1000
      ).toLocaleDateString("en-US");
    } else {
      commission_expiry = "N/A";
    }

    const firstData = [
      { label: "Commission Number", value: row.commission_number },
      { label: "Commission Expiry", value: commission_expiry },
      { label: "Registration Date", value: row.created_at },
      { label: "Address", value: row.address },
    ];

    const secondData = [
      { label: "Completed Jobs", value: row.completed_jobs },
      { label: "Pending Jobs", value: row.pending_jobs },
      { label: "Cancelled Jobs", value: row.cancelled_jobs },
      { label: "Total Worked Hours", value: row.total_worked_hours },
    ];

    return { firstData, secondData };
  };

  const notariesColumns = [
    // { field: "id", headerName: "Notary ID", flex: 1 },
    {
      field: "full_name",
      headerName: "Full Name",
      flex: 1,
      editable: false,
      valueGetter: (name) => {
        // Capitalize the first letter of each word

        return name
          ?.split(" ")
          ?.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          ?.join(" ");
      },
    },
    {
      field: "email",
      headerName: "Email",
      flex: 2,
      editable: false,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      editable: false,
      renderCell: (params) => {
        if (!params?.row?.on_site_registered && !params?.row?.bn_registered) {
          return (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                height: "100%",
              }}
            >
              <Typography variant="body2">Invitation sent</Typography>
            </Box>
          );
        } else if (
          params?.row?.on_site_registered &&
          !params?.row?.bn_registered
        ) {
          return (
            <Box sx={{ display: "flex", alignItems: "center", height: "100%" }}>
              <Typography variant="body2">PR - Registered</Typography>
            </Box>
          );
        } else if (
          !params?.row?.on_site_registered &&
          params?.row?.bn_registered
        ) {
          return <Typography variant="body2">BN - Registered</Typography>;
        } else if (
          params?.row?.on_site_registered &&
          params?.row?.bn_registered
        ) {
          return (
            <Box sx={{ display: "flex", alignItems: "center", height: "100%" }}>
              <Typography variant="body2">Active</Typography>
            </Box>
          );
        }
      },
    },
    {
      field: "created_at",
      headerName: "Creation Date",
      flex: 1,
      editable: false,
    },
    {
      field: "online_status",
      headerName: "Online Status",
      flex: 1,
      editable: false,
    },
    {
      field: "pending_jobs",
      headerName: "Pending Jobs",
      flex: 1,
      editable: false,
    },
    {
      field: "action",
      headerName: "",
      width: 50,
      renderCell: (params) => {
        const rowIndex = params.row.id;

        if (!params?.row?.on_site_registered && !params?.row?.bn_registered) {
          return (
            <>
              {loadingRows[rowIndex] ? (
                <CircularProgress size={25} />
              ) : (
                <Tooltip title="Cancel Invitation" arrow>
                  <IconButton
                    variant="contained"
                    color="error"
                    onClick={() => {
                      handleCancelNotary(params.row?.email, rowIndex);
                    }}
                    className="delete-icon"
                  >
                    <DeleteIcon width={25} />
                  </IconButton>
                </Tooltip>
              )}
            </>
          );
        } else {
          return null;
        }
      },
    },
  ];

  const handleCancelNotary = async (email, rowIndex) => {
    try {
      // Set the loading state for the specific row to true
      setLoadingRows((prevLoadingRows) => {
        const newLoadingRows = [...prevLoadingRows];
        newLoadingRows[rowIndex] = true;
        return newLoadingRows;
      });

      // const updated = await handleFetch(true);
      //       // const updatedRow = updated.data.find((notary) => notary.email === email);
      // if (!updatedRow) {
      //   // If the row is not found or some condition is not met, stop execution
      //         //   return;
      // }

      // if (updatedRow?.status !== "Invitation Sent") {
      //   // If the row's status is not "Invitation Sent", stop execution
      //   setLoadingRows((prevLoadingRows) => {
      //     const newLoadingRows = [...prevLoadingRows];
      //     newLoadingRows[rowIndex] = false;
      //     return newLoadingRows;
      //   });
      //   return;
      // }

      const resp = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/notary/cancel-notary`,
        {
          email,
          currentSession: currentSession,
        },
        { withCredentials: true }
      );

      if (resp?.data?.success && resp.data?.already_registered) {
        setNotaryRow(resp.data.data);
        setNotaryRequestRows(resp?.data?.notary_requests);
        setLoadingRows((prevLoadingRows) => {
          const newLoadingRows = [...prevLoadingRows];
          newLoadingRows[rowIndex] = false;
          return newLoadingRows;
        });
      } else {
        setNotariesRow((prevNotaryRow) =>
          prevNotaryRow.filter((notary) => notary.email !== email)
        );
        setSelectionModel([]);
        setIsCancelExpanded(false);
        setSelectedNotary(null);
        setLoadingRows((prevLoadingRows) => {
          const newLoadingRows = [...prevLoadingRows];
          newLoadingRows[rowIndex] = false;
          return newLoadingRows;
        });
      }
    } catch (e) {
      setLoadingRows((prevLoadingRows) => {
        const newLoadingRows = [...prevLoadingRows];
        newLoadingRows[rowIndex] = false;
        return newLoadingRows;
      });
    }
  };

  const handleRemoveNotary = async () => {
    try {
      setRemoveNotaryLoading(true);
      const subEmail = selectedNotary?.email;
      await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/notary/remove-notary`,
        {
          subEmail,
          currentSession: currentSession,
        },
        { withCredentials: true }
      );
      setNotariesRow((prevNotaryRow) =>
        prevNotaryRow.filter((notary) => notary.email !== subEmail)
      );
      setSelectionModel([]);
      setIsCancelExpanded(false);
      setSelectedNotary(null);
      setRemoveNotaryLoading(false);
    } catch (e) {
      setRemoveNotaryLoading(false);
    }
  };

  const getUserData = (params, event) => {
    if (!event.target.closest(".delete-icon")) {
      if (selectionModel.includes(params.id)) {
        setSelectionModel([]);
        setSelectedNotary(null);
        // Force the DataGrid to re-render with a new key
        setGridKey((prevKey) => prevKey + 1);
      } else {
        setSelectionModel([params.id]);
        setSelectedNotary(params.row);
        const data = tableData(params.row);
        setFirstTData(data.firstData);
        setSecondTData(data.secondData);
      }
    }
  };
  return (
    <>
      <Card>
        <CardContent>
          <DataGrid
            key={gridKey}
            className="cell"
            sx={{
              "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                outline: "none !important",
              },
              "& .MuiDataGrid-columnHeader:focus-within": {
                outline: "none !important",
              },
            }}
            rows={notariesRow}
            columns={notariesColumns}
            getRowId={(row) => row?.id}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 5,
                },
              },
            }}
            pageSizeOptions={[5, 10, 25, 50, 100]}
            onRowClick={getUserData}
            selectionModel={selectionModel}
            onSelectionModelChange={(newSelectionModel) => {
              setSelectionModel(newSelectionModel);
            }}
            // onCellClick={handleCellClick}
          />

          {/* ADD Notary */}
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Accordion
              expanded={isExpanded}
              elevation={0}
              sx={{ width: "100%" }}
            >
              <AccordionSummary
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{
                  borderBottom: "none",
                  cursor: "default",
                  "&.MuiAccordionSummary-root:hover": {
                    cursor: "default",
                  },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    width: "100%",
                  }}
                >
                  {isExpanded ? (
                    <Button
                      sx={{ cursor: "pointer", minWidth: "178.17px" }}
                      variant="contained"
                      color="error"
                      startIcon={<RemoveIcon />}
                      onClick={() => {
                        setIsExpanded(false);
                        formik.resetForm();
                      }}
                    >
                      Hide
                    </Button>
                  ) : (
                    <Button
                      sx={{ cursor: "pointer", minWidth: "178.17px" }}
                      variant="contained"
                      color="primary"
                      startIcon={<AddIcon />}
                      onClick={() => {
                        setIsExpanded(true);
                      }}
                    >
                      Add Notary
                    </Button>
                  )}
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <form noValidate onSubmit={formik.handleSubmit}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography variant="h5" align="center" mb={2}>
                        Add New Notary User
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        name="first"
                        label="First Name"
                        error={
                          formik.touched.first && Boolean(formik.errors.first)
                        }
                        helperText={formik.touched.first && formik.errors.first}
                        value={formik.values.first}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        name="last"
                        label="Last Name"
                        error={
                          formik.touched.last && Boolean(formik.errors.last)
                        }
                        helperText={formik.touched.last && formik.errors.last}
                        value={formik.values.last}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        name="email"
                        label="Email Address"
                        error={
                          formik.touched.email && Boolean(formik.errors.email)
                        }
                        helperText={formik.touched.email && formik.errors.email}
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </Grid>
                  </Grid>
                  <Box>
                    <Typography
                      variant="body1"
                      color="error"
                      fontWeight="bold"
                      textAlign="center"
                      sx={{ mt: 2, minHeight: "1.5rem" }}
                    >
                      {addNotaryError}
                    </Typography>
                    <LoadingButton
                      loading={addNotaryLoading}
                      type="submit"
                      variant="contained"
                      size="large"
                      sx={{ mt: 2 }}
                      fullWidth
                    >
                      Send Invitation
                    </LoadingButton>
                  </Box>
                </form>
              </AccordionDetails>
            </Accordion>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            {/* REMOVE SUBUSER */}
            <Accordion
              expanded={isCancelExpanded}
              elevation={0}
              sx={{ width: "100%" }}
            >
              <AccordionSummary
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{
                  borderBottom: "none",
                  cursor: "default",
                  "&.MuiAccordionSummary-root:hover": {
                    cursor: "default",
                  },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    width: "100%",
                  }}
                >
                  {isCancelExpanded ? (
                    <Button
                      disabled={
                        !selectedNotary || selectedNotary?.status === "pending"
                      }
                      sx={{ cursor: "pointer", minWidth: "178.16px" }}
                      variant="contained"
                      color="error"
                      startIcon={<RemoveIcon />}
                      onClick={() => {
                        setIsCancelExpanded(false);
                      }}
                    >
                      Hide
                    </Button>
                  ) : (
                    <Button
                      disabled={
                        !selectedNotary ||
                        selectedNotary?.status === "Invitation Sent" ||
                        selectedNotary?.isAdmin === true
                      }
                      sx={{ cursor: "pointer", minWidth: "178.16px" }}
                      variant="contained"
                      color="error"
                      startIcon={<DeleteIcon />}
                      onClick={() => {
                        if (selectedNotary?.isAdmin === true) {
                          return;
                        } else {
                          setIsCancelExpanded(true);
                        }
                      }}
                    >
                      Remove Notary
                    </Button>
                  )}
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <form noValidate onSubmit={formik.handleSubmit}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography variant="h5" align="center" mb={2}>
                        Remove Notary User
                      </Typography>
                      <Typography variant="h6" align="center" mb={2}>
                        Are you certain about permanently removing this notary?
                        This action is irreversible.
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        disabled
                        fullWidth
                        name="first"
                        label="First Name"
                        value={selectedNotary?.first_name || ""}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        disabled
                        fullWidth
                        name="last"
                        label="Last Name"
                        value={selectedNotary?.last_name || ""}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        name="email"
                        label="Email Address"
                        disabled
                        value={selectedNotary?.email || ""}
                      />
                    </Grid>
                  </Grid>
                  <Box>
                    <Typography
                      variant="body1"
                      color="error"
                      fontWeight="bold"
                      textAlign="center"
                      sx={{ mt: 2, minHeight: "1.5rem" }}
                    >
                      {addNotaryError}
                    </Typography>
                    <LoadingButton
                      onClick={handleRemoveNotary}
                      loading={removeNotaryLoading}
                      color="error"
                      variant="contained"
                      size="large"
                      sx={{ mt: 2 }}
                      fullWidth
                    >
                      Confirm Remove Notary User Permanently
                    </LoadingButton>
                  </Box>
                </form>
              </AccordionDetails>
            </Accordion>
          </Box>
          <Typography
            variant="body1"
            color="error"
            fontWeight="bold"
            textAlign="center"
          >
            {addedText}
          </Typography>
        </CardContent>
      </Card>
    </>
  );
};

export default NotariesGrid;
