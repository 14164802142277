import React, { useState } from "react";
import { Box, Typography, useMediaQuery } from "@mui/material";
import { keyframes } from "@emotion/react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { LoadingButton } from "@mui/lab";
import { handleSessionExpiredLogout } from "../../store/actions/sessionsActions";

// Define the keyframes for the slide-down animation
const slideDown = keyframes`
  0% {
    transform: translateY(-50%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;

const SessionWillExpireBox = ({ willExpireSoon, setWillExpireSoon }) => {
  const dispatch = useDispatch();
  const currentSession = useSelector((state) => state.sessions.currentSession);
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"));
  const [extendLoading, setExtendLoading] = useState(false);

  const handleExtendSession = async () => {
    try {
      setExtendLoading(true);
      const resp = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/sessions/extend-session`,
        {
          currentSession: currentSession,
          willExpireSoon: willExpireSoon,
        },
        { withCredentials: true }
      );

      if (resp?.data?.success && resp?.data?.data?.isValidated) {
        sessionStorage.setItem("exp", resp?.data?.data?.expiry);
        sessionStorage.setItem("extended", "true");
        sessionStorage.removeItem("willExpire");
        setWillExpireSoon(false);
      }
    } catch (error) {
      // Check if the error is a 401 Unauthorized
      if (error.response && error.response.status === 401) {
        // Navigate back to the login page
        dispatch(handleSessionExpiredLogout());
      }
    } finally {
      setExtendLoading(false);
    }
  };

  return (
    <>
      {willExpireSoon && (
        <Box
          sx={{
            position: "fixed",
            top: "20px",
            left: !lgUp ? "calc(30% - 0px)" : "calc(55% - 280px)",
            width: "auto",
            backgroundColor: "#ffe1db",
            boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.4)",
            borderRadius: "8px",
            padding: "16px",
            zIndex: 1300,
            animation: `${slideDown} 0.5s ease-out`,
          }}
        >
          <Box px={2} sx={{ position: "relative" }}>
            <Typography mx={2} align="center" variant="h5" color="error">
              Your Session Will Expire Soon
            </Typography>

            <Typography
              variant="body1"
              fontWeight="bold"
              align="center"
              color="primary"
            ></Typography>
            <Typography
              fontWeight="bold"
              align="center"
              sx={{ color: "#474747" }}
            ></Typography>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                gap: 2,
                mt: 4,
              }}
            >
              <LoadingButton
                loading={extendLoading}
                sx={{ width: "10rem" }}
                color="primary"
                onClick={handleExtendSession}
                variant="contained"
              >
                Stay Logged In
              </LoadingButton>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default SessionWillExpireBox;
