import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
  Box,
  DialogActions,
  Button,
} from "@mui/material";

const primary = {
  0: ["* Additional counties", "** Additional downloads"],
  1: ["** Additional counties"],
  2: [""],
};
const secondary = {
  0: ["$5/each/month", "$5/each"],
  1: ["$5/each/month"],
  2: [
    "Access to all the counties in the State of Florida. Ideal for contractors with operations across the state and large distributed teams.",
  ],
};

const PopupInfo = ({ open, setOpen, currentIndex, cardTitle }) => {
  return (
    <Dialog open={open} onClose={() => setOpen(false)} maxWidth="sm" fullWidth>
      <DialogTitle
        color="primary"
        sx={{ fontSize: "30px", pb: 0 }}
        align="center"
      >
        {cardTitle}
      </DialogTitle>
      <Typography sx={{ fontWeight: "bold" }} align="center">
        Plan Information
      </Typography>

      <Box sx={{ borderBottom: "3px solid #d3d3d3", mx: 3, pt: 1 }}></Box>
      <DialogContent sx={{ pt: 1 }}>
        <List component="nav">
          {secondary?.[currentIndex]?.map((secondaryInfo, index) => (
            <React.Fragment key={index}>
              <ListItem sx={{ textAlign: "center" }}>
                <ListItemText
                  primary={primary?.[currentIndex]?.[index]}
                  secondary={secondaryInfo}
                />
              </ListItem>
              <Divider />
            </React.Fragment>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default PopupInfo;
