import {
  // broadcastUpdate,
  updateUser,
  updatePaymentCards,
  updateNocTasks,
  updateNotaryUserForms,
  addURL,
  addUserFullDesignProfessionals,
  setInstantRonAccepted,
  setInstantRonStop,
  setInstantRonSuccess,
  updateAnalytics,
} from "../userActions";

import {
  getLiveNocData,
  getPdfProgress,
  updatePermitPackageFromReturn,
  updatePermitPackageOnFly,
  pushCityLog,
} from "../dataActions";
import { updateNocNotification } from "../notificationsActions";
import { handleSessionExpiredLogout } from "../sessionsActions";

export const userSessionOnMessage =
  (receivedData) => async (dispatch, getState) => {
    switch (receivedData.event) {
      case "logout":
        dispatch(handleSessionExpiredLogout());
        break;
      case "instant_ron_accepted":
        const ronAcceptedUpdate = receivedData.data;
        if (ronAcceptedUpdate.success === true) {
          dispatch(setInstantRonAccepted());
          break;
        } else if (ronAcceptedUpdate.success === "stop") {
          dispatch(setInstantRonStop());
          break;
        } else if (ronAcceptedUpdate.success === "success") {
          dispatch(setInstantRonSuccess());
          break;
        }
        break;
      case "noc_notification_update":
        dispatch(
          updateNocNotification(
            receivedData.data.new_notification,
            receivedData.data.notification_counts
          )
        );
        break;
      case "user_update":
        dispatch(updateUser(receivedData?.data));
        break;
      case "update_notary_user_forms":
        dispatch(updateNotaryUserForms(receivedData.data));
        break;
      case "update_payment_cards":
        dispatch(updatePaymentCards(receivedData?.data));
        break;
      case "update_noc_tasks":
        dispatch(updateNocTasks(receivedData?.data));
        break;
      case "broadcast":
        dispatch(getLiveNocData(receivedData.data));
        break;
      case "add_url_img":
        dispatch(addURL(receivedData.data?.data));
        break;
      case "update_pdf_progress":
        dispatch(getPdfProgress(receivedData.data));
        break;
      case "design_professionals_update":
        dispatch(addUserFullDesignProfessionals(receivedData.data));
        break;
      case "update_permit_document":
        dispatch(updatePermitPackageFromReturn(receivedData.data));
        break;
      case "update_permit_package_on_fly":
        dispatch(updatePermitPackageOnFly(receivedData.data));
        break;
      case "push_city_log":
        dispatch(pushCityLog(receivedData.data));
        break;
      case "update_analytics":
        dispatch(updateAnalytics(receivedData.data));
        break;
      default:
        return;
    }
  };
