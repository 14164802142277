import React, { useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Button,
  List,
  ListItem,
  ListItemText,
  Box,
  Tooltip,
  IconButton,
  Alert,
} from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { useTheme } from "@mui/material/styles";
import { green, yellow } from "@mui/material/colors";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

const featureMapping = {
  0: "county",
  1: "license",
  2: "subuser",
  3: "downloads_limit",
  4: "noc_notary",
  5: "notary_record",
};

const bannerMapping = {
  Free: "NO RISK",
  Premium: "POPULAR",
  Rockstar: "NO LIMITS",
};

const PricingCard = ({
  planOptions,
  title,
  new_title,
  price,
  checked,
  selectedPlan,
  setSelectedPlan,
  plan,
  contractorList,
  showAlert,
  setShowAlert,
  isFadingOut,
  type,
  handleDisable,
  mobileSize,
  promoCodeName,
  trial,
  currentUser,
  setShowInfo,
  setInfoIndex,
  cardIndex,
  setCardTitle,
}) => {
  const theme = useTheme();
  const isDisabled = contractorList.length > 1 && plan?.monthly_restart;

  const formatValue = (value) => {
    // Check if the value is a string containing a number
    if (!isNaN(value)) {
      const floatValue = parseFloat(value);
      // Format the float value to two decimal places if it doesn't already have them
      return floatValue % 1 === 0 ? floatValue.toFixed(2) : value;
    }
    return value; // Return the original value if it's not a number
  };

  const handleShowInfo = (index, title) => {
    setShowInfo(true);
    setInfoIndex(index);
    setCardTitle(title);
  };

  return (
    <>
      <Card
        sx={{
          maxWidth: 500,
          width: 500,
          boxShadow:
            "0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)",
          position: "relative", // Ensure relative positioning for the banner
          border:
            selectedPlan?.title === title ||
            (bannerMapping?.[title] === "MOST POPULAR" && !selectedPlan)
              ? "3px solid #000"
              : undefined,
          // : undefined,
        }}
      >
        {/* Red Banner */}
        {promoCodeName ? (
          <Box
            sx={{
              position: "absolute",
              top: 40,
              right: -40,
              transform: "rotate(45deg)",
              backgroundColor: "red",
              color: "white",
              padding: "8px 40px",
              fontSize: "1rem",
              fontWeight: "bold",
              zIndex: 10,
            }}
          >
            {promoCodeName}
          </Box>
        ) : (
          <Box
            sx={{
              position: "absolute",
              top: 35, // Align the top of the banner uniformly
              right: -50, // Ensure a consistent right offset across banners
              transform: "rotate(45deg)",
              backgroundColor: "#176ab0",
              color: "white",
              padding: "8px 55px", // Adjust padding to fit longer text
              pl: bannerMapping?.[title] === "MOST POPULAR" ? 10 : undefined, // Adjust padding for the "Most Popular" banner
              fontSize: "1rem",
              fontWeight: "bold",
              zIndex: 10,
              textAlign: "center", // Center the text inside the banner
              width: "auto",
            }}
          >
            {new_title ? bannerMapping?.[new_title] : bannerMapping?.[title]}
          </Box>
        )}

        <CardContent
          sx={{
            "&.MuiCardContent-root": { paddingBottom: 0 },
          }}
        >
          <Typography
            gutterBottom
            variant="h5"
            component="div"
            color={
              selectedPlan?.title === title ? yellow[600] : "text.secondary"
            }
          >
            {new_title ? new_title : title}
          </Typography>
          <Typography variant="h4" color="primary" sx={{ mb: 2 }}>
            ${price || "0.00"}
            <Typography
              variant="subtitle1"
              component="span"
              color="text.secondary"
            >
              {" "}
              / {checked ? "year" : "month"}
            </Typography>
          </Typography>

          {selectedPlan?.title === title ? (
            <>
              <Button
                disabled={
                  type === "change" && !currentUser?.user?.expired
                    ? handleDisable(title)
                    : undefined
                }
                onClick={() => setSelectedPlan()}
                variant="contained"
                sx={{
                  backgroundColor: yellow[600],
                  "&:hover": { backgroundColor: yellow[600] },
                  mb: 2,
                  color: "#176ab0",
                }}
              >
                {type === "change" &&
                !currentUser?.user?.expired &&
                handleDisable(title)
                  ? "CURRENT PLAN"
                  : "PLAN SELECTED"}
              </Button>
            </>
          ) : (
            <>
              <div
                style={{
                  width: "100%",
                  display: "inline-flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Button
                  disabled={
                    isDisabled ||
                    (type === "change" &&
                      handleDisable(title) &&
                      !currentUser?.user?.expired)
                  }
                  onClick={() => setSelectedPlan(plan)}
                  variant="outlined"
                  sx={{
                    color: yellow[700], // Text color
                    borderColor: yellow[600], // Border color
                    "&:hover": {
                      backgroundColor: yellow[600], // Background color on hover
                      borderColor: yellow[600], // Optional: Change border color on hover
                      color: "#fff", // Change text color on hover if needed
                    },
                    mb: 2,
                    // Adding styles for the disabled state
                    "&.Mui-disabled":
                      type !== "change" || !handleDisable(title)
                        ? {
                            color: theme.palette.error.main, // Text color when disabled
                            borderColor: theme.palette.error.main, // Border color when disabled
                            // Optionally, set a background color for the disabled state if needed
                            // backgroundColor: theme.palette.error.light,
                          }
                        : {},
                  }}
                >
                  {isDisabled
                    ? "PLAN UNAVAILABLE"
                    : type === "change" &&
                      handleDisable(title) &&
                      !currentUser?.user?.expired
                    ? "CURRENT PLAN"
                    : "CHOOSE PLAN"}
                </Button>
                {isDisabled && (
                  <IconButton sx={{ mb: 2 }} onClick={() => setShowAlert(true)}>
                    <HelpOutlineIcon
                      sx={{ color: theme.palette.error.main, ml: 1 }}
                    />
                  </IconButton>
                )}
              </div>
            </>
          )}

          <List dense>
            {planOptions?.map((option, index) => (
              <ListItem
                key={index}
                disableGutters
                sx={{
                  p: 0,
                  py: 1,
                  borderBottom:
                    planOptions.length === index + 1
                      ? undefined
                      : "2px solid #d3d3d3",
                  textAlign: "center",
                  display: "flex", // Flexbox to align icon and text
                  alignItems: "center", // Vertically align icon and text
                  justifyContent: "center", // Horizontally align icon and text
                }}
              >
                {/* Wrapping Icon and Text together in a Box */}
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection:
                      planOptions.length === index + 1 ? "column" : "row",
                    gap: 1,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {/* Check Circle Icon */}
                    <CheckCircleOutlineIcon
                      sx={{ color: "#176ab0", fontSize: "1.2rem" }}
                    />
                    {/* Text */}
                    <ListItemText
                      sx={{ textAlign: "left", pl: 2 }}
                      primary={
                        featureMapping[index] === "permit_package" &&
                        new_title === "Free" &&
                        index === 4
                          ? `2 ${option}`
                          : featureMapping[index] === "subuser" &&
                            parseInt(plan[featureMapping[index]]) > 1
                          ? `Up to ${
                              parseInt(plan[featureMapping[index]]) + 1
                            } ${option}`
                          : featureMapping[index] === "subuser"
                          ? `${
                              parseInt(plan[featureMapping[index]]) + 1
                            } ${option}`
                          : featureMapping[index] === "license" &&
                            parseInt(plan[featureMapping[index]]) > 1
                          ? `Up to ${plan[featureMapping[index]]} ${option}`
                          : featureMapping[index] === "notary_record"
                          ? `${option} $${formatValue(
                              plan[featureMapping[index]]
                            )}`
                          : featureMapping[index] === "noc_notary"
                          ? `${option} $${formatValue(
                              plan[featureMapping[index]]
                            )}`
                          : option.startsWith("No")
                          ? option
                          : `${plan[featureMapping[index]]} ${option}`
                      }
                      primaryTypographyProps={{
                        variant: "body2",
                        color: "text.secondary",
                      }}
                    />
                  </Box>
                  {planOptions.length === index + 1 && (
                    <Button
                      onClick={() => handleShowInfo(cardIndex, title)}
                      size="small"
                      variant="outlined"
                      sx={{ ml: 1, p: 1 }}
                    >
                      More Info
                    </Button>
                  )}
                </Box>
              </ListItem>
            ))}
          </List>
        </CardContent>
      </Card>
      {isDisabled && showAlert && (
        <Alert
          sx={{
            zIndex: 333,
            position: "absolute",
            right: 8,
            left: type === "change" ? 230 : 24,
            top: type === "change" ? 160 : 150,
            opacity: isFadingOut ? 0 : 1,
            transition: "opacity 1000ms ease-in-out",
          }}
          variant="filled"
          severity="error"
        >
          There is only one contractor license allowed under this plan. You've
          selected {contractorList.length} contractors. To select this plan,
          please select only one contractor.
        </Alert>
      )}
    </>
  );
};

export default PricingCard;
