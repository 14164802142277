import * as yup from "yup";
import YupPassword from "yup-password";
import validator from "validator";
import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import LottieAnimation from "../../../animation/LottieAnimation";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  Box,
  Stack,
  Link,
  Typography,
  useTheme,
  useMediaQuery,
  Grid2 as Grid,
  TextField,
  InputAdornment,
  IconButton,
} from "@mui/material";
import axios from "axios";
import EmailDrawer from "./EmailDrawer";
import { LoadingButton } from "@mui/lab";
import AdminLoginModal from "./AdminLoginModal";
YupPassword(yup);

const NewRegister = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const location = useLocation();
  const mobileSize = useMediaQuery(theme.breakpoints.down("sm"));
  const tabletSize = useMediaQuery(theme.breakpoints.down("md"));

  const [selectedPlan, setSelectedPlan] = useState({});
  const [submitLoading, setSubmitLoading] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [isEmailError, setIsEmailError] = useState(false);
  const [isPhoneError, setIsPhoneError] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [validationError, setValidationError] = useState("");
  const [licenseInputError, setLicenseInputError] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const [emailDrawerOpen, setEmailDrawerOpen] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [tempLicenseNumber, setTempLicenseNumber] = useState("");
  const [tempFirstName, setTempFirstName] = useState("");
  const [tempLastName, setTempLastName] = useState("");

  const [markedAdmin, setMarkedAdmin] = useState(false);
  const [adminEmail, setAdminEmail] = useState("");
  const [adminSecret, setAdminSecret] = useState("");
  const [adminModalOpen, setAdminModalOpen] = useState(false);

  useEffect(() => {
    if (
      (adminEmail === "" || adminSecret === "") &&
      markedAdmin === true &&
      adminModalOpen === false
    ) {
      setAdminModalOpen(true);
    }
  }, [adminEmail, adminSecret, markedAdmin]);

  useEffect(() => {
    const getTiers = async () => {
      try {
        const resp = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/nocApi/data/tiers`
        );

        if (resp.data.success) {
          const tierTitles = resp.data.data.map((tier) =>
            tier.title.toLowerCase()
          );

          const queryParams = new URLSearchParams(location.search);
          const typeParam = queryParams.get("type");
          const adminParam = queryParams.get("admin");

          if (adminParam && adminParam === "true") {
            setMarkedAdmin(true);
          }

          if (!tierTitles.includes(typeParam)) {
            window.location.href = "https://permitrockstar.com/pricing/";
            return;
          }

          resp.data.data.forEach((tier) => {
            if (tier.title.toLowerCase() === typeParam) {
              const planObj = {
                title: tier?.title,
                product_id: tier?.product_id,
                price_id: tier?.monthly_price_id,
                license: parseInt(tier?.license),
                subuser: parseInt(tier?.subuser),
                noc: tier?.noc,
                county: tier?.county,
                interval: "month",
              };
              setSelectedPlan(planObj);
            }
          });
        }
      } catch (e) {}
    };

    getTiers();
  }, []);

  useEffect(() => {
    if (selectedPlan?.title) {
      setPageLoading(false);
    }
  }, [selectedPlan]);

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      password: "",
      confirmPassword: "",
      licenseNumber: "",
      licenseValidated: false,
    },
    validationSchema: yup.object({
      firstName: yup
        .string()
        .min(3, "First name must be at least 3 characters")
        .matches(
          /^[A-Za-z\s]+$/,
          "First name cannot contain numbers or special characters"
        )
        .required("First name is required"),
      lastName: yup
        .string()
        .min(3, "Last name must be at least 3 characters")
        .matches(
          /^[A-Za-z\s]+$/,
          "Last name cannot contain numbers or special characters"
        )
        .required("Last name is required"),
      email: yup.string().email("Must be a valid email").required(),
      phone: yup
        .string()
        .required("Phone number is required")
        .test("PhoneTest", "Phone number is invalid", function (value) {
          const isValidPhoneNumber = validator.isMobilePhone(value);
          if (isValidPhoneNumber) {
            return true;
          } else {
            return false;
          }
        }),
      password: yup
        .string()
        .min(8, "Password must contain 8 or more characters")
        .max(255)
        .minLowercase(1, "Password must contain at least 1 lower case letter")
        .minUppercase(1, "Password must contain at least 1 upper case letter")
        .minNumbers(1, "Password must contain at least 1 number")
        .minSymbols(1, "Password must contain at least 1 special character")
        .required("Password is required"),
      confirmPassword: yup
        .string()
        .required("Password confirmation is required")
        .test("Passwords-match", "Passwords must match", function (value) {
          return this.parent.password === value;
        }),
      licenseValidated:
        selectedPlan?.title === "Free"
          ? yup.boolean().required()
          : yup.boolean(),
      licenseNumber:
        selectedPlan?.title === "Free"
          ? yup.string().min(7, "").max(12, "").required("")
          : yup.string().min(7).max(12),
    }),
    onSubmit: async (values, helpers) => {
      try {
        setSubmitLoading(true);
        if (selectedPlan?.title === "Free" && !values.licenseValidated) {
          return;
        }

        values["plan"] = selectedPlan;
        values["terms"] = true;
        values["timezone"] =
          Intl.DateTimeFormat().resolvedOptions().timeZone ||
          "America/New_York";
        values["markedAdmin"] = markedAdmin;
        values["adminEmail"] = adminEmail;
        values["adminSecret"] = adminSecret;

        await axios.post(
          `${process.env.REACT_APP_BASE_URL}/nocApi/auth/createUser`,
          {
            values,
          },
          { withCredentials: true }
        );

        try {
          const stripe_resp = await axios.post(
            `${process.env.REACT_APP_BASE_URL}/nocApi/stripe/create-checkout-session`,
            {
              values,
            },
            { withCredentials: true }
          );
          if (
            stripe_resp?.data?.success &&
            stripe_resp?.data?.free &&
            stripe_resp?.data?.token
          ) {
            navigate("/2fa-secure?token=" + stripe_resp.data.token);
            return;
          } else {
            window.location.href = stripe_resp.data;
          }
        } catch (e) {
          console.error(e);
          if (
            e.response &&
            e.response.data &&
            e.response.data.message === "Payment session canceled"
          ) {
          } else {
            console.error("An error occurred during checkout:", e);
          }
        }
      } catch (err) {
        setErrorText(err.response.data.message);
      } finally {
        setSubmitLoading(false);
      }
    },
  });

  const sendCode = async (emailValue) => {
    try {
      const resp = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/email/generateverify`,
        {
          userEmail: emailValue,
        },
        { withCredentials: true }
      );

      return {
        success: resp.data.success,
        message: resp.data.message,
        isEmailSent: resp.data.isEmailSent,
        isVerified: resp.data.isVerified,
      };
      // setErrorText(resp.data.message);
      // setVerifyLoading(false);
    } catch (e) {
      if (e.response && e.response.data) {
        // If error response contains data, display the error message
        return {
          success: false,
          message: e.response.data.message,
        };
        // setErrorText(e.response.data.message);
      } else {
        // Otherwise, handle the error gracefully without specific error message
        return { success: false, message: "Server is not working" };
      }
    }

    /// SEND EMAIL API WITH CODE ////
    /////////////////////////////////
  };

  // ?  PRE-SUBMITTING FORM
  const handlePreSubmit = async () => {
    try {
      setSubmitLoading(true);
      const errors = await formik.validateForm();
      if (Object.keys(errors).length > 0) {
        // Iterate over the errors to set each error and mark each field as touched
        Object.entries(errors).forEach(([field, errorMessage]) => {
          formik.setFieldError(field, errorMessage);
          formik.setFieldTouched(field, true, false); // Marks the field as touched
          if (field === "licenseNumber") {
            setLicenseInputError(true);
            setValidationError(
              "License number must be between 7 and 12 characters"
            );
          }
        });
        setSubmitLoading(false);
        return;
      }
      if (
        (selectedPlan?.title === "Free" ||
          formik.values.licenseNumber !== "") &&
        (!formik.values.licenseValidated ||
          formik.values.licenseNumber !== tempLicenseNumber ||
          formik.values.firstName !== tempFirstName ||
          formik.values.lastName !== tempLastName)
      ) {
        const resp = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/nocApi/data/validate-registering-license`,
          {
            license_number: formik.values.licenseNumber,
            first_name: formik.values.firstName,
            last_name: formik.values.lastName,
          }
        );
        if (resp.data.success === true) {
          formik.setFieldValue("licenseValidated", true);
          setTempLicenseNumber(formik.values.licenseNumber);
          setTempFirstName(formik.values.firstName);
          setTempLastName(formik.values.lastName);
          setValidationError("");
        } else {
          if (
            selectedPlan?.title === "Free" ||
            formik.values.licenseNumber !== ""
          ) {
            setValidationError(resp.data.message);
            setTempLicenseNumber("");
            setTempFirstName("");
            setTempLastName("");
            formik.setFieldValue("licenseValidated", false);
          }
          return;
        }
      }

      // CHECK EMAIL FOR DUPLICATIONS //
      const emailResp = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/email/verifyEmail`,
        {
          emailAddress: formik.values.email,
          phone: formik.values.phone,
        },
        { withCredentials: true }
      );

      if (emailResp.data.success === true) {
        if (
          emailResp.data.data.userExist === true ||
          emailResp.data.data.phoneExist === true
        ) {
          const errors = {};
          const touched = {};

          if (emailResp.data.data.userExist === true) {
            setIsEmailError(true);
            errors.email = "Email already exists.";
            touched.email = true;
          }

          if (emailResp.data.data.phoneExist === true) {
            setIsPhoneError(true);
            errors.phone = "Phone number already exists.";
            touched.phone = true;
          }

          formik.setErrors(errors);
          formik.setTouched(touched);

          // Clear values only for the fields that have issues
          formik.setValues({
            ...formik.values,
            email: errors.email ? "" : formik.values.email,
            phone: errors.phone ? "" : formik.values.phone,
          });

          setSubmitLoading(false);
        } else {
          setIsEmailError(false);
          setIsPhoneError(false);

          const sendCodeValue = await sendCode(formik.values.email);
          if (sendCodeValue.success === false) {
            setSubmitLoading(false);
          } else {
            if (sendCodeValue.isVerified === true) {
              formik.setTouched({});

              //! SUBMIT REGISTRATION HERE!!
              //! //////////////////////////////////////////////////

              formik.handleSubmit();
            } else {
              setEmailDrawerOpen(true);
              setSubmitLoading(false);
            }
          }
        }
      }
    } catch (e) {
      if (
        (selectedPlan?.title === "Free" ||
          formik.values.licenseNumber !== "") &&
        e.response &&
        e?.response?.data?.type === "license"
      ) {
        setValidationError(e?.response?.data?.message);
        setLicenseInputError(true);
        setTempLicenseNumber("");
        setTempFirstName("");
        setTempLastName("");
        formik.setFieldValue("licenseValidated", false);
      }
      if (e.response && e?.response?.data?.type !== "license") {
        setErrorText(e?.response?.data?.message);
      }
    } finally {
      setSubmitLoading(false);
    }
  };

  const handleLicenseNumberInputChange = (event) => {
    const value = event?.target.value.toUpperCase();
    setValidationError("");

    formik?.setFieldValue("licenseNumber", value);

    if (value.length >= 7 && value?.length <= 12) {
      setLicenseInputError(false);
    } else {
      setLicenseInputError(true);
      setValidationError("License number must be between 7 and 12 characters");
    }
  };

  const handleNameChange = (e) => {
    const { name, value } = e.target;

    const formattedValue = value.charAt(0).toUpperCase() + value.slice(1);
    formik.setFieldValue(name, formattedValue);
  };

  const handleEmailChange = (e) => {
    const { name, value } = e.target;

    const formattedValue = value.toLowerCase();
    formik.setFieldValue(name, formattedValue);
  };

  const handleNumberInputChange = (event) => {
    if (event.target.value.length > 12) {
      return;
    }

    // Update Formik value
    formik.setFieldValue(event.target.name, event.target.value);
    if (isPhoneError) {
      setIsPhoneError(false);
    }
  };

  const handlePhoneInputChange = (event) => {
    const { value } = event.target;

    const digitsOnly = value.replace(/\D/g, ""); // Remove non-numeric characters
    let formattedPhoneNumber = "";

    // Add dashes after 3rd and 6th digits
    for (let i = 0; i < digitsOnly.length; i++) {
      if (i === 3 || i === 6) {
        formattedPhoneNumber += "-";
      }
      formattedPhoneNumber += digitsOnly[i];
    }

    formik.setFieldValue("phone", formattedPhoneNumber);
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    if (
      selectedPlan?.title !== "Free" &&
      formik.values.licenseNumber === "" &&
      validationError !== ""
    ) {
      setValidationError("");
      setLicenseInputError(false);
      formik.setFieldError("licenseNumber", "");
    }
  }, [formik.values.licenseNumber, validationError]);

  return (
    <>
      {pageLoading ? (
        <Box
          sx={{
            display: "flex",
            width: "100%",
            height: "100vh",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Box>
            <img
              style={{
                width: "10rem",
                marginLeft: "auto",
                marginRight: "auto",
              }}
              alt="Permit Rockstar Logo"
              src="/img/website_img/PermitRockstar.png"
            />
          </Box>
          <LottieAnimation />
        </Box>
      ) : (
        <Box
          sx={{
            backgroundImage: mobileSize
              ? "none"
              : "url('/img/website_img/homeImg.jpeg')",
            backgroundRepeat: "repeat",
            minHeight: "100vh",
            display: "flex",
            justifyContent: mobileSize ? undefined : "center",
            alignItems: "center",
            flexDirection: "column",
            overflow: mobileSize ? "hidden" : undefined,
          }}
        >
          <Box
            sx={{
              backgroundColor: "#fff",
              mt: mobileSize ? 0 : 2,
              maxWidth: "1100px",
              borderRadius: "25px",
              px: mobileSize ? 1 : 3,
              py: mobileSize ? 1 : 3,
              width: mobileSize ? "100%" : "75%",
              overflow: "hidden",
              position: "relative",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Stack spacing={1} sx={{ mb: 1 }}>
              <Box display="flex" justifyContent="space-between">
                <Link
                  sx={{ fontWeight: "bold", cursor: "pointer" }}
                  onClick={() => navigate("/login")}
                  underline="hover"
                  variant="subtitle2"
                  display="flex"
                  alignItems="center"
                >
                  <ArrowBackIcon sx={{ marginRight: 1 }} />
                  Back to login
                </Link>
                <div style={{ display: "flex", alignSelf: "center" }}>
                  <img
                    style={{
                      width: "8rem",
                      marginRight: "7.5rem",
                      marginTop: "-9px",
                    }}
                    alt="Permit Rockstar Logo"
                    src="/img/website_img/logoText.png"
                  />
                </div>

                <div />
              </Box>
              <Typography
                sx={{ textAlign: "center" }}
                color="#004976"
                variant="h5"
              >
                Enter Your Details to Sign Up
              </Typography>
            </Stack>

            <Grid
              container
              spacing={2}
              rowSpacing={3}
              sx={{
                height: mobileSize
                  ? "calc(95vh - 13rem)"
                  : "calc(80vh - 12rem)",
                overflowY: "auto",
                paddingRight: "2px",
                py: "2px",
                mt: 1,
                alignItems: "flex-start",
                flexGrow: 1,
                px: 1,
              }}
            >
              <Grid size={{ xs: 12, md: 6 }}>
                <TextField
                  error={
                    Boolean(formik.touched.firstName) &&
                    Boolean(formik.errors.firstName)
                  }
                  helperText={
                    formik.touched?.firstName && formik.errors?.firstName
                  }
                  onBlur={formik.handleBlur}
                  onChange={(e) => {
                    handleNameChange(e);
                  }}
                  value={formik.values.firstName}
                  name="firstName"
                  label="First Name*"
                  variant="filled"
                  fullWidth
                />
              </Grid>
              <Grid size={{ xs: 12, md: 6 }}>
                <TextField
                  error={
                    Boolean(formik.touched.lastName) &&
                    Boolean(formik.errors.lastName)
                  }
                  helperText={
                    formik.touched?.lastName && formik.errors?.lastName
                  }
                  onBlur={formik.handleBlur}
                  onChange={(e) => {
                    handleNameChange(e);
                  }}
                  value={formik.values.lastName}
                  name="lastName"
                  label="Last Name*"
                  variant="filled"
                  fullWidth
                />
              </Grid>
              <Grid size={{ xs: 12, md: 6 }}>
                <TextField
                  error={
                    Boolean(formik.touched.email) &&
                    Boolean(formik.errors.email)
                  }
                  helperText={
                    isEmailError === true && !formik.isValid
                      ? "Email address already exists"
                      : formik.touched?.email && formik.errors?.email
                  }
                  onBlur={formik.handleBlur}
                  onChange={(e) => {
                    handleEmailChange(e);
                  }}
                  value={formik.values.email}
                  name="email"
                  label="Email Address*"
                  variant="filled"
                  fullWidth
                />
              </Grid>
              <Grid size={{ xs: 12, md: 6 }}>
                <TextField
                  error={
                    (isPhoneError && formik.touched.phone) || // Check phone validation error
                    (formik.touched.phone && Boolean(formik.errors.phone)) // Fall back to generic error
                  }
                  helperText={
                    isPhoneError === true && !formik.isValid
                      ? "Phone number already exists"
                      : formik.touched?.phone && formik.errors?.phone
                  }
                  onKeyUp={handlePhoneInputChange}
                  onBlur={formik.handleBlur}
                  onChange={handleNumberInputChange}
                  value={formik.values.phone}
                  name="phone"
                  label="Phone Number*"
                  variant="filled"
                  fullWidth
                />
              </Grid>
              <Grid size={{ xs: 12, md: 6 }} xs={12} md={6}>
                <TextField
                  error={
                    Boolean(formik.touched.password) &&
                    Boolean(formik.errors.password)
                  }
                  helperText={
                    formik.touched?.password && formik.errors?.password
                  }
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.password}
                  type={showPassword ? "text" : "password"}
                  name="password"
                  label="Password*"
                  variant="filled"
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleTogglePasswordVisibility}
                          onMouseDown={(e) => e.preventDefault()} // Prevent focus change on mouse down
                          onMouseUp={(e) => e.preventDefault()} // Prevent focus change on mouse up
                          edge="end"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid size={{ xs: 12, md: 6 }}>
                <TextField
                  error={
                    Boolean(formik.touched.confirmPassword) &&
                    Boolean(formik.errors.confirmPassword)
                  }
                  helperText={
                    formik.touched?.confirmPassword &&
                    formik.errors?.confirmPassword
                  }
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.confirmPassword}
                  type="password"
                  name="confirmPassword"
                  label="Confirm Password*"
                  variant="filled"
                  fullWidth
                />
              </Grid>
              <Grid size={{ xs: 12 }}>
                <Box
                  sx={{
                    mx: tabletSize ? undefined : "auto",
                    width: tabletSize ? "100%" : "50%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="h6"
                    mt={1}
                    color="primary"
                    align="center"
                  >
                    {selectedPlan?.title === "Free"
                      ? "Before proceeding, we will validate your license number"
                      : "To register, you’ll need a valid contractor license"}
                  </Typography>
                  <Typography
                    variant="body2"
                    mt={1}
                    align="center"
                    fontWeight="bold"
                    mb={2}
                  >
                    {selectedPlan?.title === "Free"
                      ? "The registering user must hold a valid contractor license to proceed with registration."
                      : " We recommend that the licensed contractor registers initially for license validation. You can add it later, but no permit packages can be created until the license is validated."}
                  </Typography>
                  <TextField
                    error={
                      Boolean(formik.touched.licenseNumber) &&
                      Boolean(formik.errors.licenseNumber)
                    }
                    helperText={
                      formik.touched?.licenseNumber &&
                      formik.errors?.licenseNumber
                    }
                    onBlur={formik.handleBlur}
                    onChange={(e) => handleLicenseNumberInputChange(e)}
                    value={formik.values.licenseNumber}
                    disabled={searchLoading}
                    name="licenseNumber"
                    label="License Number"
                    variant="filled"
                    fullWidth
                    className="fadeIn"
                    inputProps={{
                      style: {
                        fontSize: "32px",
                        fontWeight: "bold",
                        color: licenseInputError ? "red" : undefined,
                      },
                    }}
                    sx={
                      licenseInputError
                        ? {
                            mb: 2,
                            "& .MuiFilledInput-root": {
                              "&:before": {
                                borderBottomColor: "red",
                              },
                              "&:hover:not(.Mui-disabled):before": {
                                borderBottomColor: "red",
                              },
                              "&.Mui-focused:before": {
                                borderBottomColor: "red",
                              },
                            },
                            "& .MuiInputLabel-root": {
                              color: "red",
                            },
                          }
                        : { mb: 2 }
                    }
                  />
                  <Typography
                    align="center"
                    variant="h6"
                    color="error"
                    mb={1}
                    sx={{ minHeight: "1.4rem" }}
                  >
                    {validationError}
                  </Typography>
                </Box>
              </Grid>
            </Grid>

            <Box
              sx={{
                position: "sticky",
                bottom: 0,
                py: 2,
                backgroundColor: "#fff",
                display: "flex",
                justifyContent: "center",
                boxShadow: "0px -2px 10px rgba(0,0,0,0.1)",
                mt: "auto",
                px: mobileSize ? 1 : undefined,
              }}
            >
              <LoadingButton
                variant="contained"
                sx={{ width: "20rem" }}
                loading={submitLoading}
                onClick={handlePreSubmit}
              >
                Submit
              </LoadingButton>
            </Box>
          </Box>
          <EmailDrawer
            open={emailDrawerOpen}
            setOpen={setEmailDrawerOpen}
            setSubmitLoading={setSubmitLoading}
            formik={formik}
          />
          <AdminLoginModal
            title="Registering as Admin"
            open={adminModalOpen}
            setOpen={setAdminModalOpen}
            onClose={() => setAdminModalOpen(false)}
            adminEmail={adminEmail}
            setAdminEmail={setAdminEmail}
            adminSecret={adminSecret}
            setAdminSecret={setAdminSecret}
          />
        </Box>
      )}
    </>
  );
};

export default NewRegister;
