export const protectedPaths = {
  userSession: [
    {
      path: "/dashboard",
      cookie: "__nocCreator__u_sid",
      socket: true,
      roles: ["user", "admin"],
    },
    {
      path: "/permits/create",
      cookie: "__nocCreator__u_sid",
      socket: true,
      roles: ["user", "admin"],
    },
    {
      path: "/selectcounties",
      cookie: "__nocCreator__u_sid",
      socket: true,
      roles: ["user", "admin"],
    },
    {
      path: "/defaults",
      cookie: "__nocCreator__u_sid",
      socket: true,
      roles: ["user", "admin"],
    },
    {
      path: "/permits/my-permits",
      cookie: "__nocCreator__u_sid",
      socket: true,
      roles: ["user", "admin"],
    },
    {
      path: "/permits/invitations",
      cookie: "__nocCreator__u_sid",
      socket: true,
      roles: ["user", "admin"],
    },
    {
      path: "/document-uploader",
      cookie: "__nocCreator__u_sid",
      socket: true,
      roles: ["tester"],
    },
    {
      path: "/document-uploader-test",
      cookie: "__nocCreator__u_sid",
      socket: true,
      roles: ["tester"],
    },
    {
      path: "/profilesettings",
      cookie: "__nocCreator__u_sid",
      socket: true,
      roles: ["user", "admin"],
    },
    {
      path: "/contractors",
      cookie: "__nocCreator__u_sid",
      socket: true,
      roles: ["user", "admin"],
    },
    {
      path: "/payment",
      cookie: "__nocCreator__u_sid",
      socket: true,
      roles: ["user", "admin"],
    },
    {
      path: "/subusers",
      cookie: "__nocCreator__u_sid",
      socket: true,
      roles: ["user", "admin"],
    },
    {
      path: "/subscription",
      cookie: "__nocCreator__u_sid",
      socket: true,
      roles: ["user", "admin"],
    },
    {
      path: "/contact",
      cookie: "__nocCreator__u_sid",
      socket: true,
      roles: ["user", "admin"],
    },
    {
      path: "/admin/overview",
      cookie: "__nocCreator__u_sid",
      socket: true,
      roles: ["admin"],
    },
    {
      path: "/admin/customers",
      cookie: "__nocCreator__u_sid",
      socket: true,
      roles: ["admin"],
    },
    {
      path: "/admin/notaries",
      cookie: "__nocCreator__u_sid",
      socket: true,
      roles: ["admin"],
    },
    {
      path: "/admin/tasks",
      cookie: "__nocCreator__u_sid",
      socket: true,
      roles: ["admin"],
    },
    {
      path: "/admin/licenses",
      cookie: "__nocCreator__u_sid",
      socket: true,
      roles: ["admin"],
    },
    {
      path: "/admin/management",
      cookie: "__nocCreator__u_sid",
      socket: true,
      roles: ["admin"],
    },
    {
      path: "/admin/messages",
      cookie: "__nocCreator__u_sid",
      socket: true,
      roles: ["admin"],
    },
  ],
  subUserSession: [
    {
      path: "/dashboard",
      cookie: "__nocCreator__su_sid",
      socket: true,
      roles: ["subUser"],
    },
    {
      path: "/permits/create",
      cookie: "__nocCreator__su_sid",
      socket: true,
      roles: ["subUser"],
    },
    {
      path: "/permits/my-permits",
      cookie: "__nocCreator__su_sid",
      socket: true,
      roles: ["subUser"],
    },
    {
      path: "/permits/invitations",
      cookie: "__nocCreator__su_sid",
      socket: true,
      roles: ["subUser"],
    },
    {
      path: "/selectcounties",
      cookie: "__nocCreator__su_sid",
      socket: true,
      roles: ["subUser"],
    },
    {
      path: "/defaults",
      cookie: "__nocCreator__su_sid",
      socket: true,
      roles: ["subUser"],
    },
    {
      path: "/permits/internal",
      cookie: "__nocCreator__su_sid",
      socket: true,
      roles: ["subUser"],
    },
    {
      path: "/document-uploader",
      cookie: "__nocCreator__su_sid",
      socket: true,
      roles: ["subUser"],
    },
    {
      path: "/document-uploader-test",
      cookie: "__nocCreator__u_sid",
      socket: true,
      roles: ["user", "admin"],
    },
    {
      path: "/profilesettings",
      cookie: "__nocCreator__su_sid",
      socket: true,
      roles: ["subUser"],
    },
    {
      path: "/payment",
      cookie: "__nocCreator__su_sid",
      socket: true,
      roles: ["subUser"],
    },
    {
      path: "/subscription",
      cookie: "__nocCreator__su_sid",
      socket: true,
      roles: ["subUser"],
    },
    {
      path: "/contact",
      cookie: "__nocCreator__su_sid",
      socket: true,
      roles: ["subUser"],
    },
  ],
  notarySession: [
    {
      path: "/notary/dashboard",
      cookie: "__nocCreator__n_sid",
      socket: true,
      roles: ["notary"],
    },
    {
      path: "/notary/finalize",
      cookie: "__nocCreator__n_sid",
      socket: true,
      roles: ["notary"],
    },
    {
      path: "/notary/history",
      cookie: "__nocCreator__n_sid",
      socket: true,
      roles: ["notary"],
    },
    {
      path: "/notary/contact",
      cookie: "__nocCreator__n_sid",
      socket: true,
      roles: ["notary"],
    },
    {
      path: "/notary/settings",
      cookie: "__nocCreator__n_sid",
      socket: true,
      roles: ["notary"],
    },
  ],
  guestSession: [
    {
      path: "/record/signee/register",
      cookie: "__nocCreator__g_sid",
      socket: true,
      roles: ["guest"],
    },
    {
      path: "/notary/signee/register",
      cookie: "__nocCreator__g_sid",
      socket: true,
      roles: ["guest"],
    },
    {
      path: "/signee/manage",
      cookie: "__nocCreator__g_sid",
      socket: true,
      roles: ["guest"],
    },
  ],
};
