import { Box, IconButton, Typography } from "@mui/material";
import { convertIsoToLocalFormat } from "../../../functions/helperFunctions";
import { LoadingButton } from "@mui/lab";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";

const RecordingTab = (props) => {
  const currentSession = useSelector((state) => state.sessions.currentSession);

  const row = props?.selectedRow;
  const { processing_permit } = row;
  const { recording } = processing_permit;

  const [loading, setLoading] = useState(false);

  const handleRefreshRecording = async () => {
    try {
      setLoading(true);

      await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/noc/refresh-recording`,
        {
          task_id: props?.selectedRow?.task_id,
          currentSession: currentSession,
        },
        { withCredentials: true }
      );
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Box
        sx={{
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          mt: 1,
        }}
      >
        <img
          src="/img/website_img/record.png"
          style={{
            width: "100%",
            maxWidth: "5rem",
            height: "auto",
            borderRadius: "20px",
          }}
        />
        {recording?.in_progress && !recording?.is_recorded && (
          <Box sx={{ position: "absolute", top: 0, right: 0 }}>
            <IconButton
              onClick={() => {
                if (loading) {
                  return;
                } else {
                  handleRefreshRecording();
                }
              }}
            >
              <RefreshIcon className={loading ? "rotate" : undefined} />
            </IconButton>
          </Box>
        )}

        {!processing_permit ||
        Object.keys(processing_permit).length === 0 ||
        (!recording?.is_recorded &&
          !recording?.in_progress &&
          (!recording?.failure || recording?.failure.length === 0) &&
          !row?.notarized) ? (
          <Box mt={6}>
            <Typography align="center" px={1} variant="h6" color="primary">
              Recording requests have not yet been initiated
            </Typography>
          </Box>
        ) : !processing_permit ||
          Object.keys(processing_permit).length === 0 ||
          (!recording?.is_recorded &&
            !recording?.in_progress &&
            (!recording?.failure || recording?.failure.length === 0) &&
            row?.notarized &&
            !recording?.auto_record) ? (
          <>
            <Typography variant="h6" color="primary" fontWeight="bold" mt={2}>
              Ready to Start Recording Process
            </Typography>
            <Box sx={{ display: "flex", justifyContent: "center", mt: 5 }}>
              <LoadingButton
                loading={props?.recordLoading}
                onClick={props?.handleStartRecord}
                variant="contained"
              >
                Record Notice of Commencement
              </LoadingButton>
            </Box>
          </>
        ) : recording?.in_progress && !recording?.is_recorded ? (
          <>
            <Typography variant="h6" color="primary" fontWeight="bold" mt={1}>
              Recording in progress
            </Typography>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Typography
                variant="body2"
                color="primary"
                align="center"
                fontWeight="bold"
                px={1}
              >
                {convertIsoToLocalFormat(
                  recording?.started_at,
                  props?.currentUser?.subuser
                    ? props?.currentUser?.subuser?.timezone
                    : props?.currentUser?.user?.personal?.[0]?.timeZone ||
                        "America/New_York",
                  "cccc, LLLL dd, yyyy hh:mm a"
                )}
              </Typography>
            </Box>

            <Typography
              variant="body2"
              color="primary"
              align="center"
              fontWeight="bold"
              px={1}
              mt={2}
            >
              Your documents have been submitted to the recording office. Please
              allow time for their processing and review. We will notify you
              once the process is complete.
            </Typography>
            <Box sx={{ textAlign: "center", mt: 2 }}>
              <img src="/img/gif/HI9M.gif" alt="loading" width={100} />
            </Box>
          </>
        ) : !recording?.in_progress && recording?.is_recorded ? (
          <>
            <Typography
              variant="h6"
              color="primary"
              fontWeight="bold"
              align="center"
              mt={2}
              px={1}
            >
              Recording Process is Completed
            </Typography>
            <Typography
              variant="body2"
              color="primary"
              fontWeight="bold"
              align="center"
              mt={0}
              px={1}
            >
              with status:
            </Typography>
            <Box
              sx={{
                mt: 0.5,
                display: "flex",
                justifyContent: "center",
                border: "1px solid #10b981",
                borderRadius: "5px",
                py: 0.3,
              }}
            >
              <Typography
                variant="body2"
                sx={{ color: "#10b981" }}
                align="center"
                fontWeight="bold"
                px={1}
              >
                {recording?.status || "RECORDED"}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Typography
                variant="body2"
                sx={{ color: "#10b981" }}
                align="center"
                fontWeight="bold"
                px={1}
              >
                {convertIsoToLocalFormat(
                  recording?.finished_at,
                  props?.currentUser?.subuser
                    ? props?.currentUser?.subuser?.timezone
                    : props?.currentUser?.user?.personal?.[0]?.timeZone ||
                        "America/New_York",
                  "cccc, LLLL dd, yyyy hh:mm a"
                )}
              </Typography>
            </Box>
            <Typography
              variant="body2"
              color="primary"
              align="center"
              fontWeight="bold"
              px={1}
              mt={2}
            >
              Your Notice of Commencement document has been successfully
              recorded. You can download it using the same download button on
              the left, as previously.
            </Typography>
          </>
        ) : !recording?.in_progress && recording?.recording_failed ? (
          <>
            <Typography
              variant="h6"
              color="primary"
              fontWeight="bold"
              align="center"
              mt={1}
              px={1}
            >
              Recording Process is Failed
            </Typography>
            <Typography
              variant="body2"
              color="primary"
              fontWeight="bold"
              align="center"
              mt={0}
              px={1}
            >
              with status:
            </Typography>
            <Box
              sx={{
                mt: 0.5,
                display: "flex",
                justifyContent: "center",
                border: "1px solid #f87171",
                borderRadius: "5px",
                py: 0.3,
              }}
            >
              <Typography
                variant="body2"
                sx={{ color: "#f87171" }}
                align="center"
                fontWeight="bold"
                px={1}
              >
                {recording?.status || "UNKNOWN"}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Typography
                variant="body2"
                sx={{ color: "#f87171" }}
                align="center"
                fontWeight="bold"
                px={1}
              >
                {convertIsoToLocalFormat(
                  recording?.finished_at,
                  props?.currentUser?.subuser
                    ? props?.currentUser?.subuser?.timezone
                    : props?.currentUser?.user?.personal?.[0]?.timeZone ||
                        "America/New_York",
                  "cccc, LLLL dd, yyyy hh:mm a"
                )}
              </Typography>
            </Box>
            <Typography
              variant="body2"
              color="primary"
              align="center"
              fontWeight="bold"
              px={1}
              mt={2}
            >
              We’re sorry, but the recording process has failed. The Permit
              Rockstar team is investigating the issue and will automatically
              restart the process. Updates will appear in this tab."
            </Typography>
          </>
        ) : (
          <></>
        )}
      </Box>
    </>
  );
};

export default RecordingTab;
