import {
  Alert,
  Box,
  Button,
  IconButton,
  Snackbar,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { green, red } from "@mui/material/colors";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { useEffect, useState } from "react";
import StartNotarizationModal from "../../modals/StartNotarizationModal";
import ReviewCreatedFormsModal from "../../modals/ReviewCreatedFormsModal";
import ContractorSignerTab from "./ContractorSignerTab";
import OwnerSignerTab from "./OwnerSignerTab";
import ScheduleContractorRonModal from "../../modals/ScheduleContractorRonModal";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { getPermitPackages } from "../../../store/actions/dataActions";
import AddPaymentCardModal from "../../modals/AddPaymentCardModal";
import RefreshIcon from "@mui/icons-material/Refresh";

const NotarizationTab = (props) => {
  const currentSession = useSelector((state) => state.sessions.currentSession);

  const row = props?.selectedRow;
  const dispatch = useDispatch();
  const { processing_permit } = row;
  const stripe = useStripe();
  const elements = useElements();
  const [notarizeModalOpen, setNotarizeModalOpen] = useState(false);
  const [reviewDocModalOpen, setReviewDocModalOpen] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  const [signerValue, setSignerValue] = useState(0);
  const [isFading, setIsFading] = useState(false);
  const [ronScheduleOpen, setRonScheduleOpen] = useState(false);
  const [ronStarted, setRonStarted] = useState(false);
  const [fiveMinBeforeSession, setFiveMinBeforeSession] = useState(false);
  const [loading, setLoading] = useState(false);
  const [assignLoading, setAssignLoading] = useState(false);
  const [addPaymentModalOpen, setAddPaymentModalOpen] = useState(false);
  const [isOwnerSign, setIsOwnerSign] = useState(false);

  const lg = useMediaQuery((theme) => theme.breakpoints.up("lg"));

  const snackHandleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackOpen(false);
  };

  useEffect(() => {
    const docType = row?.processing_permit?.notarization?.notarizing_docs;

    // if (
    //   !row?.processing_permit?.notarization?.finalized &&
    //   row?.processing_permit?.notarization?.contractor?.notarization
    //     ?.only_sign &&
    //   row?.processing_permit?.notarization?.contractor?.notarization?.is_signed
    // ) {
    //   setSignerValue(1);
    // }

    if ([0, 1, 2].includes(docType)) {
      const ownerHasDocs = row?.selected_documents?.some((doc) => {
        if (docType === 0) {
          return true; // Always true if docType is 0
        }

        if (docType >= 1 && doc.name !== "noc") {
          return (
            doc?.signatures?.owner?.sign === true ||
            doc?.signatures?.owner?.notary === true
          ); // True if owner has signed or notarized this document
        }

        return false; // False if no conditions are met
      });
      if (isOwnerSign !== ownerHasDocs) {
        setIsOwnerSign(ownerHasDocs);
        if (!ownerHasDocs && signerValue === 1) {
          setSignerValue(0);
        }
      }
    }
  }, [row]);

  useEffect(() => {
    if (
      props?.selectedRow?.noc_created &&
      !props?.selectedRow?.permit_package_created
    ) {
      props?.setDocNotaryTypeValue(1);
    } else if (props?.selectedRow?.permit_package_created) {
      props?.setDocNotaryTypeValue(0);
    }
  }, [props?.selectedRow]);

  const handleSignerChange = (event, newValue) => {
    setIsFading(true);
    setTimeout(() => {
      setSignerValue(newValue);
      setIsFading(false);
    }, 300); // Duration should match the CSS transition duration
  };

  const handleDocNotaryTypeChange = (event, newValue) => {
    setIsFading(true);
    setTimeout(() => {
      props?.setDocNotaryTypeValue(newValue);
      setIsFading(false);
    }, 300); // Duration should match the CSS transition duration
  };

  useEffect(() => {
    if (
      processing_permit?.notarization?.signing_order === "contractor" &&
      (processing_permit?.notarization?.contractor?.notarization?.is_signed ===
        true ||
        processing_permit?.notarization?.contractor?.notarization
          ?.is_notarized === true)
    ) {
      if (row?.selectedDocuments?.length === 1 || !isOwnerSign) {
        setSignerValue(0);
      } else {
        setSignerValue(1);
      }
    } else if (
      processing_permit?.notarization?.signing_order === "owner" &&
      processing_permit?.notarization?.owner?.notarization?.is_notarized ===
        true
    ) {
      if (row?.selectedDocuments?.length === 1) {
        setSignerValue(0);
      } else {
        setSignerValue(1);
      }
    } else {
      setSignerValue(0);
    }
  }, [props?.selectedRow]);

  useEffect(() => {
    if (ronStarted) {
      const timeOut = setTimeout(() => {
        setRonStarted(false);
      }, 15000);
      return () => clearTimeout(timeOut);
    }
  }, [ronStarted]);

  const getNotaryTypeTabComponent = () => {
    switch (props?.docNotaryTypeValue) {
      case 0:
      case 1:
      case 2:
        return (
          <>
            <Typography
              align="center"
              variant="h5"
              mt={1}
              sx={{ color: "#388e3c" }}
            >
              Notarize/sign{" "}
            </Typography>
            <Typography
              align="center"
              variant="h5"
              mt={1}
              sx={{ color: "#388e3c" }}
            >
              {props?.docNotaryTypeValue === 0
                ? "All Documents"
                : props?.docNotaryTypeValue === 1
                ? "NOC Form"
                : "Permit Forms"}
            </Typography>
            {props?.docNotaryTypeValue === 1 &&
              row?.documents?.noc_affidavit?.is_created === true && (
                <Typography
                  px={2}
                  align="center"
                  variant="body2"
                  fontWeight="bold"
                  color="primary"
                >
                  (Affidavit Included)
                </Typography>
              )}
            <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
              <Button
                disabled={
                  row?.city_scraper?.is_accepted &&
                  !row?.city_scraper?.first_done
                }
                onClick={() => {
                  setNotarizeModalOpen(true);
                }}
                variant="contained"
              >
                Get Started
              </Button>
            </Box>
          </>
        );

      default:
        return <></>;
    }
  };

  const getSignerTabComponent = () => {
    switch (signerValue) {
      case 0:
        return processing_permit?.notarization?.signing_order ===
          "contractor" ? (
          <ContractorSignerTab
            {...props}
            setRonScheduleOpen={setRonScheduleOpen}
            fiveMinBeforeSession={fiveMinBeforeSession}
            setFiveMinBeforeSession={setFiveMinBeforeSession}
            isOwnerSign={isOwnerSign}
          />
        ) : (
          <OwnerSignerTab {...props} processing_permit={processing_permit} />
        );
      case 1:
        return processing_permit?.notarization?.signing_order ===
          "contractor" ? (
          <OwnerSignerTab {...props} processing_permit={processing_permit} />
        ) : Object.keys(processing_permit?.notarization?.contractor).length ===
          0 ? (
          <></>
        ) : (
          <ContractorSignerTab
            {...props}
            setRonScheduleOpen={setRonScheduleOpen}
            fiveMinBeforeSession={fiveMinBeforeSession}
            setFiveMinBeforeSession={setFiveMinBeforeSession}
          />
        );

      default:
        return (
          <ContractorSignerTab
            {...props}
            setRonScheduleOpen={setRonScheduleOpen}
            fiveMinBeforeSession={fiveMinBeforeSession}
            setFiveMinBeforeSession={setFiveMinBeforeSession}
          />
        );
    }
  };

  const handleRefreshContractorSignOnly = async () => {
    try {
      setLoading(true);
      const resp = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/notary/refresh-contractor-sign`,
        {
          task_id: props?.selectedRow?.task_id,
          currentSession: currentSession,
        },
        {
          withCredentials: true,
        }
      );
      if (resp.data.success === true) {
      }
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Box
        sx={{
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          mt: 1,
        }}
      >
        <img
          src="/img/website_img/notarize.png"
          style={{
            width: "100%",
            maxWidth: "4rem",
            height: "auto",
            borderRadius: "20px",
          }}
        />

        {props?.selectedRow?.processing_permit?.notarization?.contractor
          ?.notarization?.only_sign &&
          !props?.selectedRow?.processing_permit?.notarization?.contractor
            ?.notarization?.is_signed &&
          processing_permit?.notarization?.signing_order === "contractor" &&
          signerValue === 0 && (
            <Box sx={{ position: "absolute", top: 0, right: 0 }}>
              <IconButton
                onClick={() => {
                  if (loading) {
                    return;
                  } else {
                    handleRefreshContractorSignOnly();
                  }
                }}
              >
                <RefreshIcon className={loading ? "rotate" : undefined} />
              </IconButton>
            </Box>
          )}

        {!row?.permit_package_created &&
        (!row?.noc_created ||
          row?.documents?.noc_affidavit?.is_created === false) ? (
          //? DEFAULT DISPLAY
          <Box mt={6}>
            <Typography align="center" px={1} variant="h6" color="primary">
              Notarization requests have not yet been initiated
            </Typography>
          </Box>
        ) : //? START NOTARIZATION
        !row?.notarized && !processing_permit?.notarization?.started ? (
          <>
            <Typography
              align="center"
              px={1}
              variant="h6"
              sx={{ pt: 1 }}
              color="primary"
            >
              Select the type of document(s) you would like to notarize
            </Typography>
            <Box
              sx={{
                maxWidth: "100%",
                bgcolor: "background.paper",
              }}
            >
              <Tabs
                value={props?.docNotaryTypeValue}
                onChange={handleDocNotaryTypeChange}
                variant="scrollable"
                scrollButtons="auto"
                allowScrollButtonsMobile
                aria-label="scrollable auto tabs example"
                TabIndicatorProps={{
                  style: {
                    height: "3px", // Customize the height of the indicator
                    bottom: "10px", // Adjust the position closer to the text
                  },
                }}
              >
                <Tab
                  disabled={
                    props?.selectedRow?.noc_created &&
                    !props?.selectedRow?.permit_package_created
                  }
                  label="All Documents"
                  disableRipple
                />
                <Tab
                  disabled={!props?.selectedRow?.noc_created}
                  label="NOC Only"
                  disableRipple
                />
                {/* <Tab
                  disabled={!props?.selectedRow?.permit_package_created}
                  label="Permit Forms"
                  disableRipple
                /> */}
              </Tabs>
            </Box>
            <Box
              sx={{ width: "100%" }}
              className={
                isFading
                  ? "fade-exit fade-exit-active"
                  : "fade-enter fade-enter-active"
              }
            >
              {getNotaryTypeTabComponent()}
            </Box>
          </>
        ) : !row?.notarized &&
          !processing_permit?.notarization?.finalized &&
          processing_permit?.notarization?.started ? (
          <>
            <Box
              sx={{
                maxWidth: "100%",
                bgcolor: "background.paper",
              }}
            >
              <Tabs
                value={signerValue}
                onChange={handleSignerChange}
                variant="scrollable"
                scrollButtons="auto"
                allowScrollButtonsMobile
                aria-label="scrollable auto tabs example"
                TabIndicatorProps={{
                  style: {
                    height: "3px", // Customize the height of the indicator
                    bottom: "10px", // Adjust the position closer to the text
                  },
                }}
              >
                <Tab
                  sx={{
                    ml:
                      Object.keys(processing_permit?.notarization?.contractor)
                        .length === 0 ||
                      (!isOwnerSign &&
                        !props?.selectedRow?.documents?.noc_affidavit
                          ?.is_created)
                        ? 11
                        : undefined,
                  }}
                  label={
                    processing_permit?.notarization?.signing_order ===
                    "contractor"
                      ? "1. Contractor"
                      : "1. Owner/Agent"
                  }
                  disableRipple
                />

                <Tab
                  sx={{
                    visibility:
                      Object.keys(processing_permit?.notarization?.contractor)
                        .length === 0 ||
                      (!isOwnerSign &&
                        !props?.selectedRow?.documents?.noc_affidavit
                          ?.is_created)
                        ? "hidden"
                        : undefined,
                  }}
                  label={
                    processing_permit?.notarization?.signing_order ===
                    "contractor"
                      ? "2. Owner/Agent"
                      : "2. Contractor"
                  }
                  disableRipple
                />
              </Tabs>
            </Box>
            <Box
              sx={{ width: "100%" }}
              className={
                isFading
                  ? "fade-exit fade-exit-active"
                  : "fade-enter fade-enter-active"
              }
            >
              {getSignerTabComponent()}
            </Box>
          </>
        ) : props?.selectedRow?.notarized ? (
          <>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography align="center" variant="h5" color="primary" mt={2}>
                Notarized Permit Package
              </Typography>
              <CheckCircleIcon
                style={{
                  color: green[500],
                  marginLeft: 8,
                  marginTop: 15,
                  fontSize: "30px",
                }}
              />
            </Box>

            <Typography
              variant="body2"
              fontWeight="bold"
              color="primary"
              align="center"
              px={2}
              mt={2}
            >
              All of your documents have been notarized/signed successfully. The
              notarized/signed documents can be downloaded by clicking on the
              download button for each file.
            </Typography>
          </>
        ) : (
          <>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography align="center" variant="h5" color="primary" mt={2}>
                Notarization Overview
              </Typography>
            </Box>

            <Box sx={{ display: "flex", flexDirection: "column", mt: 2 }}>
              {[
                { name: "noc", title: "Notice of Commencement (NOC)" },
                { name: "pp", title: "Permit Forms" },
              ].map((item, index) => {
                const notarized =
                  props?.selectedRow?.notarized ||
                  (item.name === "noc" &&
                    props?.selectedRow?.noc_notarized === true) ||
                  (item.name === "pp" &&
                    props?.selectedRow?.pp_notarized === true)
                    ? true
                    : false;
                return (
                  <Box key={index} mb={3}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="primary"
                        align="center"
                      >
                        {item.title}
                      </Typography>
                      {notarized ? (
                        <CheckCircleIcon
                          style={{
                            color: green[500],
                            marginLeft: 8,

                            fontSize: "25px",
                          }}
                        />
                      ) : (
                        <CancelIcon
                          style={{
                            color: red[500],
                            marginLeft: 8,

                            fontSize: "25px",
                          }}
                        />
                      )}
                    </Box>
                    <Box
                      sx={{
                        mt: 0.5,
                        display: "flex",
                        justifyContent: "center",
                        border: notarized
                          ? "1px solid #10b981"
                          : "1px solid #f44336",
                        borderRadius: "5px",
                        py: 0.3,
                      }}
                    >
                      <Typography
                        variant="body2"
                        sx={{ color: notarized ? "#10b981" : "#f44336" }}
                        align="center"
                        fontWeight="bold"
                        px={1}
                      >
                        {notarized ? "NOTARIZED" : "NOT SELECTED"}
                      </Typography>
                    </Box>
                  </Box>
                );
              })}
            </Box>
          </>
        )}
      </Box>
      <StartNotarizationModal
        open={notarizeModalOpen}
        setOpen={setNotarizeModalOpen}
        selectedRow={props?.selectedRow}
        paymentCards={props?.paymentCards}
        setReviewDocModalOpen={setReviewDocModalOpen}
        setSnackOpen={setSnackOpen}
        setRonScheduleOpen={setRonScheduleOpen}
        contractorSigning={props?.contractorSigning}
        ownerSigning={props?.ownerSigning}
        docNotaryTypeValue={props?.docNotaryTypeValue}
        setAddPaymentModalOpen={setAddPaymentModalOpen}
        setSelectedRow={props?.setSelectedRow}
        pageSize={props?.pageSize}
        setPage={props?.setPage}
        setRowCount={props?.setRowCount}
        activeTab={props?.activeTab}
      />
      <ReviewCreatedFormsModal
        open={reviewDocModalOpen}
        setOpen={setReviewDocModalOpen}
        selectedRow={props?.selectedRow}
        docNotaryTypeValue={props?.docNotaryTypeValue}
      />
      <ScheduleContractorRonModal
        open={ronScheduleOpen}
        setOpen={setRonScheduleOpen}
        selectedRow={props?.selectedRow}
        setRonStarted={setRonStarted}
        setSnackOpen={setSnackOpen}
        fiveMinBeforeSession={fiveMinBeforeSession}
      />
      <AddPaymentCardModal
        addPaymentModalOpen={addPaymentModalOpen}
        setAddPaymentModalOpen={setAddPaymentModalOpen}
        stripe={stripe}
        elements={elements}
        CardElement={CardElement}
      />
      <Snackbar
        sx={{ zIndex: 9999999 }}
        open={snackOpen}
        autoHideDuration={4000}
        onClose={snackHandleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={snackHandleClose}
          severity="success" // Changed severity to success
          sx={{
            width: "100%",
            marginTop: "80px",
            marginLeft: lg ? "280px" : "0px",
            backgroundColor: "#388e3c", // Green shade for success
            color: "#fff",
            fontWeight: "bold",
          }}
          iconMapping={{
            success: <CheckCircleIcon sx={{ color: "#fff" }} />, // Changed to success icon
          }}
        >
          {ronStarted
            ? "RON Session has been scheduled successfully!"
            : "Payment Successful. Thank you"}
        </Alert>
      </Snackbar>
    </>
  );
};

export default NotarizationTab;
