import React, { useCallback, useState, useEffect } from "react";
import { Formik } from "formik";
import axios from "axios";
import {
  Step,
  StepLabel,
  Stepper,
  Typography,
  useMediaQuery,
  useTheme,
  Drawer,
  TextField,
  Box,
  Button,
  Grid,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import ChangePlanFormNavigation from "./ChangePlanFormNavigation";

const ChangePlanMultistepForm = ({
  stepNumber,
  setStepNumber,
  type,
  page,
  setPage,
  tiers,
  setSelectedName,
  isLoad,
  children,
  initialValues,
  onSubmit,
  isExpired,
  cardRemain,
  isSSOLogin,
  setErrorText,
  errorText,
  terms,
  setPlan,
  plan,
  setUserEmail,
  contractorList,
  setContractorList,
  setDuplicatedLicenses,
  selectedName,
  oldPlan,
  checked,
  oldChecked,
  defPlan,
  validLicense,
  lastDisabled,
  setLastDisabled,
  currentUser,
  setIsload,
  selectedPlan,
  mobileSize,
  tabletSize,
  deleteLicenses,
  currLicense,
}) => {
  const theme = useTheme();
  const [stepLabel, setStepLabel] = useState("");
  const [nextLoad, setNextLoad] = useState(false);
  const [open, setOpen] = useState(false);
  const steps = React.Children.toArray(children);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [errorMsg, setErrorMsg] = useState("");
  const [snapshot, setSnapshot] = useState(initialValues);
  const step = steps[stepNumber];
  const totalSteps = steps.length;
  const isLastStep = stepNumber === totalSteps - 1;

  const nextStep = (values) => {
    setSnapshot(values);
    setStepNumber(stepNumber + 1);
  };

  const previousStep = (values) => {
    setSnapshot(values);
    setStepNumber(stepNumber - 1);
  };

  const handleSubmit = async (values, actions) => {
    if (step.props.onSubmit) {
      await step.props.onSubmit;
    }
    if (stepNumber === 1) {
      // setNextLoad(true);

      return onSubmit(values, actions);
    } else {
      actions.setTouched({});
      nextStep(values);
    }
  };

  const updateStepLabel = useCallback(
    (stepIndex) => {
      if (stepIndex >= 0 && stepIndex < steps.length) {
        setStepLabel(steps[stepIndex].props.stepName);
      }
    },
    [steps]
  );

  useEffect(() => {
    updateStepLabel(stepNumber);
  }, [stepNumber]);

  return (
    <Box
      sx={{
        position: "relative",
        height: "calc(100% - 120px)",
        width: "auto",
        my: "auto",
        px: 0.3,
      }}
    >
      <Formik
        enableReinitialize={true}
        initialValues={snapshot}
        onSubmit={handleSubmit}
        validationSchema={step.props.validationSchema}
      >
        {(formik) => (
          <>
            <form onSubmit={formik.handleSubmit}>
              {isSmallScreen ? (
                <Stepper activeStep={stepNumber}>
                  {steps.map((currentStep, id) => {
                    return (
                      <Step key={id}>
                        <StepLabel></StepLabel>
                      </Step>
                    );
                  })}
                </Stepper>
              ) : (
                <Box
                  sx={{
                    my: 2,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Stepper activeStep={stepNumber} sx={{ width: "60%" }}>
                    {steps.map((currentStep, id) => {
                      const label = currentStep.props.stepName;
                      return (
                        <Step key={id}>
                          <StepLabel>{label}</StepLabel>
                        </Step>
                      );
                    })}
                  </Stepper>
                </Box>
              )}
              {isSmallScreen && stepNumber !== 0 && (
                <Typography
                  color="primary"
                  fontWeight="bold"
                  align="center"
                  variant="body1"
                  mt={0}
                  mb={0}
                  pb={0}
                >
                  {stepLabel}
                </Typography>
              )}
              <Box mb={18}>{step}</Box>
              <Box
                sx={{
                  px: 0,
                  py: 0.5,
                  position: "fixed",
                  bottom: 0,
                  left: 0,
                  right: 0,
                  opacity: 1,
                }}
              >
                <ChangePlanFormNavigation
                  mobileSize={mobileSize}
                  tabletSize={tabletSize}
                  selectedPlan={selectedPlan}
                  type={type}
                  plan={plan}
                  validLicense={validLicense}
                  lastDisabled={lastDisabled}
                  setLastDisabled={setLastDisabled}
                  contractorList={contractorList}
                  defPlan={defPlan}
                  terms={terms}
                  oldChecked={oldChecked}
                  checked={checked}
                  selectedName={selectedName}
                  oldPlan={oldPlan}
                  isLoad={isLoad}
                  nextLoad={nextLoad}
                  emailError={formik?.errors}
                  emailValue={formik?.values?.email}
                  setErrorText={setErrorText}
                  errorText={errorText}
                  isSSOLogin={isSSOLogin}
                  stepNumber={stepNumber}
                  isExpired={isExpired}
                  cardRemain={cardRemain}
                  isLastStep={isLastStep}
                  hasPrevious={stepNumber > 0 || false}
                  onBackClick={() => {
                    previousStep(formik?.values);
                    updateStepLabel(stepNumber - 1); // Update the stepLabel when going back
                  }}
                  deleteLicenses={deleteLicenses}
                  currLicense={currLicense}
                  currentUser={currentUser}
                />
              </Box>
            </form>
          </>
        )}
      </Formik>
    </Box>
  );
};

export default ChangePlanMultistepForm;
export const FormStep = ({ stepName = "", children }) => children;
