import {
  Box,
  Card,
  CircularProgress,
  useMediaQuery,
  useTheme,
  Grid2 as Grid,
  Typography,
  IconButton,
} from "@mui/material";
import NocUsage from "../dashboard/NocUsage";
import TotalNoc from "../dashboard/TotalNoc";
import RefreshIcon from "@mui/icons-material/Refresh";
import axios from "axios";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import SubusersGrid from "../datagridActions/SubusersGrid";
import { useFormik } from "formik";
import * as Yup from "yup";
import YupPassword from "yup-password";
import { fetchUserData } from "../../store/actions/userActions";
import { useNavigate } from "react-router-dom";
YupPassword(Yup);

const Subusers = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentSession = useSelector((state) => state.sessions.currentSession);
  const currentUser = useSelector((state) => state[currentSession].user);
  const [year, setYear] = useState(String(new Date().getFullYear()));
  const [nocYears, setNocYears] = useState([year]);
  const theme = useTheme();
  const [pageLoading, setPageLoading] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isCancelExpanded, setIsCancelExpanded] = useState(false);
  const [subuserError, setSubuserError] = useState("");
  const [subuserLoading, setSubuserLoading] = useState(false);
  const [addedText, setAddedText] = useState("");
  const [fetching, setFetching] = useState(false);
  const [selectedUser, setSelectedUser] = useState();
  const [removeSubuserLoading, setRemoveSubuserLoading] = useState(false);
  const [requestsSelectionModel, setRequestsSelectionModel] = useState([]);

  //? SCREEN SIZES ##################################################################
  const miniSize = useMediaQuery("(max-width:320px)");
  const mobileSize = useMediaQuery(theme.breakpoints.down("sm"));
  const tabletSize = useMediaQuery(theme.breakpoints.down("md"));
  const largePC = useMediaQuery(theme.breakpoints.up("xl"));
  const between905and1125 = useMediaQuery(
    "(min-width:900px) and (max-width:1125px)"
  );
  const between1200and1405 = useMediaQuery(
    "(min-width:1200px) and (max-width:1405px)"
  );

  //? ###############################################################################

  const [usageTitle, setUsageTitle] = useState(false);
  const [isUsageFadingOut, setIsUsageFadingOut] = useState(false);

  const [isFadingOut, setIsFadingOut] = useState(false);
  const [totalNocTitle, setTotalNocTitle] = useState(false);
  useEffect(() => {
    if (totalNocTitle) {
      const timeout = setTimeout(() => {
        setIsFadingOut(true);
        const fadeOutTimeout = setTimeout(() => {
          setTotalNocTitle(false);
          setIsFadingOut(false);
        }, 1000);

        return () => clearTimeout(fadeOutTimeout);
      }, 3500);

      return () => clearTimeout(timeout);
      //? //////////////////////////////////////////////////////////////////////////
    }
  }, [totalNocTitle]);

  let fetchCount = 0;

  useEffect(() => {
    if (currentUser?.user?.expired === true) {
      navigate("/subscription");
    }
  }, [currentUser]);

  useEffect(() => {
    if (selectedUser) {
      if (selectedUser?.nocUsage) {
        const yearsSet = new Set();

        selectedUser?.nocUsage.forEach((item) => {
          yearsSet.add(item.year);
        });

        // Convert Set to array, sort it numerically, and update state
        setNocYears([...yearsSet].sort((a, b) => a - b));
      }
    }
  }, [selectedUser]);

  const addSubuserFormik = useFormik({
    enableReinitialize: false,
    initialValues: {
      first: "",
      last: "",
      email: "",
      role: "",
    },
    validationSchema: Yup.object({
      first: Yup.string().min(3).required("First name is required"),
      last: Yup.string().min(3).required("Last name is required"),
      email: Yup.string()
        .required("Email is required")
        .email("Must be a valid email"),
      role: Yup.string().required("Role is required"),
    }),
    onSubmit: async (values, helpers) => {
      setSubuserLoading(true);
      values["timezone"] = Intl.DateTimeFormat().resolvedOptions().timeZone;
      try {
        const resp = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/nocApi/subuser/add-subuser`,
          {
            values,
            currentSession: currentSession,
          },
          { withCredentials: true }
        );
        setIsExpanded(false);
        setAddedText(resp.data.message);
        helpers.resetForm();
      } catch (e) {
        setSubuserError(e.response.data.message);

        if (e.response.data?.type === "email") {
          helpers.setFieldValue("email", "");
        }
      }

      setSubuserLoading(false);
    },
  });

  useEffect(() => {
    if (subuserError !== "") {
      const subuserTimer = setInterval(() => {
        setSubuserError("");
      }, 5000);
      return () => clearInterval(subuserTimer);
    }

    if (addedText !== "") {
      const addedTextTimer = setInterval(() => {
        setAddedText("");
      }, 5000);
      return () => clearInterval(addedTextTimer);
    }
  }, [subuserError, addedText]);

  const handleFetch = async (currentSession) => {
    setFetching(true);
    await dispatch(fetchUserData(currentSession));
    setFetching(false);
  };

  useEffect(() => {
    const fetchUserOnce = async (currentSession) => {
      dispatch(fetchUserData(currentSession));
    };

    if (fetchCount < 1) {
      fetchCount += 1;
      fetchUserOnce(currentSession);
    }
  }, []);

  const handleChartValues = () => {
    let chartList = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

    selectedUser?.nocUsage?.map((item) => {
      if (item.year === year) {
        chartList[item.month] = item.noc;
      }
      return null;
    });
    return chartList;
  };

  return (
    <>
      {pageLoading ? (
        <>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              height: "100vh",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <CircularProgress color="warning" size="10rem" />
          </Box>
        </>
      ) : (
        <>
          <Box
            sx={{
              mx: largePC ? "auto" : 0,
              maxWidth: "1500px",
              py: 1,
              mt: 2,
              backgroundColor: "#EEF2F6",
              mb: tabletSize ? "5rem" : undefined,
            }}
          >
            <Grid
              container
              sx={{
                px: mobileSize ? 1 : tabletSize ? 2 : 3,
              }}
            >
              <Card
                py={tabletSize ? 0 : 3}
                px={tabletSize ? 0 : 3}
                component={Grid}
                container
              >
                <Grid container>
                  {!tabletSize && (
                    <Grid
                      size={{ xs: 12 }}
                      sx={{ mb: 3, position: "relative" }}
                    >
                      <Typography variant="h5" align="center" color="primary">
                        Users {currentUser?.subusers.length}/
                        {parseInt(currentUser?.tier?.subuser, 10) || 0}
                      </Typography>
                      {(parseInt(currentUser?.tier?.subuser, 10) || 0) === 0 ? (
                        <></>
                      ) : (
                        <IconButton
                          sx={{ position: "absolute", right: 0, top: -5 }}
                          className={fetching ? "rotate" : ""}
                          color="primary"
                          onClick={() => handleFetch(currentSession)}
                          disabled={currentUser?.subusers_row?.length === 0}
                        >
                          <RefreshIcon />
                        </IconButton>
                      )}
                    </Grid>
                  )}

                  <Grid size={{ xs: 12 }}>
                    <SubusersGrid
                      setRemoveSubuserLoading={setRemoveSubuserLoading}
                      removeSubuserLoading={removeSubuserLoading}
                      currentUser={currentUser}
                      subusersRow={currentUser?.subusers_row || []}
                      formik={addSubuserFormik}
                      setIsCancelExpanded={setIsCancelExpanded}
                      isCancelExpanded={isCancelExpanded}
                      isExpanded={isExpanded}
                      setIsExpanded={setIsExpanded}
                      subuserError={subuserError}
                      setSubuserError={setSubuserError}
                      subuserLoading={subuserLoading}
                      addedText={addedText}
                      setSelectedUser={setSelectedUser}
                      selectedUser={selectedUser}
                      requestsSelectionModel={requestsSelectionModel}
                      setRequestsSelectionModel={setRequestsSelectionModel}
                      mobileSize={mobileSize}
                      tabletSize={tabletSize}
                      handleFetch={handleFetch}
                      currentSession={currentSession}
                      fetching={fetching}
                    />
                  </Grid>
                </Grid>
              </Card>
              <Grid container spacing={2} sx={{ mt: 2, mb: 3 }}>
                <Grid size={{ xs: 12, md: 7, lg: 8 }}>
                  {selectedUser && selectedUser?.nocCreated > 0 && (
                    <NocUsage
                      year={year}
                      setYear={setYear}
                      nocYears={nocYears}
                      tabletSize={tabletSize}
                      mobileSize={mobileSize}
                      fromSub={true}
                      handleChartValues={handleChartValues}
                      chartSeries={[
                        {
                          name: "Created NOC's",
                          data: handleChartValues(),
                        },
                      ]}
                      sx={{ height: "100%" }}
                      isFadingOut={isUsageFadingOut}
                      usageTitle={usageTitle}
                      setUsageTitle={setUsageTitle}
                    />
                  )}
                </Grid>
                {selectedUser && selectedUser?.nocCreated > 0 && (
                  <Grid
                    size={{ lg: 4, md: 5, xs: 12 }}
                    align="center"
                    sx={{ ml: "auto", mr: "auto" }}
                  >
                    <Box mt={3}>
                      <TotalNoc
                        currentUser={selectedUser}
                        tabletSize={tabletSize}
                        mobileSize={mobileSize}
                        miniSize={miniSize}
                        between905and1125={between905and1125}
                        between1200and1405={between1200and1405}
                        fromSub={true}
                        difference={16}
                        positive={false}
                        value={selectedUser?.nocCreated || 0}
                        isFadingOut={isFadingOut}
                        totalNocTitle={totalNocTitle}
                        setTotalNocTitle={setTotalNocTitle}
                      />
                    </Box>
                    <Box mt={4}>
                      <TotalNoc
                        currentUser={selectedUser}
                        tabletSize={tabletSize}
                        mobileSize={mobileSize}
                        miniSize={miniSize}
                        between905and1125={between905and1125}
                        between1200and1405={between1200and1405}
                        fromSub={true}
                        pp={true}
                        difference={16}
                        positive={false}
                        value={selectedUser?.ppCreated || 0}
                        isFadingOut={isFadingOut}
                        totalNocTitle={totalNocTitle}
                        setTotalNocTitle={setTotalNocTitle}
                      />
                    </Box>
                  </Grid>
                )}
              </Grid>
            </Grid>
            {selectedUser && selectedUser?.nocCreated == 0 && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  backgroundColor: "#fff",
                  p: 3,
                  mx: 3,
                  borderRadius: "16px",
                  boxShadow: "3px 5px 6px 6px rgba(0, 0, 0, 0.1)",
                }}
              >
                <Typography variant="h6" color="primary" align="center">
                  No data is available for display.
                </Typography>
              </Box>
            )}
          </Box>
        </>
      )}
    </>
  );
};

export default Subusers;
