import { LoadingButton } from "@mui/lab";
import {
  Card,
  FormControl,
  Grid2 as Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useTour } from "@reactour/tour";
import { ownerInterest } from "../../data/presets";

const Defaults = (props) => {
  const { isOpen, currentStep } = useTour();

  return (
    <Card
      className="step4"
      py={3}
      px={props?.mobileSize ? 1 : 3}
      mb={2}
      component={Grid}
      sx={!props?.tabletSize ? { width: "100%" } : undefined}
      container
    >
      <Grid container justifyContent="center">
        <Grid size={{ xs: 12 }}>
          <Typography sx={{ textAlign: "center" }} variant="h5">
            {/* {defaultCounty ? "Edit Default" : "Add / Edit Defaults"} */}
            Notice of Commencement Presets
          </Typography>
          <Typography sx={{ textAlign: "center", mb: 5 }} variant="body2">
            {/* {defaultCounty ? "Edit Default" : "Add / Edit Defaults"} */}
            Start by selecting the county for which you'd like to add or edit
            values. For most NOC entries, this default text will appear, but you
            can update it at any time.
          </Typography>
        </Grid>
        <Grid size={{ xs: 12 }} width="100%">
          <Stack
            spacing={props?.mobileSize ? 1 : 3}
            mt={props?.mobileSize ? 0 : 4}
          >
            <FormControl fullWidth variant="filled">
              <InputLabel>Select County</InputLabel>
              <Select
                size={props?.mobileSize ? "small" : undefined}
                disabled={
                  props?.loading ||
                  props?.defaultCounty ||
                  (isOpen && currentStep === 4)
                }
                value={props?.selectedCounty}
                label="Select"
                onChange={(e) => props?.setSelectedCounty(e.target.value)}
                MenuProps={{
                  PaperProps: {
                    style: {
                      marginTop: "1.5px",
                      maxHeight: 150, // Set the desired max height for the dropdown menu
                      width: "auto",
                    },
                  },
                }}
                sx={{
                  maxWidth: "100%", // Set the desired max-width for the input element
                  "& .MuiTypography-body2": {
                    whiteSpace: "wrap", // Prevent text from wrapping or shrinking
                  },
                }}
              >
                {props?.selectList?.map(function (item, i) {
                  return (
                    <MenuItem key={i} value={item}>
                      {item}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            {props?.selectedCounty && (
              <>
                <FormControl fullWidth variant="filled">
                  <InputLabel>General Description of Improvement</InputLabel>
                  <Select
                    size={props?.mobileSize ? "small" : undefined}
                    disabled={
                      props?.loading ||
                      props?.defaultCounty ||
                      (isOpen && currentStep === 4)
                    }
                    value={props?.defaults?.workType?.noc_name}
                    label="Select"
                    onChange={(e) => {
                      const selectedWorkType = props?.workTypeOptions.find(
                        (item) => item.noc_name === e.target.value
                      );
                      if (selectedWorkType) {
                        props?.setDefaults((prev) => ({
                          ...prev,
                          workType: selectedWorkType,
                        }));
                      }
                    }}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          marginTop: "1.5px",
                          maxHeight: 150, // Set the desired max height for the dropdown menu
                          width: "auto",
                        },
                      },
                    }}
                    sx={{
                      maxWidth: "100%", // Set the desired max-width for the input element
                      "& .MuiTypography-body2": {
                        whiteSpace: "wrap", // Prevent text from wrapping or shrinking
                      },
                    }}
                  >
                    {props?.workTypeOptions?.map(function (item, i) {
                      return (
                        <MenuItem key={i} value={item?.noc_name}>
                          {item?.label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <FormControl fullWidth variant="filled">
                  <InputLabel>
                    Owner's Interest in Site of the Improvement
                  </InputLabel>
                  <Select
                    size={props?.mobileSize ? "small" : undefined}
                    disabled={
                      props?.loading ||
                      props?.defaultCounty ||
                      (isOpen && currentStep === 4)
                    }
                    value={props?.defaults?.ownerInterest?.name}
                    label="Select"
                    onChange={(e) => {
                      const selectedInterest = ownerInterest.find(
                        (item) => item.name === e.target.value
                      );
                      if (selectedInterest) {
                        props?.setDefaults((prev) => ({
                          ...prev,
                          ownerInterest: selectedInterest,
                        }));
                      }
                    }}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          marginTop: "1.5px",
                          maxHeight: 150, // Set the desired max height for the dropdown menu
                          width: "auto",
                        },
                      },
                    }}
                    sx={{
                      maxWidth: "100%", // Set the desired max-width for the input element
                      "& .MuiTypography-body2": {
                        whiteSpace: "wrap", // Prevent text from wrapping or shrinking
                      },
                    }}
                  >
                    {ownerInterest?.map(function (item, i) {
                      return (
                        <MenuItem key={i} value={item?.name}>
                          {item?.label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                {/* <TextField
                  sx={{ visibility: !props?.selected ? "hidden" : undefined }}
                  size={props?.mobileSize ? "small" : undefined}
                  disabled={props?.loading}
                  fullWidth
                  label="Owner's Interest in Site of the Improvement"
                  name="interest"
                  onChange={(e) => {
                    props?.setDefaultValues({
                      ...props?.defaultValues,
                      interest: e.target.value,
                    });
                  }}
                  value={props?.defaultValues["interest"]}
                /> */}
              </>
            )}

            <Typography
              variant="body1"
              fontWeight="bold"
              textAlign="center"
              sx={{ minHeight: "1.5rem", color: "#4CAF50" }}
            >
              {props?.errorText}
            </Typography>
            <LoadingButton
              loading={props?.loading}
              fullWidth
              size={props?.mobileSize ? "small" : "large"}
              sx={{
                mt: 2,
                backgroundColor: "primary",
              }}
              onClick={props?.handleSubmit}
              variant="contained"
            >
              {props?.defaultCounty
                ? "Save & Back to Create NOC"
                : "Submit Changes"}
            </LoadingButton>
          </Stack>
        </Grid>
      </Grid>
    </Card>
  );
};

export default Defaults;
