import { useState } from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import {
  Box,
  Divider,
  Popover,
  Typography,
  useMediaQuery,
  useTheme,
  Stack,
  Tabs,
  Tab,
  Badge,
} from "@mui/material";
import { notificationLabels } from "../../data/notificationData";
import NocNotifications from "./notifications/NocNotifications";
import PackageNotifications from "./notifications/PackageNotifications";
import {
  setCurrentPaginationData,
  setForwardNotification,
  setNewNotificationRead,
  updateAllNotifications,
} from "../../store/actions/notificationsActions";
import RequestsNotifications from "./notifications/RequestsNotifications";
import { useLocation, useNavigate } from "react-router-dom";

export const NotificationPopover = (props) => {
  const currentSession = useSelector((state) => state.sessions.currentSession);
  const notifications = useSelector((state) => state.notifications);

  const theme = useTheme();
  const dispatch = useDispatch();
  const { anchorEl, onClose, open } = props;
  const isMd = useMediaQuery(theme.breakpoints.down("md"));

  const [notifyValue, setNotifyValue] = useState(0);
  const [show, setShow] = useState(true);

  const [notificationLoading, setNotificationLoading] = useState("");
  const [paginationLoading, setPaginationLoading] = useState({
    noc: false,
    package: false,
    requests: false,
  });

  const navigate = useNavigate();
  const location = useLocation();

  const handleChange = (event, newValue) => {
    setNotifyValue(newValue);
  };

  const handlePageChange = async (event, page, notificationType) => {
    try {
      if (notifications?.[notificationType]?.pagination?.page === page) {
        return;
      }

      setPaginationLoading((prevState) => ({
        ...prevState,
        [notificationType]: true,
      }));
      const resp = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/notification/navigate-page`,
        {
          notificationType: notificationType,
          newPage: page,
          currentSession: currentSession,
        },
        { withCredentials: true }
      );

      if (resp?.data?.success) {
        await dispatch(
          setCurrentPaginationData(page, notificationType, resp?.data?.data)
        );
      }
    } catch (error) {
      console.error(error);
    } finally {
      setPaginationLoading((prevState) => ({
        ...prevState,
        [notificationType]: true,
      }));
    }
  };

  const handleNotificationClick = async (task) => {
    setNotificationLoading(task?.pp_task_id);
    try {
      const resp = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/notification/set-noc-notification-read`,
        {
          task: task,
          currentSession: currentSession,
          notifications: {
            noc: {
              curr_page: notifications?.noc?.pagination?.page,
            },
            package: {
              curr_page: notifications?.package?.pagination?.page,
            },
            requests: {
              curr_page: notifications?.requests?.pagination?.page,
            },
          },
        },
        { withCredentials: true }
      );

      if (resp?.data?.success && resp?.data?.is_new_task) {
        dispatch(setNewNotificationRead(task));
      }

      if (resp?.data?.success === false && resp?.data?.data) {
        await dispatch(updateAllNotifications(resp?.data?.data));
      } else {
        await dispatch(setForwardNotification(task));
      }

      if (resp?.data?.success) {
        if (location.pathname !== "/permits/my-permits") {
          navigate("/permits/my-permits");
        }
      }

      props.onClose();
    } catch (e) {
      console.error(e);
    } finally {
      setNotificationLoading("");
    }
  };

  return (
    <>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          horizontal: "left",
          vertical: "bottom",
        }}
        onClose={onClose}
        open={open}
        slotProps={{
          paper: {
            sx: {
              border: "3px solid #d6d6d6", // Darker border color
              boxShadow: "5px 2px 8px 5px rgba(0, 0, 0, 0.2)", // Optional: customize shadow if needed
            },
          },
        }}
      >
        <Box
          sx={
            isMd
              ? {
                  display: "flex",
                  width: "100%",
                  py: 1.5,
                  px: 2,
                }
              : {
                  display: "flex",
                  width: "38rem",
                  py: 1.5,
                  px: 2,
                }
          }
        >
          <Box sx={{ flex: 1 }}>
            <Typography align="center" variant="h5" mb={1} color="primary">
              Notifications
            </Typography>
            <Stack>
              <Tabs
                value={notifyValue}
                onChange={handleChange}
                variant="fullWidth"
                indicatorColor="transparent"
                textColor="primary"
              >
                {notificationLabels.map((label, index) => {
                  const key = Object.keys(label)[0];
                  let top;
                  let right;

                  if (key === "noc") {
                    top = 2;
                    right = -42;
                  } else if (key === "package") {
                    top = 2;
                    right = -28;
                  } else if (key === "requests") {
                    top = 2;
                    right = -12;
                  }

                  return (
                    <Tab
                      sx={{
                        py: 0,
                        px: 1,
                        m: 0,
                        border: "2px solid #d3d3d3",
                        borderTopLeftRadius: index === 0 ? "10px" : 0, // Only the first tab
                        borderTopRightRadius:
                          index === notificationLabels.length - 1 ? "10px" : 0, // Last tab
                        boxShadow: "3px 2px 4px rgba(0, 0, 0, 0.1)",
                        backgroundColor:
                          notifyValue === index && show
                            ? "rgba(0, 73, 118, 0.9)"
                            : undefined,
                      }}
                      key={index}
                      label={
                        <Box
                          sx={{ position: "relative", display: "inline-block" }}
                        >
                          <Typography
                            sx={{
                              color:
                                notifyValue === index && show
                                  ? "white"
                                  : "#333",
                              fontWeight: "bold",
                            }}
                            variant="body2"
                          >
                            {label[key]}
                          </Typography>
                          {/* <Badge
                            badgeContent={
                              notifications?.[key]?.newCount || null
                            }
                            color="success"
                            sx={{
                              position: "absolute",
                              top: top,
                              right: right,
                              zIndex: 1,
                            }}
                          /> */}
                        </Box>
                      }
                    />
                  );
                })}
              </Tabs>
            </Stack>
          </Box>
        </Box>
        <Divider />
        {notifyValue === 0 ? (
          <NocNotifications
            notificationLoading={notificationLoading}
            handlePageChange={handlePageChange}
            handleNotificationClick={handleNotificationClick}
            paginationLoading={paginationLoading}
          />
        ) : notifyValue === 1 ? (
          <PackageNotifications
            notificationLoading={notificationLoading}
            handlePageChange={handlePageChange}
            handleNotificationClick={handleNotificationClick}
            paginationLoading={paginationLoading}
          />
        ) : notifyValue === 2 ? (
          <RequestsNotifications
            notificationLoading={notificationLoading}
            handlePageChange={handlePageChange}
            handleNotificationClick={handleNotificationClick}
            paginationLoading={paginationLoading}
          />
        ) : (
          <></>
        )}
      </Popover>
    </>
  );
};
