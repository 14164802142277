import React from "react";
import { Box, Typography, Button } from "@mui/material";
import { keyframes } from "@emotion/react";

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const SessionForcedLogoutBox = ({ onNavigateToLogin }) => {
  return (
    <Box
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent background
        zIndex: 1400, // Higher than other app elements
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        animation: `${fadeIn} 0.3s ease-out`,
      }}
    >
      <Box
        sx={{
          backgroundColor: "#fff",
          borderRadius: "12px",
          padding: "24px",
          width: "90%",
          maxWidth: "400px",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.5)",
          textAlign: "center",
        }}
      >
        <Typography variant="h5" fontWeight="bold" color="error" mb={2}>
          Session Expired
        </Typography>
        <Typography variant="body1" mb={3} color="textSecondary">
          Your session is open on another page or has expired. Please log in
          again to continue.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={onNavigateToLogin}
          sx={{ width: "100%" }}
        >
          Go to Login
        </Button>
      </Box>
    </Box>
  );
};

export default SessionForcedLogoutBox;
