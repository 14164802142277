import React, { useState } from "react";
import { Modal, Box, Button, Typography, IconButton } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CreditCardDisplay from "../../noc/CreditCardDisplay";

const AddDefaultCardModal = ({
  open,
  setOpen,
  paymentCards,
  activeStep,
  setActiveStep,
  title,
  currentUser,
  setAddPaymentModalOpen,
  setIsload,
  setIsLoading,
  setErrorText,
  defaultCard,
  checkDefault,
}) => {
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setOpen(false);
    setIsload(false);
    setIsLoading(false);
    setErrorText("");
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    border: "2px solid #000",
    borderRadius: "20px",
    boxShadow: 24,
    p: 4,
  };

  return (
    <div>
      <Modal
        open={open}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            color="primary"
            id="modal-modal-title"
            align="center"
            variant="h4"
            sx={{ mb: 2 }}
          >
            {title}
          </Typography>
          <Typography align="center" mt={1} mb={3} variant="h6">
            {(paymentCards?.length === 0 || !paymentCards) &&
            currentUser?.subuser?.role !== "Basic User"
              ? "To proceed with your subscription change, please add a credit card."
              : (paymentCards?.length === 0 || !paymentCards) &&
                currentUser?.subuser?.role === "Basic User"
              ? "In order to proceed, you must contact your administrator in order to add a payment card"
              : checkDefault === true && defaultCard === null
              ? "To proceed with your subscription change, please set a default payment card. A default payment method is required for the monthly subscription plan."
              : "Choose Your Payment Method"}
          </Typography>
          {paymentCards?.length === 0 || !paymentCards ? (
            <></>
          ) : (
            <Box sx={{ position: "relative" }}>
              <Box sx={{ cursor: "default" }}>
                <CreditCardDisplay
                  cards={paymentCards}
                  activeStep={activeStep}
                  setActiveStep={setActiveStep}
                  defaultCard={defaultCard}
                  checkDefault={checkDefault}
                  loading={loading}
                  setLoading={setLoading}
                />
              </Box>

              <Box
                sx={{
                  position: "absolute",
                  top: "35%",
                  left: 0,
                  display:
                    paymentCards.length === 0 || activeStep === 0
                      ? "none"
                      : undefined,
                }}
              >
                <IconButton
                  onClick={() => {
                    if (activeStep > 0) {
                      setActiveStep(activeStep - 1);
                    }
                  }}
                >
                  <ArrowBackIcon />
                </IconButton>
              </Box>

              <Box
                sx={{
                  position: "absolute",
                  top: "35%",
                  right: 0,
                  display:
                    paymentCards.length - 1 === activeStep ||
                    paymentCards.length === 0
                      ? "none"
                      : undefined,
                }}
              >
                <IconButton
                  onClick={() => {
                    if (paymentCards.length - 1 !== activeStep) {
                      setActiveStep(activeStep + 1);
                    }
                  }}
                >
                  <ArrowForwardIcon />
                </IconButton>
              </Box>
            </Box>
          )}

          <Box
            sx={{
              display: "flex",
              gap: 1,
              width: "100%",
              justifyContent: "center",
            }}
          >
            {checkDefault === true && defaultCard ? (
              <></>
            ) : (
              <Button
                disabled={loading !== false}
                color="error"
                onClick={handleClose}
                sx={{ mt: 2 }}
              >
                Cancel
              </Button>
            )}

            {((paymentCards?.length === 0 || !paymentCards) &&
              currentUser?.subuser?.role !== "Basic User") ||
            paymentCards?.length > 0 ? (
              <LoadingButton
                disabled={
                  defaultCard === null &&
                  checkDefault === true &&
                  paymentCards?.length > 0
                }
                color="success"
                variant="contained"
                onClick={() => {
                  if (paymentCards?.length === 0 || !paymentCards) {
                    // navigate("/payment");
                    setAddPaymentModalOpen(true);
                  } else if (checkDefault === true && defaultCard) {
                    handleClose();
                  }
                }}
                sx={{ mt: 2 }}
              >
                {(paymentCards?.length === 0 || !paymentCards) &&
                currentUser?.subuser?.role !== "Basic User"
                  ? "Set up Card"
                  : checkDefault === true && defaultCard
                  ? "Save"
                  : "Proceed"}
              </LoadingButton>
            ) : (
              <></>
            )}
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default AddDefaultCardModal;
