import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { AppBar, Box, Toolbar, Typography } from "@mui/material";
import { useSwipeable } from "react-swipeable";
import ErrorIcon from "@mui/icons-material/Error";

const MobileFooter = ({
  pos,
  py = 0,
  pl = 0,
  success = true,
  lockedForms,
  waitingCountiesUnlock,
  waitingText,
  waitingColor,
}) => {
  const currentSession = useSelector((state) => state.sessions.currentSession);
  const currentUser = useSelector((state) => state[currentSession].user);
  const [index, setIndex] = useState(0);
  const footerRef = useRef(null);
  const [footerHeight, setFooterHeight] = useState(0);

  const handleSwipedLeft = () => {
    setIndex(
      (prevIndex) =>
        (prevIndex + 1) %
        currentUser?.displays?.advertising?.footer_texts?.length
    );
  };

  const handleSwipedRight = () => {
    setIndex(
      (prevIndex) =>
        (prevIndex -
          1 +
          currentUser?.displays?.advertising?.footer_texts?.length) %
        currentUser?.displays?.advertising?.footer_texts?.length
    );
  };

  const swipeHandlers = useSwipeable({
    onSwipedLeft: handleSwipedLeft,
    onSwipedRight: handleSwipedRight,
  });

  useEffect(() => {
    if (footerRef.current) {
      setFooterHeight(footerRef.current.clientHeight); // Measure the height of the Toolbar
    }
  }, [footerRef]);

  return (
    <>
      {pos === "bottom" ? (
        <Toolbar
          ref={footerRef}
          sx={{
            px: 0,
            py: py,
            pl: pl,
            position: "fixed",
            bottom: pos === "bottom" ? 0 : undefined,
            top: pos === "top" ? 60 : undefined,
            left: 0,
            right: 0,
            opacity: 1,
            backgroundColor: "#004976",
            zIndex: 99999,
          }}
        >
          <div {...swipeHandlers}>
            {currentUser?.displays?.advertising?.footer_texts?.map(
              (adv, idx) => (
                <Box
                  key={idx}
                  component="a"
                  href={`https://www.${adv?.link}`}
                  target="_blank"
                  sx={{
                    textDecoration: "none",
                    display: idx === index ? "block" : "none",
                  }}
                >
                  <Typography
                    color="#fff"
                    fontWeight={"bold"}
                    variant="body1"
                    sx={{ fontSize: pos === "bottom" ? "12px" : undefined }}
                  >
                    {adv?.text}
                  </Typography>
                </Box>
              )
            )}
          </div>
        </Toolbar>
      ) : (
        <AppBar
          sx={{
            position: "relative",
            pl: 1,
            backgroundColor:
              success === false
                ? "#f44336"
                : waitingCountiesUnlock &&
                  waitingText.startsWith("Another user is")
                ? "#f44336"
                : waitingCountiesUnlock &&
                  waitingText.startsWith("The user has completed")
                ? "#0E870F"
                : undefined,
          }}
        >
          <Toolbar>
            {success === false ? (
              <>
                <ErrorIcon sx={{ mr: 1 }} />
                <Typography
                  color="#fff"
                  fontWeight={"bold"}
                  variant="body1"
                  sx={{
                    fontSize: pos === "top" ? "12px" : undefined,
                    py: py,
                  }}
                >
                  Currently, this county is not available due to a problem with
                  the property appraiser's website.
                </Typography>
              </>
            ) : waitingCountiesUnlock ? (
              <>
                <ErrorIcon sx={{ mr: 1 }} />
                <Typography
                  color="#fff"
                  fontWeight={"bold"}
                  variant="body1"
                  sx={{
                    fontSize: pos === "top" ? "12px" : undefined,
                    py: py,
                    pr: 1,
                  }}
                >
                  {waitingText}
                </Typography>
              </>
            ) : (
              <div {...swipeHandlers}>
                {currentUser?.displays?.advertising?.footer_texts?.map(
                  (adv, idx) => (
                    <Box
                      key={idx}
                      component="a"
                      href={`https://www.${adv?.link}`}
                      target="_blank"
                      sx={{
                        textDecoration: "none",
                        display: idx === index ? "block" : "none",
                      }}
                    >
                      <Typography
                        color="#fff"
                        fontWeight={"bold"}
                        variant="body1"
                        sx={{
                          fontSize: pos === "top" ? "12px" : undefined,
                          py: py,
                        }}
                      >
                        {adv?.text}
                      </Typography>
                    </Box>
                  )
                )}
              </div>
            )}
          </Toolbar>
        </AppBar>
      )}
    </>
  );
};

export default MobileFooter;
