import {
  Typography,
  Box,
  useMediaQuery,
  useTheme,
  Chip,
  Stack,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Alert,
  MobileStepper,
  Button,
  Card,
  CardHeader,
  CardContent,
  Grid,
} from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import TextFields from "./TextFields";
import EngineeringIcon from "@mui/icons-material/Engineering";
import BadgeIcon from "@mui/icons-material/Badge";
import { LoadingButton } from "@mui/lab";
import { useSwipeable } from "react-swipeable";
import { DataGrid } from "@mui/x-data-grid";

const MobileCreateForm = (props) => {
  const [dynamicValues, setDynamicValues] = useState({});
  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = props?.previewImg?.length;
  const navigate = useNavigate();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const addressColumns = [
    {
      field: "owner",
      headerName: "Owner's Name",
      minWidth: 100,
      flex: 2,
      editable: false,
    },
    {
      field: "primaryAddress",
      headerName: "Address",
      minWidth: 100,
      flex: 1,
      editable: false,
    },
  ];

  const handleRowClick = (rowData) => {
    if (props?.selectedPreName === "Citrus") {
      const { street, number, unit, apt, lot, zip, owner } = rowData;

      const values = {
        streetName: street || "",
        streetNumber: number || "",
        unitNumber: unit || "",
        apt: apt || "",
        lot: lot || "",
        zip: zip || "",
        owner: owner || "",
      };
      props?.formik.setValues({ ...props?.formik.values, ...values });
    } else {
      const { street, number, unit, zip, owner } = rowData;

      const values = {
        streetName: street || "",
        streetNumber: number || "",
        unitNumber: unit || "",
        zip: zip || "",
        owner: owner || "",
      };
      props?.formik.setValues({ ...props?.formik.values, ...values });
    }

    props?.setListEnabled(false);
    props?.setCreateEnabled(true);
  };

  const handleSwipedLeft = () => {
    setActiveStep((prevActiveStep) => (prevActiveStep + 1) % maxSteps);
  };

  const handleSwipedRight = () => {
    setActiveStep(
      (prevActiveStep) => (prevActiveStep - 1 + maxSteps) % maxSteps
    );
  };

  const handlers = useSwipeable({
    onSwipedLeft: handleSwipedLeft,
    onSwipedRight: handleSwipedRight,
  });

  return (
    <>
      <Box
        sx={{
          px: 2,
          pt: 2,
          height: "100vh",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 1,
          }}
        >
          <Typography variant="h5">
            {props?.previewEnabled
              ? "Form Preview"
              : props?.listEnabled
              ? "Select Address"
              : "Add Field Values"}
          </Typography>
          <Chip
            sx={{ fontWeight: "bold" }}
            label={props?.mobileRow?.name?.toUpperCase()}
            color="primary"
          />
        </Box>
        <Typography variant="h6">{props?.mobileRow?.ownersName}</Typography>
        <Stack mt={1} direction="row" alignItems="center" spacing={1}>
          <EngineeringIcon sx={{ color: "#333333" }} />
          <FormControl
            fullWidth
            variant="filled"
            disabled={Boolean(props?.previewEnabled)}
          >
            <InputLabel id="licenseType-label">Select Contractor</InputLabel>
            <Select
              disabled={
                Boolean(props?.disableSelect) ||
                Boolean(props?.previewEnabled || props?.listEnabled)
              }
              labelId="license_types"
              name="licenseType"
              label="License Type"
              value={props?.licenseType}
              onChange={(e) => {
                const index = props?.currentUser?.user?.contractors.findIndex(
                  (contractor) => contractor.licenseType === e.target.value
                );

                props?.setSelectedLicenseIndex(index);
                // Use Formik's setFieldValue to update the licenseType
                props?.setLicenseType(e.target.value);

                // Now you can update other form fields based on the selected licenseType
                const selectedContractor =
                  props?.currentUser?.user?.contractors.find(
                    (contractor) => contractor.licenseType === e.target.value
                  );

                props?.setLicenseNumber(selectedContractor.licenseNumber);
                props?.setCopySelected(selectedContractor);
              }}
              MenuProps={{
                PaperProps: {
                  style: {
                    marginTop: "1.5px",
                    maxHeight: 150, // Set the desired max height for the dropdown menu
                    width: "auto",
                    maxWidth: "100%",
                  },
                },
              }}
              sx={{
                maxWidth: "100%", // Set the desired max-width for the input element
                "& .MuiTypography-body2": {
                  whiteSpace: "wrap", // Prevent text from wrapping or shrinking
                },
              }}
            >
              {props?.currentUser?.user?.contractors?.map(function (
                contractor,
                i
              ) {
                const getStyles = () => {
                  let dotColor = "";

                  if (contractor.process_status === "queued") {
                    dotColor = "#004976";
                  } else if (
                    contractor.valid === true &&
                    contractor.confirmed === false
                  ) {
                    dotColor = "#F2CD00";
                  } else if (
                    contractor.valid === true &&
                    contractor.confirmed === true
                  ) {
                    dotColor = "#006400";
                  } else if (contractor.valid === false) {
                    dotColor = "#a21717";
                  }

                  return {
                    "&::after": {
                      content: '""',
                      display: "inline-block",
                      width: "10px", // Adjust the size of the dot
                      height: "10px", // Adjust the size of the dot
                      marginLeft: "5px", // Adjust the spacing between text and dot
                      borderRadius: "50%",
                      backgroundColor: dotColor,
                    },
                  };
                };

                return (
                  <MenuItem
                    key={i}
                    value={contractor.licenseType}
                    sx={getStyles()}
                  >
                    <Typography variant="body2">
                      {contractor.licenseType}
                    </Typography>
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Stack>
        <Stack mt={1} direction="row" alignItems="center" spacing={1}>
          <BadgeIcon sx={{ color: "#333333" }} />
          <Typography
            variant="h5"
            sx={{
              flex: 1,
              color:
                props?.copySelected?.process_status === "queued"
                  ? "#004976"
                  : props?.copySelected?.confirmed === true ||
                    props?.copySelected?.contractorConfirmed === true
                  ? "#006400"
                  : "#990000",
            }}
          >
            {props?.licenseNumber}
          </Typography>
          <Chip
            sx={{ fontWeight: "bold" }}
            label={
              props?.copySelected?.confirmed === true ||
              props?.copySelected?.contractorConfirmed === true
                ? "Valid"
                : "Invalid"
            }
            color={
              props?.copySelected?.confirmed === true ||
              props?.copySelected?.contractorConfirmed === true
                ? "success"
                : "error"
            }
          />
        </Stack>
        {props?.copySelected?.confirmed === false ||
        props?.copySelected?.contractorConfirmed === false ? (
          <Box mt={1}>
            <Alert variant="filled" severity="error">
              <Box
                sx={{
                  pr: 1,
                  mr: 1,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  gap: 2,
                }}
              >
                <Box sx={{ maxWidth: "100vw" }}>
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: "bold",
                    }}
                  >
                    There is an error with your license number.{" "}
                    {props?.currentUser?.subuser
                      ? "Please reach out to your administrator for further assistance."
                      : "The information can be updated through the profile settings."}
                  </Typography>
                </Box>

                {!props?.currentUser?.subuser && (
                  <LoadingButton
                    // loading={loading}
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      navigate("/contractors", {
                        state: { option: props?.copySelected?.licenseType },
                      });
                    }}
                    sx={{
                      color: "#fff", // White text
                      borderColor: "#fff", // White border
                      "&:hover": {
                        backgroundColor: "rgba(255, 255, 255, 0.08)", // Slight white tint on hover for feedback
                        borderColor: "#fff", // Ensure border stays white on hover
                      },
                      "&.Mui-focusVisible": {
                        boxShadow: "0 0 0 3px rgba(255, 255, 255, 0.5)", // White shadow box for focus (accessibility)
                      },
                      "&:active": {
                        backgroundColor: "rgba(255, 255, 255, 0.24)", // White tint when pressed
                      },
                      // This style overrides the default ripple color
                      ".MuiTouchRipple-child": {
                        backgroundColor: "#fff", // White ripple effect
                      },
                    }}
                  >
                    Update
                  </LoadingButton>
                )}
              </Box>
            </Alert>
          </Box>
        ) : undefined}

        {props?.previewEnabled ? (
          // ? PREVIEW ///////////////////////////////////////
          <Box>
            {maxSteps > 1 && (
              <MobileStepper
                steps={maxSteps}
                position="static"
                activeStep={activeStep}
                nextButton={
                  <Button
                    size="small"
                    onClick={handleNext}
                    disabled={activeStep === maxSteps - 1}
                  >
                    Next
                  </Button>
                }
                backButton={
                  <Button
                    size="small"
                    onClick={handleBack}
                    disabled={activeStep === 0}
                  >
                    Back
                  </Button>
                }
              />
            )}
            <div {...handlers}>
              {props?.previewImg?.map((image, idx) => (
                <img
                  key={idx}
                  src={image}
                  style={{
                    width: "100%",
                    height: "auto",
                    display: idx === activeStep ? "block" : "none",
                  }}
                  alt={`Form Page ${idx + 1}`}
                />
              ))}
            </div>
          </Box>
        ) : // ? MORE RESULTS ///////////////////////////////////////
        props?.listEnabled ? (
          <Card>
            <CardHeader
              title={
                <Typography variant="h6">
                  Several addresses were found
                </Typography>
              }
              sx={{ textAlign: "center", pt: 2 }}
            />
            <Typography
              variant="body2"
              align="center"
              sx={{ marginLeft: "1.5rem", marginRight: "1.5rem" }}
            >
              Select the address you wish to use or enter more information in
              the input fields to narrow the search
            </Typography>
            <CardContent sx={{ p: 0 }}>
              <DataGrid
                sx={{
                  ".MuiDataGrid-row:hover": {
                    cursor: "pointer",
                  },
                  ".MuiDataGrid-footer .MuiDataGrid-selectedRowCount": {
                    display: "none",
                  },
                  "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                    outline: "none !important",
                  },
                  "& .MuiDataGrid-columnHeader:focus-within": {
                    outline: "none !important",
                  },
                }}
                rows={props?.addressRow || []}
                columns={addressColumns}
                onRowClick={(params) => {
                  handleRowClick(params.row);
                }}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 5,
                    },
                  },
                }}
                pageSizeOptions={[5, 10, 25, 50, 100]}
              />
            </CardContent>
          </Card>
        ) : (
          <Box mt={2}>
            <form
              noValidate
              autoComplete="off"
              onSubmit={props?.formik?.handleSubmit}
            >
              <Stack spacing={1} mb={2}>
                <TextFields
                  isSuretyRadio={props?.isSuretyRadio}
                  setIsSuretyRadio={props?.setIsSuretyRadio}
                  editFromCreate={props?.editFromCreate}
                  dynamicValues={dynamicValues}
                  setDynamicValues={setDynamicValues}
                  formik={props?.formik}
                  selectedPreName={props?.mobileRow?.name}
                  type={props?.type}
                  inputs={props?.formik?.values}
                  mobile={true}
                  // mobileLoading={props?.editLoading}
                  // isCurrentCountyNotFound={props?.isCurrentCountyNotFound}
                />
              </Stack>
            </form>
          </Box>
        )}
      </Box>
    </>
  );
};

export default MobileCreateForm;
