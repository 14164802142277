import React, { useState } from "react";
import { Modal, Box, Button, Typography, IconButton } from "@mui/material";

const InfoRemoveCountyModal = ({
  open,
  setOpen,
  setIsload,
  setIsLoading,
  setErrorText,
}) => {
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setOpen(false);
    setIsload(false);
    setIsLoading(false);
    setErrorText("");
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    border: "2px solid #000",
    borderRadius: "20px",
    boxShadow: 24,
    p: 4,
  };

  return (
    <div>
      <Modal
        open={open}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            color="primary"
            id="modal-modal-title"
            align="center"
            variant="h4"
            sx={{ mb: 2 }}
          >
            Remove Additional County Subscriptions to Upgrade
          </Typography>

          <Typography
            color="primary"
            id="modal-modal-title"
            align="center"
            variant="h6"
            sx={{ mt: 5, mb: 6 }}
          >
            You currently have active subscriptions for additional counties. To
            upgrade to a higher/lower subscription plan, you must first remove
            these additional county subscriptions. Once removed, you can proceed
            with selecting a new plan that best suits your needs. Upgrading may
            include more or less default counties, helping you avoid unnecessary
            charges.
          </Typography>

          <Box
            sx={{
              display: "flex",
              gap: 1,
              width: "100%",
              justifyContent: "center",
            }}
          >
            <Button
              variant="contained"
              disabled={loading !== false}
              color="error"
              onClick={handleClose}
              sx={{ mt: 2, width: "80%" }}
            >
              Close
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default InfoRemoveCountyModal;
