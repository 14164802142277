import React, { useState } from "react";
import {
  Paper,
  Typography,
  Box,
  Pagination,
  Button,
  Chip,
} from "@mui/material";
import { useSwipeable } from "react-swipeable";
import { LoadingButton } from "@mui/lab";
import axios from "axios";
import { useSelector } from "react-redux";

function CreditCardDisplay({
  cards,
  activeStep,
  setActiveStep,
  defaultCard,
  checkDefault,
  loading,
  setLoading,
}) {
  const currentSession = useSelector((state) => state.sessions.currentSession);
  const [responseText, setResponseText] = useState("");

  const handleSetDefault = async (activeStep) => {
    try {
      setLoading(activeStep);
      const resp = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/stripe/set-default-card`,
        { card: cards[activeStep], currentSession: currentSession },
        { withCredentials: true }
      );

      if (resp.data.success) {
        setResponseText(resp.data.message);
      }
    } catch (e) {
      setResponseText(e.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  const handleNext = () => {
    if (activeStep < cards.length - 1) {
      setActiveStep(activeStep + 1);
    }
  };

  const handleBack = () => {
    if (activeStep > 0) {
      setActiveStep(activeStep - 1);
    }
  };

  const handlers = useSwipeable({
    onSwipedLeft: handleNext,
    onSwipedRight: handleBack,
    preventScrollOnSwipe: true,
    trackMouse: true,
  });

  return (
    <Box
      sx={{
        width: "100%",
        overflow: "hidden",
      }}
      {...handlers}
    >
      <Box
        sx={{ display: "flex", justifyContent: "center", position: "relative" }}
      >
        {cards.map(
          (card, index) =>
            activeStep === index && (
              <React.Fragment key={index}>
                <Paper
                  elevation={4}
                  sx={{
                    position: "relative",
                    padding: 3,
                    margin: 2,
                    textAlign: "center",
                    width: "80%", // Ensure it fits within the viewport
                    transition: "transform 0.3s ease-in-out", // Smooth transition
                    transform: activeStep === index ? "scale(1)" : "scale(0.9)", // Highlight active card
                  }}
                >
                  <Typography variant="h6" component="div">
                    {`**** **** **** ${card?.cardNum.slice(-4)}`}
                  </Typography>
                  <Typography color="text.secondary">
                    {card?.cardName}
                  </Typography>
                  <Typography color="text.secondary">
                    {`Exp: ${card?.expires}`}
                  </Typography>
                </Paper>
                {checkDefault === true && (
                  <Box sx={{ position: "absolute", top: 0, right: 40 }}>
                    {defaultCard === null ? (
                      <>
                        <LoadingButton
                          loading={loading === activeStep}
                          onClick={() => {
                            handleSetDefault(activeStep);
                          }}
                          size="small"
                          variant="contained"
                          color="success"
                        >
                          Set as Default
                        </LoadingButton>
                      </>
                    ) : (
                      <>
                        <Chip label="Default Payment Card" />
                      </>
                    )}
                  </Box>
                )}
              </React.Fragment>
            )
        )}
      </Box>
    </Box>
  );
}

export default CreditCardDisplay;
