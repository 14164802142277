import React, { useState } from "react";
import { Modal, Box, Button, Typography, IconButton } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CreditCardDisplay from "../../noc/CreditCardDisplay";
import { useNavigate } from "react-router-dom";

const DemoPopPaymentModal = ({
  open,
  setOpen,
  paymentCards,
  activeStep,
  setActiveStep,
  handlePayment,
  paymentLoading,
  title,
  currentPopup,
  setCurrentPopup,
  currentUser,
  setAddPaymentModalOpen,
}) => {
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const navigate = useNavigate();

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    border: "2px solid #000",
    borderRadius: "20px",
    boxShadow: 24,
    p: 4,
  };

  return (
    <div>
      <Modal
        open={open}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            color="primary"
            id="modal-modal-title"
            align="center"
            variant="h4"
            sx={{ mb: 1 }}
          >
            {title}
          </Typography>
          <Typography align="center" mt={1} mb={3} variant="h6">
            {(paymentCards?.length === 0 || !paymentCards) &&
            currentUser?.subuser?.role !== "Basic User"
              ? "Before proceeding with the payment, please add a credit card"
              : (paymentCards?.length === 0 || !paymentCards) &&
                currentUser?.subuser?.role === "Basic User"
              ? "In order to proceed, you must contact your administrator in order to add a payment card"
              : "Select Payment Card"}
          </Typography>
          {paymentCards?.length === 0 || !paymentCards ? (
            <></>
          ) : (
            <Box sx={{ position: "relative" }}>
              <Box sx={{ cursor: "default" }}>
                <CreditCardDisplay
                  cards={paymentCards}
                  activeStep={activeStep}
                  setActiveStep={setActiveStep}
                />
              </Box>

              <Box
                sx={{
                  position: "absolute",
                  top: "35%",
                  left: 0,
                  display:
                    paymentCards.length === 0 || activeStep === 0
                      ? "none"
                      : undefined,
                }}
              >
                <IconButton
                  onClick={() => {
                    if (activeStep > 0) {
                      setActiveStep(activeStep - 1);
                    }
                  }}
                >
                  <ArrowBackIcon />
                </IconButton>
              </Box>

              <Box
                sx={{
                  position: "absolute",
                  top: "35%",
                  right: 0,
                  display:
                    paymentCards.length - 1 === activeStep ||
                    paymentCards.length === 0
                      ? "none"
                      : undefined,
                }}
              >
                <IconButton
                  onClick={() => {
                    if (paymentCards.length - 1 !== activeStep) {
                      setActiveStep(activeStep + 1);
                    }
                  }}
                >
                  <ArrowForwardIcon />
                </IconButton>
              </Box>
            </Box>
          )}

          <Box
            sx={{
              display: "flex",
              gap: 1,
              width: "100%",
              justifyContent: "center",
            }}
          >
            <Button
              disabled={paymentLoading}
              color="error"
              onClick={handleClose}
              sx={{ mt: 2 }}
            >
              Cancel
            </Button>
            {((paymentCards?.length === 0 || !paymentCards) &&
              currentUser?.subuser?.role !== "Basic User") ||
            paymentCards?.length > 0 ? (
              <LoadingButton
                loading={paymentLoading}
                color="success"
                variant="contained"
                onClick={() => {
                  if (paymentCards?.length === 0 || !paymentCards) {
                    // navigate("/payment");
                    setAddPaymentModalOpen(true);
                  } else {
                    handlePayment();
                  }
                }}
                sx={{ mt: 2 }}
              >
                {(paymentCards?.length === 0 || !paymentCards) &&
                currentUser?.subuser?.role !== "Basic User"
                  ? "Set up Card"
                  : "Proceed"}
              </LoadingButton>
            ) : (
              <></>
            )}
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default DemoPopPaymentModal;
