const initialState = {
  totalNewCount: 0,
  totalCount: 0,
  noc: {
    newCount: 0,
    data: [],
    pagination: {
      page: 1,
      totalPages: 1,
      totalCount: 0,
    },
  },
  package: {
    newCount: 0,
    data: [],
    pagination: {
      page: 1,
      totalPages: 1,
      totalCount: 0,
    },
  },
  requests: {
    newCount: 0,
    data: [],
    pagination: {
      page: 1,
      totalPages: 1,
      totalCount: 0,
    },
  },
  forwardNotifications: {
    packageId: null,
    category: "",
  },
};

const notificationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "LOAD_NOTIFICATION":
      return {
        ...state,
        totalNewCount: action.payload.total_new_count,
        totalCount: action.payload.total_count,
        noc: {
          ...state.noc,
          newCount: action.payload.types.noc.new_count,
          data: action.payload.types.noc.records,
          pagination: {
            ...state.noc.pagination,
            totalPages: Math.max(
              1,
              Math.ceil(action.payload.types.noc.total_count / 5)
            ),
            totalCount: action.payload.types.noc.total_count,
          },
        },
        package: {
          ...state.package,
          newCount: action.payload.types.package.new_count,
          data: action.payload.types.package.records,
          pagination: {
            ...state.package.pagination,
            totalPages: Math.max(
              1,
              Math.ceil(action.payload.types.package.total_count / 5)
            ),
            totalCount: action.payload.types.package.total_count,
          },
        },
        requests: {
          ...state.requests,
          newCount: action.payload.types.requests.new_count,
          data: action.payload.types.requests.records,
          pagination: {
            ...state.requests.pagination,
            totalPages: Math.max(
              1,
              Math.ceil(action.payload.types.requests.total_count / 5)
            ),
            totalCount: action.payload.types.requests.total_count,
          },
        },
      };
    case "SET_CURRENT_PAGINATION_DATA": {
      const { page, notificationType, data } = action.payload;

      return {
        ...state,
        [notificationType]: {
          ...state[notificationType],
          data: data, // Update the data for the specific notification type
          pagination: {
            ...state[notificationType].pagination,
            page: page, // Update the current page
          },
        },
      };
    }
    case "UPDATE_NOC_NOTIFICATION":
      const nocCategory = action.payload.notificationCounts?.categories?.find(
        (category) => category.category === "noc"
      );

      return {
        ...state,
        totalNewCount: action.payload.notificationCounts.total_new_count,
        totalCount: action.payload.notificationCounts.total_count,
        noc: {
          ...state.noc,
          newCount: nocCategory ? nocCategory.new_count : state.noc.newCount,
          pagination: {
            ...state.noc.pagination,
            totalCount: nocCategory
              ? nocCategory.total_count
              : state.noc.pagination.totalCount,
            totalPages: nocCategory
              ? Math.max(1, Math.ceil(nocCategory.total_count / 5))
              : state.noc.pagination.totalPages,
          },
          data: (() => {
            const updatedData = [...state.noc.data]; // Copy the current data array
            const { data: newNotification } = action.payload; // Extract the new notification
            const index = updatedData.findIndex(
              (item) => item?.pp_task_id === newNotification?.pp_task_id
            );

            if (index !== -1) {
              // If found, update the existing item
              updatedData[index] = {
                ...updatedData[index],
                ...newNotification,
              };
            } else if (state.noc.pagination.page === 1) {
              // If not found and current page is 1, push to front and remove the last one
              updatedData.unshift(newNotification); // Add the new notification to the front
              if (updatedData.length > 5) {
                updatedData.pop(); // Remove the last notification if length exceeds limit
              }
            }

            return updatedData;
          })(), // Immediately invoke to return updated array
        },
      };

    case "SET_NEW_NOTIFICATION_READ":
      const { category, pp_task_id } = action.payload;

      // Safety check to avoid errors if category doesn't exist
      if (!state[category]) return state;

      return {
        ...state,
        totalNewCount: Math.max(0, state.totalNewCount - 1),
        [category]: {
          ...state[category],
          data: state[category].data.map((item) =>
            item.pp_task_id === pp_task_id ? { ...item, new: false } : item
          ),
          newCount: Math.max(0, state[category].newCount - 1),
        },
      };
    case "SET_FORWARD_NOTIFICATIONS":
      return {
        ...state,
        forwardNotifications: {
          packageId: action.payload.pp_task_id,
          category: action.payload.category,
        },
      };
    case "CLEAR_FORWARD_NOTIFICATIONS":
      return {
        ...state,
        forwardNotifications: {
          packageId: null,
          category: "",
        },
      };
    case "UPDATE_ALL_NOTIFICATIONS":
      // Calculate totalNewCount and totalCount
      const totalCounts = Object.values(action.payload).reduce(
        (totals, category) => {
          totals.totalNewCount += category.new_count || 0;
          totals.totalCount += category.total_count || 0;
          return totals;
        },
        { totalNewCount: 0, totalCount: 0 }
      );

      // Update each category's state dynamically
      return {
        ...state,
        totalNewCount: totalCounts.totalNewCount, // Sum of new notifications across all categories
        totalCount: totalCounts.totalCount, // Sum of total notifications across all categories
        ...Object.keys(action.payload).reduce((acc, category) => {
          acc[category] = {
            ...state[category], // Retain existing state for this category
            newCount: action.payload[category].new_count, // Update new count
            data: action.payload[category].records, // Update current page data
            pagination: {
              page: action.payload[category].pagination.page, // Current page
              totalPages: action.payload[category].pagination.total_pages, // Total pages
              totalCount: action.payload[category].pagination.total_count, // Total count
            },
          };
          return acc;
        }, {}),
      };
    default:
      return state;
  }
};

export default notificationsReducer;
