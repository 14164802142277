const initialState = {
  currentRole: null,
  currentSession: null,
  expiredSession: null,
  sessionForcedLogout: false,
  sessionForcedLogoutAt: null,
  userSession: {
    token: null,
    isValidated: null,
    expiry: null,
    wsConnected: null,
  },
  subUserSession: {
    token: null,
    isValidated: null,
    expiry: null,
    wsConnected: null,
  },
  notarySession: {
    token: null,
    isValidated: null,
    expiry: null,
    wsConnected: null,
  },
  guestSession: {
    token: null,
    isValidated: null,
    expiry: null,
    wsConnected: null,
  },
};

const sessionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_SESSION_EXPIRED_FOR_LOGOUT":
      return {
        ...state,
        sessionForcedLogout: true,
        sessionForcedLogoutAt: new Date().toISOString(),
      };
    case "CLEAR_SESSION_EXPIRED_FOR_LOGOUT":
      return {
        ...state,
        sessionForcedLogout: false,
        sessionForcedLogoutAt: null,
      };
    case "EXTEND_EXPIRY":
      const expirySessionName = action.payload.sessionName;
      const extendTime = action.payload.duration;
      return {
        ...state,
        [expirySessionName]: {
          ...state[expirySessionName],
          expiry: extendTime,
        },
      };
    case "SET_EXPIRED_SESSION":
      return {
        ...state,
        expiredSession: action.payload,
      };
    case "UPDATE_SESSION":
      const { sessionType, token, isValidated, expiry } = action.payload;
      if (!state.hasOwnProperty(sessionType)) {
        console.error("Invalid session type provided.");
        return state;
      }
      return {
        ...state,
        [sessionType]: {
          ...state[sessionType],
          token: token,
          isValidated: isValidated,
          expiry: expiry,
        },
      };
    case "UPDATE_SESSION_WS_CONNECTED":
      const { sessionType: wsSessionType, wsConnected } = action.payload;
      if (!state.hasOwnProperty(wsSessionType)) {
        console.error("Invalid session type provided for WebSocket update.");
        return state;
      }
      return {
        ...state,
        [wsSessionType]: {
          ...state[wsSessionType],
          wsConnected: wsConnected,
        },
      };
    case "RESET_SESSION":
      const resetSessionType = action.payload;

      if (!state.hasOwnProperty(resetSessionType)) {
        console.error("Invalid session type provided for reset.");
        return state;
      }
      return {
        ...state,
        currentSession: null,
        currentRole: null,
        expiredSession: null,
        [resetSessionType]: initialState[resetSessionType],
      };
    case "INVALIDATE_SESSION":
      const invalidateSessionType = action.payload;

      if (!state.hasOwnProperty(invalidateSessionType)) {
        console.error("Invalid session type provided for invalidate session.");
        return state;
      }
      return {
        ...state,
        [invalidateSessionType]: {
          ...state[invalidateSessionType],
          isValidated: "invalid",
        },
      };
    case "SET_CURRENT_SESSION":
      if (!action.payload) {
        console.error("Payload is undefined.");
        return state;
      }
      const currentSession = action.payload;

      return {
        ...state,
        currentSession: currentSession,
      };
    case "SET_ROLE":
      if (!action.payload) {
        console.error("Payload is undefined from SET_ROLE");
        return state;
      }
      const currentRole = action.payload;
      return {
        ...state,
        currentRole: currentRole,
      };
    case "CLEAR_ROLE":
      return {
        ...state,
        currentRole: null,
      };
    default:
      return state;
  }
};

export default sessionsReducer;
