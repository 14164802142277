import React, { useEffect } from "react";
import { DateTime } from "luxon";

import {
  Typography,
  Box,
  Card,
  Grid2 as Grid,
  List,
  ListItem,
  Stack,
  Button,
} from "@mui/material";
import { Link } from "react-router-dom";
import { LoadingButton } from "@mui/lab";

const NotaryJobNotice = ({
  currentUser,
  handleSetCompleted,
  infoModal,
  setInfoModal,
  closestJobDate,
  fiveMinBeforeSession,
  elapsed,
  setElapsed,
}) => {
  const getElapsedTime = (closestIso) => {
    // Convert closestIso to DateTime object in UTC
    const closestDateUTC = DateTime.fromISO(closestIso, { zone: "utc" });
    // Get the current time in UTC
    const nowUTC = DateTime.utc();

    // Calculate the difference
    const duration = nowUTC.diff(closestDateUTC, ["hours", "minutes"]);

    // Extract hours and minutes
    const hours = duration.hours;
    const minutes = duration.minutes;

    setElapsed({ hours: Math.floor(hours), minutes: Math.floor(minutes) });
    // Return as a formatted string
  };

  useEffect(() => {
    if (currentUser?.closest_job?.booked_date) {
      getElapsedTime(currentUser?.closest_job?.booked_date);
    }
  }, []);

  useEffect(() => {
    let interval;
    if (currentUser?.closest_job?.booked_date && fiveMinBeforeSession) {
      interval = setInterval(() => {
        getElapsedTime(currentUser?.closest_job?.booked_date);
      }, 5000);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [fiveMinBeforeSession, currentUser?.closest_job?.booked_date]);

  return (
    <Card sx={{ backgroundColor: "#FCF2F0" }}>
      <Grid container px={6} sx={{ mt: 4 }}>
        <Grid size={{ xs: 12 }}>
          <Typography
            color="primary"
            align={fiveMinBeforeSession ? "center" : "left"}
            variant="h3"
          >
            {fiveMinBeforeSession
              ? "RON Session is Started..."
              : "Next Notarization Appointment"}
          </Typography>
          {fiveMinBeforeSession && (
            <>
              {elapsed.minutes < 0 ? (
                <Typography
                  color="primary"
                  variant="body1"
                  fontWeight="bold"
                  align="center"
                >
                  You may join the session up to 5 minutes before the scheduled
                  start time
                </Typography>
              ) : (
                <Typography align="center" variant="h6" mt={2} color="GrayText">
                  Elapsed Time Since Scheduled RON Session:
                </Typography>
              )}
            </>
          )}
          {fiveMinBeforeSession && (
            <>
              <Box align="center" mt={1}>
                {elapsed.minutes >= 0 && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      mt: 0,
                      mb: 3,
                      gap: 0.5,
                    }}
                  >
                    {elapsed.hours > 0 && (
                      <>
                        <Typography color="error" variant="h3">
                          {elapsed.hours}
                        </Typography>
                        <Typography
                          variant="body1"
                          fontWeight="bold"
                          color="error"
                        >
                          {elapsed.hours > 1 ? "Hours" : "Hour"}
                        </Typography>
                      </>
                    )}

                    <Typography
                      sx={{
                        ml: 2,
                        color:
                          (elapsed.hours === 0 || !elapsed.hours) &&
                          elapsed.minutes <= 15
                            ? "#10b981"
                            : (elapsed.hours === 0 || !elapsed.hours) &&
                              elapsed.minutes > 15 &&
                              elapsed.minutes <= 45
                            ? "#ff9800"
                            : "#f04438",
                      }}
                      variant="h3"
                    >
                      {elapsed.minutes < 10
                        ? `0${elapsed.minutes}`
                        : elapsed.minutes}
                    </Typography>
                    <Typography
                      sx={{
                        color:
                          (elapsed.hours === 0 || !elapsed.hours) &&
                          elapsed.minutes <= 15
                            ? "#10b981"
                            : (elapsed.hours === 0 || !elapsed.hours) &&
                              elapsed.minutes > 15 &&
                              elapsed.minutes <= 45
                            ? "#ff9800"
                            : "#f04438",
                      }}
                      variant="body1"
                      fontWeight="bold"
                    >
                      Min
                    </Typography>
                  </Box>
                )}
                <Button
                  disabled={
                    infoModal.rescheduleLoading ||
                    infoModal.completedLoading ||
                    infoModal.voidLoading
                  }
                  sx={{ width: "20rem" }}
                  onClick={() => {
                    window.open(
                      currentUser?.closest_job?.notarization?.notarization
                        ?.bn_session?.notary_url,
                      "_blank"
                    );
                  }}
                  variant="contained"
                >
                  Join Session
                </Button>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  mt: 2,
                  pt: 1,
                  gap: 2,
                  borderTop: "3px solid #d3d3d3",
                }}
              >
                {/* <Button
                  disabled={
                    infoModal.rescheduleLoading ||
                    infoModal.completedLoading ||
                    infoModal.voidLoading
                  }
                  // onClick={() => setInfoModal({ ...infoModal, logsOpen: true })}
                  size="small"
                  color="warning"
                  variant="contained"
                >
                  LOGS
                </Button> */}
                <LoadingButton
                  onClick={() => {
                    setInfoModal({ ...infoModal, rescheduleOpen: true });
                    getElapsedTime(currentUser?.closest_job?.booked_date);
                  }}
                  loading={infoModal.rescheduleLoading}
                  disabled={infoModal.completedLoading || infoModal.voidLoading}
                  size="small"
                  color="secondary"
                  variant="contained"
                >
                  MARK AS EXPIRED
                </LoadingButton>
                <LoadingButton
                  loading={infoModal.completedLoading}
                  disabled={
                    infoModal.rescheduleLoading || infoModal.voidLoading
                  }
                  onClick={handleSetCompleted}
                  size="small"
                  color="success"
                  variant="contained"
                >
                  MARK AS COMPLETED
                </LoadingButton>
              </Box>
            </>
          )}
        </Grid>

        <>
          <Grid
            size={{ sm: 8 }}
            sx={{
              pl: "6rem",
              pt: 3,
              pb: 0,
            }}
          >
            {currentUser?.closest_job === "N/A" ? (
              <Typography variant="h6" sx={{ mt: 3 }}>
                There are no appointments scheduled at the moment. Please wait
                until you receive invitations to require notarization by email
                and text message.
              </Typography>
            ) : (
              <>
                <List>
                  <ListItem sx={{ paddingLeft: 0, textAlign: "center" }}>
                    <Typography
                      component="div"
                      variant="h5"
                      sx={{ display: "block" }}
                    >
                      Date and Time
                      <Typography
                        component="div"
                        sx={{ fontWeight: "bold", color: "#3b663c" }}
                      >
                        {closestJobDate}
                      </Typography>
                    </Typography>
                  </ListItem>

                  <Box sx={{ display: "flex" }}>
                    <ListItem sx={{ paddingLeft: 0 }}>
                      <Typography
                        component="div"
                        variant="body2"
                        sx={{ display: "block" }}
                      >
                        <Typography variant="h6">Signer</Typography>

                        <Stack direction="column">
                          <Typography component="span" variant="body2">
                            {currentUser?.closest_job?.signer
                              .charAt(0)
                              .toUpperCase() +
                              currentUser?.closest_job?.signer.slice(1)}
                          </Typography>
                        </Stack>
                      </Typography>
                    </ListItem>
                    <ListItem sx={{ paddingLeft: 0, maxWidth: "353.33px" }}>
                      <Typography
                        component="div"
                        variant="body2"
                        sx={{ display: "block" }}
                      >
                        <Typography variant="h6">Document Type</Typography>
                        <Stack direction="column">
                          <Typography component="span" variant="body2">
                            Permit Package
                          </Typography>
                          <Typography component="span" variant="body2">
                            {
                              currentUser?.closest_job?.document
                                ?.property_address
                            }
                          </Typography>
                          <Typography component="span" variant="body2">
                            {currentUser?.closest_job?.document?.county}
                          </Typography>
                          <Typography
                            component="a"
                            href={currentUser?.closest_job?.document?.pdf}
                            target="_blank"
                            rel="noopener noreferrer"
                            variant="body2"
                            sx={{
                              maxWidth: "100%",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {currentUser?.closest_job?.document?.pdf}
                          </Typography>
                        </Stack>
                      </Typography>
                    </ListItem>
                  </Box>
                  <Box sx={{ display: "flex" }}>
                    <ListItem sx={{ paddingLeft: 0 }}>
                      <Typography
                        component="div"
                        variant="body2"
                        sx={{ display: "block" }}
                      >
                        <Typography variant="h6">Signer Details</Typography>
                        <Stack direction="column">
                          <Typography component="span" variant="body2">
                            {`${
                              currentUser?.closest_job?.signer_details?.first ||
                              ""
                            } ${
                              currentUser?.closest_job?.signer_details
                                ?.middle || ""
                            } ${
                              currentUser?.closest_job?.signer_details?.last ||
                              ""
                            }`
                              .trim()
                              .replace(/\s+/g, " ")}
                          </Typography>
                          <Typography component="span" variant="body2">
                            {`${
                              currentUser?.closest_job?.signer_details
                                ?.address || ""
                            } ${
                              currentUser?.closest_job?.signer_details?.city ||
                              ""
                            } ${
                              currentUser?.closest_job?.signer_details?.state ||
                              ""
                            }  ${
                              currentUser?.closest_job?.signer_details?.zip ||
                              ""
                            }`
                              .trim()
                              .replace(/\s+/g, " ")}
                          </Typography>
                          <Typography component="span" variant="body2">
                            {currentUser?.closest_job?.signer_details?.mobile}
                          </Typography>
                          <Link href="mailto:martin-w@ww.com" color="inherit">
                            <Typography component="span" variant="body2">
                              {currentUser?.closest_job?.signer_details?.email}
                            </Typography>
                          </Link>
                        </Stack>
                      </Typography>
                    </ListItem>
                    <ListItem sx={{ paddingLeft: 0, maxWidth: "353.33px" }}>
                      <Typography
                        component="div"
                        variant="body2"
                        sx={{ display: "block" }}
                      >
                        <Typography variant="h6">Selected Documents</Typography>
                        <Stack
                          direction="column"
                          sx={{ height: "6rem", overflow: "auto" }}
                        >
                          {currentUser?.closest_job?.notarization?.notarization?.selected_documents?.map(
                            (document, index) => (
                              <Box
                                key={index}
                                sx={{
                                  display: "flex",
                                }}
                              >
                                <Typography component="span" variant="body2">
                                  <Typography component="span" variant="body2">
                                    {document?.name
                                      ?.replace(/_/g, " ")
                                      .replace(/\b\w/g, (char) =>
                                        char.toUpperCase()
                                      )}
                                  </Typography>
                                </Typography>
                              </Box>
                            )
                          )}
                        </Stack>
                      </Typography>
                    </ListItem>
                  </Box>
                </List>
              </>
            )}
          </Grid>
        </>

        <Grid size={{ sm: 4 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center", // Aligns the image to the right
              alignItems: "center", // Centers the image vertically
              height: "100%", // Ensures the Box takes the full height of the grid item
            }}
          >
            <img
              src="/img/logos/stamp.png"
              style={{ width: "60%", height: "auto" }}
              alt="Notary Public Stamp"
            />
            {/* Other component content */}
          </Box>
        </Grid>
      </Grid>
    </Card>
  );
};

export default NotaryJobNotice;
