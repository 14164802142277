import { useEffect, useState, useRef } from "react";
import axios from "axios";
import {
  Box,
  Container,
  Grid2 as Grid,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { disconnectWebsocket } from "../../../store/actions/websocketActions";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import MessageNotifications from "../../admin/MessageNotifications";
import MessageGrid from "../../admin/MessageGrid";

const Messages = () => {
  const currentSession = useSelector((state) => state.sessions.currentSession);
  const currentUser = useSelector((state) => state[currentSession].user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const [reasonsData, setReasonsData] = useState([]);
  const [messagesDataRows, setMessagesDataRows] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedName, setSelectedName] = useState();
  const isSm = useMediaQuery(theme.breakpoints.down("md"));

  const dispatchCount = useRef(0);

  useEffect(() => {
    if (currentUser) {
      if (currentUser?.user?.role !== "admin" || !currentUser?.user?.admin) {
        if (dispatchCount.current < 1) {
          dispatch(disconnectWebsocket(currentSession));
          navigate("/login");
          dispatchCount.current += 1;
        }
      }
    }
  }, [currentUser]);

  useEffect(() => {
    const getMessages = async () => {
      try {
        const reasonsResp = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/nocApi/admin/message-reasons-amounts`,
          { currentSession: currentSession },
          { withCredentials: true }
        );

        setReasonsData(reasonsResp.data.data);
        // setData(resp.data.data.reasons);
      } catch (e) {}
    };
    if (
      !currentUser ||
      currentUser?.user?.role !== "admin" ||
      !currentUser?.user?.admin
    ) {
      return;
    } else {
      getMessages();
    }
  }, []);
  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 2,
          backgroundColor: "#EEF2F6",
        }}
      >
        <Container maxWidth="xl">
          <Grid container spacing={3}>
            <Grid size={{ xs: 12, md: 4, lg: 4 }}>
              <MessageNotifications
                isSm={isSm}
                selectedName={selectedName}
                setSelectedName={setSelectedName}
                setMessagesDataRows={setMessagesDataRows}
                reasonsData={reasonsData}
                setReasonsData={setReasonsData}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
              />
            </Grid>
            <Grid size={{ xs: 12, md: 8, lg: 8 }}>
              <MessageGrid
                isSm={isSm}
                setMessagesDataRows={setMessagesDataRows}
                selectedName={selectedName}
                setSelectedName={setSelectedName}
                title="Income Messages"
                messagesDataRows={messagesDataRows}
                setReasonsData={setReasonsData}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default Messages;
