import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import dayjs from "dayjs";
import * as Yup from "yup";
import {
  Box,
  CircularProgress,
  Container,
  Grid2 as Grid,
  Card,
  Typography,
  List,
  CardHeader,
  CardContent,
} from "@mui/material";

import ListItemWithPopup from "./dashboardComponents/ListItemWithPopup";
import NotaryAvailability from "./dashboardComponents/NotaryAvailability";
import { fetchNotaryData } from "../../store/actions/notaryActions";

const FinishNotary = () => {
  let count = 0;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.notarySession.user);
  const currentSession = useSelector((state) => state.sessions.currentSession);
  const [loadingPage, setLoadingPage] = useState(true);
  const [availabilities, setAvailabilities] = useState([]);
  const [saveLoading, setSaveLoading] = useState(false);
  const [fetching, setFetching] = useState(false);

  const [selectedDays, setSelectedDays] = useState([]);
  const [selectedStartTime, setSelectedStartTime] = useState(null);
  const [selectedEndTime, setSelectedEndTime] = useState(null);
  const [prefillLoading, setPrefillLoading] = useState(false);

  const daysOfWeek = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  const handleFetch = async () => {
    setFetching(true);
    const data = await dispatch(fetchNotaryData(currentSession));

    if (
      data?.data?.on_site_registered === true &&
      data?.data?.bn_registered === true
    ) {
      window.location.reload();
    }
    setFetching(false);
  };

  const daySchema = Yup.object()
    .shape({
      day: Yup.string(),
      available: Yup.boolean(),
      startTime: Yup.date()
        .nullable()
        .typeError("Start time must be a valid date and time"),
      endTime: Yup.date()
        .nullable()
        .typeError("End time must be a valid date and time"),
    })
    .test("time-conditional", "Invalid start or end time", function (obj) {
      if (obj.available) {
        const startTime = new Date(obj.startTime);
        const endTime = new Date(obj.endTime);
        const startMinutes = startTime.getHours() * 60 + startTime.getMinutes();
        const endMinutes = endTime.getHours() * 60 + endTime.getMinutes();

        if (!isValidTime(startTime)) {
          return this.createError({
            path: `${this.path}.startTime`,
            message: "Start time must be on the hour or half-hour",
          });
        }
        if (!isValidTime(endTime)) {
          return this.createError({
            path: `${this.path}.endTime`,
            message: "End time must be on the hour or half-hour",
          });
        }

        if (!obj.startTime || isNaN(startTime.getTime())) {
          return this.createError({
            path: `${this.path}.startTime`, // Updated path
            message: "Start time is required",
          });
        }
        if (!obj.endTime || isNaN(endTime.getTime())) {
          return this.createError({
            path: `${this.path}.endTime`, // Updated path
            message: "End time is required",
          });
        }
        if (
          endMinutes <= startMinutes &&
          !(endTime.getHours() === 0 && endTime.getMinutes() === 0)
        ) {
          // This error handling assumes the function is part of a context where `this.createError` is defined, like a custom validation method
          return this.createError({
            path: `${this.path}.endTime`, // Updated path
            message:
              "End time must be after start time unless it's exactly 00:00 marking the end of the day.",
          });
        }
        const hourDifference =
          ((endTime - startTime + 24 * 60 * 60 * 1000) %
            (24 * 60 * 60 * 1000)) /
          (1000 * 60 * 60);

        if (hourDifference < 1) {
          return this.createError({
            path: `${this.path}.endTime`, // Updated path
            message:
              "There must be at least 1 hour difference between start and end times",
          });
        }
      }
      return true;
    });

  const validationSchema = Yup.object({
    availability: Yup.array()
      .of(daySchema)
      .test(
        "total-hours",
        "Minimum of 1 hours per week required",
        function (availabilityList) {
          let totalMinutes = 0;
          for (const day of availabilityList) {
            if (day.available && day.startTime && day.endTime) {
              // Ensure startTime and endTime are Date objects
              const startTime = new Date(day.startTime);
              const endTime = new Date(day.endTime);
              // Check if the dates are valid
              if (!isNaN(startTime.getTime()) && !isNaN(endTime.getTime())) {
                const diff = endTime.getTime() - startTime.getTime();
                totalMinutes += diff / (1000 * 60);
              }
            }
          }
          const totalHours = totalMinutes / 60;
          return totalHours >= 1;
        }
      ),
  });

  const initialValues = {
    availability: daysOfWeek.map((day) => {
      const availabilityData = availabilities.find((a) => a.day === day);

      return availabilityData
        ? {
            day: day,
            available: availabilityData.available,
            startTime: createTimeFromString(availabilityData.startTime),
            endTime: createTimeFromString(availabilityData.endTime),
          }
        : {
            day: day,
            available: false,
            startTime: null,
            endTime: null,
          };
    }),
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema,
    // validateOnBlur: true, // Disable validation when losing focus
    // validateOnChange: false, // Disable validation on every change
    onSubmit: async (values) => {
      const processedAvailability = values.availability.map((day) => {
        return {
          ...day,
          startTime: day.startTime ? formatTime(day.startTime) : null,
          endTime: day.endTime ? formatTime(day.endTime) : null,
        };
      });

      try {
        setSaveLoading(true);
        const resp = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/nocApi/notary/save-availabilities`,
          { payload: processedAvailability, currentSession: currentSession },
          {
            withCredentials: true,
          }
        );
        if (resp.data.success) {
          setAvailabilities(processedAvailability);
          if (resp.data.status !== "Inactive") {
            dispatch(fetchNotaryData(currentSession));
          }
        }
        setSaveLoading(false);
      } catch (e) {
        setSaveLoading(false);
      }
    },
  });

  function isValidTime(time) {
    if (!time || isNaN(time.getTime())) return false;
    const minutes = time.getMinutes();
    return minutes === 0 || minutes === 30;
  }

  function formatTime(time) {
    const date = new Date(time);
    let hours = date.getHours();
    let minutes = date.getMinutes();

    // Format hours and minutes to ensure two digits
    hours = hours < 10 ? "0" + hours : hours;
    minutes = minutes < 10 ? "0" + minutes : minutes;

    return `${hours}:${minutes}`;
  }

  function createTimeFromString(timeString) {
    if (!timeString) return null;

    const [hours, minutes] = timeString.split(":").map(Number);
    // Create a Day.js object from the current date, then set the hours and minutes
    return dayjs().hour(hours).minute(minutes);
  }

  useEffect(() => {
    if (
      currentUser?.on_site_registered === true &&
      currentUser?.bn_registered === true &&
      currentUser?.availabilities.length > 0
    ) {
      navigate("/notary/dashboard");
      return;
    }

    const getAvailabilities = async () => {
      try {
        const resp = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/nocApi/notary/notary-availability`,
          { currentSession: currentSession },
          {
            withCredentials: true,
          }
        );

        setAvailabilities(resp.data.data);
        setLoadingPage(false);
      } catch (e) {}
      setLoadingPage(false);
    };

    if (count < 1) {
      count += 1;
      getAvailabilities();
    }
  }, [currentUser]);

  const handlePrefillTimes = () => {
    setPrefillLoading(true);
    const updatedAvailability = formik.values.availability.map((day) => {
      if (selectedDays.includes(day.day)) {
        return {
          ...day,
          available: true,
          startTime: selectedStartTime, // Ensure these are in the correct format
          endTime: selectedEndTime,
        };
      } else {
        // For days not included in selectedDays, set available to false and clear times
        return {
          ...day,
          available: false,
          startTime: null,
          endTime: null,
        };
      }
    });

    formik.setFieldValue("availability", updatedAvailability);
    // Reset the selected days and times after updating
    setSelectedDays([]);
    setSelectedStartTime(null);
    setSelectedEndTime(null);
    setPrefillLoading(false);
  };

  return (
    <>
      {loadingPage ? (
        <>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              height: "100vh",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <CircularProgress color="warning" size="10rem" />
          </Box>
        </>
      ) : (
        <>
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              py: 1,
              my: 2,
              backgroundColor: "#EEF2F6",
            }}
          >
            <Container maxWidth="xxl">
              <Grid container spacing={2} justifyContent="center">
                <Grid size={{ xs: 12 }}>
                  <Card sx={{ backgroundColor: "#FCF2F0", py: 4, my: 2 }}>
                    <Grid container px={6}>
                      <Grid size={{ sm: 9 }} sx={{ pt: 3 }}>
                        <Typography align="center" variant="h3">
                          Complete Your Notary Account Setup
                        </Typography>
                        <Typography
                          mt={2}
                          mb={8}
                          align="center"
                          variant="body1"
                        >
                          To begin your journey with us, it's essential to fully
                          activate your notary account. Kindly follow the
                          straightforward steps provided below to commence.
                        </Typography>

                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            width: "100%",
                          }}
                        >
                          <List
                            sx={{
                              width: "100%",

                              bgcolor: "background.paper",
                              "& .MuiListItem-root": {
                                paddingTop: 1,
                                paddingBottom: 1,
                              },
                              borderRadius: "10px",
                              boxShadow: "6px 5px 14px #d3d3d3",
                            }}
                          >
                            <ListItemWithPopup
                              primarySx={{}}
                              finishedStep={false}
                              primary="Locate Your Invitation Link"
                              step="1"
                              secondary="Please check your email inbox for an invitation link titled 'BlueNotary - Create Your Notary Account'."
                              imageUrl="/img/website_img/1.png"
                            />
                            <ListItemWithPopup
                              primarySx={{}}
                              finishedStep={false}
                              primary="Complete Your Account Information"
                              step="2"
                              secondary="Please adhere to the provided instructions, fill in all necessary details, and then select 'Save' to proceed. *It is important that your entered details match those on your Permit Rockstar notary account. "
                              imageUrl="/img/website_img/2.png"
                            />
                            <ListItemWithPopup
                              primarySx={
                                currentUser?.availabilities.length > 0
                                  ? { textDecoration: "line-through" }
                                  : {}
                              }
                              primary="Add Availability"
                              step="3"
                              secondary="Select your preferred time frames for availability."
                            />
                            <ListItemWithPopup
                              handleFetch={handleFetch}
                              fetching={fetching}
                              primary="Final Step"
                              step="4"
                              secondary="Upon completing the setup, please notify your administrator. Once the setup has been approved, you will be able to begin accepting your first Remote Online Notarization (RON) jobs."
                            />
                          </List>
                        </Box>
                        <Box
                          sx={{
                            boxShadow: "6px 5px 14px #d3d3d3",
                            borderRadius: "1rem",
                            mt: "2rem",
                          }}
                        >
                          <Card>
                            <CardHeader title="Set Your RON Session Availability" />
                            <CardContent>
                              <NotaryAvailability
                                formik={formik}
                                availabilities={availabilities}
                                saveLoading={saveLoading}
                                selectedDays={selectedDays}
                                setSelectedDays={setSelectedDays}
                                selectedStartTime={selectedStartTime}
                                setSelectedStartTime={setSelectedStartTime}
                                selectedEndTime={selectedEndTime}
                                setSelectedEndTime={setSelectedEndTime}
                                prefillLoading={prefillLoading}
                                handlePrefillTimes={handlePrefillTimes}
                              />
                            </CardContent>
                          </Card>
                        </Box>
                      </Grid>
                      <Grid size={{ sm: 3 }} mt={20}>
                        <Box
                          sx={{
                            position: "fixed", // Fix the position relative to the viewport
                            top: "50%", // Position at the middle of the viewport vertically
                            transform: "translateY(-50%)", // Shift upwards by half its height to center
                            right: 30, // Align to the right edge of the viewport
                            width: "20%", // Adjust the width as needed
                            // If using a container, adjust the right value to align with the Grid
                          }}
                        >
                          <img
                            src="/img/logos/finalize_notary.png"
                            style={{ width: "100%", height: "auto" }}
                            alt="Notary Public Stamp"
                          />
                          {/* Other component content */}
                        </Box>
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
              </Grid>
            </Container>
          </Box>
        </>
      )}
    </>
  );
};

export default FinishNotary;
