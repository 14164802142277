import React from "react";
import {
  IconButton,
  Collapse,
  Box,
  Typography,
  TextField,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TimezoneSelect from "../layout/TimezoneSelect";

const ExpandableTimezone = (props) => {
  return (
    <Box sx={{ width: "100%", position: "relative", textAlign: "center" }}>
      {/* IconButton centered horizontally at the top */}
      <IconButton
        onClick={() => props?.setOpen(!props?.open)}
        aria-expanded={props?.open}
        aria-label="show more"
        sx={{ zIndex: 1 }} // Ensure some space from the top of the container
      >
        <ExpandMoreIcon
          sx={{
            fontSize: "30px",
            transform: props?.open ? "rotate(180deg)" : "rotate(0deg)",
            transition: "transform 0.3s",
          }}
        />
      </IconButton>

      {/* Collapse expands directly below the IconButton */}
      <Collapse in={props?.open} sx={{ width: "100%" }}>
        <Box pb={1} bgcolor="background.paper">
          <Typography
            color="primary"
            variant={props?.mobileSize ? "body2" : "body1"}
            align="center"
            sx={{
              pb: props?.mobileSize ? 0 : 1,
            }}
          >
            To get started, carefully review the important details provided
            below. Then, fill out the required fields and choose a preferred
            date and time to schedule your Remote Online Notarization (RON)
            session.
          </Typography>
          <TimezoneSelect
            options={props?.timezoneMappings}
            timezone={props?.userTimezone}
            setTimezone={props?.setUserTimeZone}
            changeLocal={true}
            specificWidth={props?.largeTabletSize ? "15rem" : undefined}
            specialDisable={
              props?.isInstantAvailable &&
              !props?.checkInstantLoading &&
              !props?.instantAccepted
            }
            specialDisabled2={
              props?.formik.values.scheduledDate ||
              props?.slots.length !== 0 ||
              props?.instantError !== "" ||
              props?.checkDateLoading
            }
          />
        </Box>
      </Collapse>
    </Box>
  );
};

export default ExpandableTimezone;
