import React, { useState } from "react";
import ApexChart from "react-apexcharts";
import { Box, Select, MenuItem, FormControl, Card } from "@mui/material";

const NotaryPerformanceChart = (props) => {
  const { year, setYear, years, handleChartValues, chartSeries, sx } = props;

  const handleYearChange = (event) => {
    setYear(event.target.value);
    handleChartValues();
  };

  const state = {
    options: {
      chart: {
        height: 350,
        type: "line",
        stacked: false,
        toolbar: {
          show: false,
        },
      },
      colors: ["#28a745", "#ff9800", "#f44336"],
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: [1, 2, 5],
        curve: "smooth",
      },
      plotOptions: {
        bar: {
          columnWidth: "50%",
        },
      },
      fill: {
        opacity: [0.85, 0.25, 1],
        gradient: {
          inverseColors: false,
          shade: "light",
          type: "vertical",
          opacityFrom: 0.85,
          opacityTo: 0.55,
          stops: [0, 100, 100, 100],
        },
      },
      labels: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      markers: {
        size: 0,
      },
      xaxis: {
        type: "month",
      },
      yaxis: {
        title: {
          text: "Jobs",
        },
        min: 0,
        labels: {
          formatter: function (value) {
            return parseInt(value).toFixed(0);
          },
        },
      },
      tooltip: {
        shared: true,
        intersect: false,
        y: {
          formatter: (y) => {
            if (typeof y !== "undefined") {
              return `${y.toFixed(0)} jobs`;
            }
            return y;
          },
        },
      },
    },
  };

  return (
    <Card sx={{ position: "relative" }}>
      <Box sx={{ position: "relative", mt: 4 }}>
        <FormControl
          variant="filled"
          sx={{
            zIndex: 999,
            position: "absolute",
            top: -35,
            right: 30,
            m: 1,
            minWidth: 200,
            backgroundColor: "white",
            borderRadius: 1,
            boxShadow: 1,
          }}
        >
          <Select
            value={year}
            onChange={handleYearChange}
            inputProps={{ "aria-label": "Without label" }}
          >
            {years?.map((year) => (
              <MenuItem key={year} value={year}>
                {year}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <ApexChart
          options={state.options}
          series={chartSeries}
          type="line"
          height={350}
        />
      </Box>
    </Card>
  );
};

export default NotaryPerformanceChart;
