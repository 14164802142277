import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Dialog,
  useMediaQuery,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  ListItemText,
  Checkbox,
} from "@mui/material";
import { useSelector } from "react-redux";
import { LoadingButton } from "@mui/lab";

const UpdateCountiesWarnModal = ({
  open,
  setOpen,
  handleModifyCounty,
  updateCountiesLoading,
  removeAdjustedCountiesList,
  setRemoveAdjustedCountiesList,
  setOpenDrawer,
  setStepNumber,
  needRemoveExtraCountyQty,
  needRemoveFreeCountyQty,
}) => {
  const currentSession = useSelector((state) => state.sessions.currentSession);
  const currentUser = useSelector((state) => state[currentSession].user);
  const tabletSize = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const [filteredSelectedCountiesList, setFilteredSelectedCountiesList] =
    useState([]);

  useEffect(() => {
    const countTotalCounties =
      (parseInt(currentUser?.tier?.additions?.county?.qty) || 0) +
      parseInt(currentUser?.tier?.county);
    const countSelectedCounties = currentUser?.user?.selectedCountiesNOC.length;
    const countUnusedSubscriptionCounties =
      countTotalCounties - countSelectedCounties;

    const unlockedCounties = currentUser?.user?.selectedCountiesNOC.filter(
      (county) => !county.locked
    );
    const lockedCounties = currentUser?.user?.selectedCountiesNOC.filter(
      (county) => county.locked
    );
    const neededCounties = Math.max(
      0,
      needRemoveFreeCountyQty - countUnusedSubscriptionCounties
    );

    // Determine which counties to include in the filtered list
    let filteredSelectedCounties;

    if (needRemoveFreeCountyQty <= unlockedCounties.length) {
      // If unlocked counties are sufficient, use only the unlocked ones
      filteredSelectedCounties = unlockedCounties;
    } else {
      // If unlocked counties are not enough, add some locked counties to meet the requirement
      const remainingCount = neededCounties - unlockedCounties.length;
      filteredSelectedCounties = [
        ...unlockedCounties,
        ...lockedCounties.slice(0, remainingCount),
      ];
    }
    // Set the filtered selected counties list
    setFilteredSelectedCountiesList(filteredSelectedCounties);
  }, [currentUser?.tier, currentUser?.user, needRemoveFreeCountyQty]);

  return (
    <div>
      <Dialog
        open={open}
        fullWidth
        maxWidth={tabletSize ? false : undefined}
        aria-labelledby="responsive-dialog-title"
        PaperProps={{
          className: "popupGrow", // Apply the animation class dynamically
          style: tabletSize
            ? {
                width: "100%", // Set Paper width to 100%
                maxWidth: "100%", // Ensure it does not exceed 100%
                height: "auto", // Optional: in case you also want full height
                margin: 0, // Remove margins to ensure it fits to the edge
              }
            : {},
        }}
      >
        <DialogTitle
          color="primary"
          variant="h4"
          id="responsive-dialog-title"
          sx={{ textAlign: "center", mt: 1 }}
        >
          Remove Counties from Your Subscription
        </DialogTitle>
        <Box sx={{ display: "flex", justifyContent: "center", mt: -2 }}>
          <img
            alt="avatar_circle"
            src="/img/website_img/pp_avatar_circle.png"
            style={{ width: "10rem", height: "auto", zIndex: 2 }}
          />
        </Box>
        <DialogContent sx={{ px: 2 }}>
          <Box>
            <DialogContentText sx={{ mb: 0, mt: -3, textAlign: "center" }}>
              You need to remove additional counties from your subscription to
              be able to change to a higher/lower subscription plan. Once
              removed, you can proceed with the selected new plan that best
              suits your needs. Upgrading may include more or less default
              counties, helping you avoid unnecessary charges.
            </DialogContentText>

            <DialogContentText
              sx={{ mb: 1, textAlign: "center", fontWeight: "bold" }}
              color="primary"
            >
              <span
                style={{
                  fontSize: "24px",
                  color: needRemoveExtraCountyQty === 0 ? "#0a885e" : "#7a211b",
                  fontWeight: "bold",
                }}
              >
                {needRemoveExtraCountyQty}
              </span>{" "}
              paid counties will be removed from your additional monthly
              subscription
            </DialogContentText>
            {needRemoveFreeCountyQty > 0 ? (
              <Box>
                <Typography
                  variant="body2"
                  color="primary"
                  fontWeight="bold"
                  align="center"
                  px={2}
                  pb={1}
                >
                  You need to select{" "}
                  <span
                    style={{
                      fontSize: "24px",
                      color:
                        needRemoveFreeCountyQty -
                          removeAdjustedCountiesList.length >
                        0
                          ? "#7a211b"
                          : "#0a885e",
                      fontWeight: "bold",
                    }}
                  >
                    {needRemoveFreeCountyQty -
                      removeAdjustedCountiesList.length}
                  </span>{" "}
                  {needRemoveFreeCountyQty -
                    removeAdjustedCountiesList.length <=
                  1
                    ? "county"
                    : "counties"}{" "}
                  to be removed from your current selection. This is required
                  because your new selected plan has fewer allowed counties.
                </Typography>

                <Box>
                  <FormControl variant="filled" fullWidth>
                    <InputLabel id="county-select-label">
                      Select Counties
                    </InputLabel>
                    <Select
                      labelId="county-select-label"
                      id="county-select"
                      multiple
                      value={removeAdjustedCountiesList}
                      onChange={() => {}} // Prevent the default behavior of Select's onChange
                      MenuProps={{
                        PaperProps: {
                          style: {
                            marginTop: "1.5px",
                            maxHeight: 170, // Set the desired max height for the dropdown menu
                            width: "auto",
                            maxWidth: "100%",
                          },
                        },
                      }}
                      sx={{
                        maxWidth: "100%", // Set the desired max-width for the input element
                        "& .MuiTypography-body2": {
                          whiteSpace: "wrap", // Prevent text from wrapping or shrinking
                        },
                      }}
                      renderValue={(selected) =>
                        selected
                          .map(
                            (name) =>
                              filteredSelectedCountiesList.find(
                                (c) => c.name === name
                              )?.name
                          )
                          .join(", ")
                      }
                    >
                      {filteredSelectedCountiesList.map((county) => (
                        <MenuItem
                          key={county.name}
                          value={county.name}
                          onClick={() => {
                            const isSelected =
                              removeAdjustedCountiesList.includes(county.name);

                            // Toggle the selection
                            if (isSelected) {
                              setRemoveAdjustedCountiesList(
                                removeAdjustedCountiesList.filter(
                                  (name) => name !== county.name
                                )
                              );
                            } else if (
                              removeAdjustedCountiesList.length <
                              needRemoveFreeCountyQty
                            ) {
                              setRemoveAdjustedCountiesList([
                                ...removeAdjustedCountiesList,
                                county.name,
                              ]);
                            }
                          }}
                        >
                          <Checkbox
                            checked={removeAdjustedCountiesList.includes(
                              county.name
                            )} // Reflect the current state
                          />
                          <ListItemText primary={county.name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </Box>
            ) : (
              <></>
            )}
          </Box>

          <Box
            sx={{
              gap: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "center",
              mt: 2,
            }}
          >
            <LoadingButton
              loading={updateCountiesLoading}
              disabled={
                needRemoveFreeCountyQty - removeAdjustedCountiesList.length > 0
              }
              color="success"
              onClick={() => {
                handleModifyCounty();
              }}
              fullWidth
              variant="contained"
            >
              PROCEED
            </LoadingButton>
            <Button
              disabled={updateCountiesLoading}
              onClick={() => {
                setOpen(false);
                setRemoveAdjustedCountiesList([]);
                setOpenDrawer(false);
                setStepNumber(0);
              }}
              color="error"
              fullWidth
            >
              Cancel
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default UpdateCountiesWarnModal;
