import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Card,
  CardContent,
  Collapse,
  FormHelperText,
  Grid2 as Grid,
  IconButton,
  InputAdornment,
  Modal,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useRef, useState } from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { TimePicker, DatePicker } from "@mui/x-date-pickers";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import dayjs from "dayjs";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import { setExpiredSession } from "../../../store/actions/sessionsActions";
import { disconnectWebsocket } from "../../../store/actions/websocketActions";
import { useNavigate } from "react-router-dom";

const ChangeRonComponent = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const expandableRef = useRef(null);
  const mobileSize = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const tabletSize = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const currentSession = useSelector((state) => state.sessions.currentSession);
  const largeTabletSize = useMediaQuery((theme) =>
    theme.breakpoints.down("lg")
  );
  const [slots, setSlots] = useState([]);
  const [slotIndex, setSlotIndex] = useState(-1);
  const [availableLoading, setAvailableLoading] = useState(false);
  const [dateChanged, setDateChanged] = useState(false);
  const [scheduleError, setScheduleError] = useState("");
  const [tempDate, setTempDate] = useState(null);
  const [tempTime, setTempTime] = useState(null);
  const [unavailableText, setUnavailableText] = useState("");

  const handleDateChange = (newDate) => {
    setTempDate(newDate);
  };

  const handleTimeChange = (newTime) => {
    setTempTime(newTime);
  };

  function formatSlotToUSDateTime(slot) {
    const date = new Date(slot.year, slot.month - 1, slot.day_of_month); // JavaScript months are 0-indexed
    const options = { month: "long", day: "numeric", year: "numeric" };
    const formattedDate = date.toLocaleDateString("en-US", options);

    // Convert 'HH:MM' format to 12-hour format with AM/PM
    const [hours, minutes] = slot.time.split(":");
    const hours12 = hours % 12 || 12; // Convert 0 to 12 for midnight
    const amPm = hours < 12 ? "AM" : "PM";
    const formattedTime = `${hours12}:${minutes} ${amPm}`;

    return `${formattedDate}, ${formattedTime}`;
  }

  function convertObjectToDayjs(dateInfo) {
    // Day.js month is zero-indexed, subtract 1
    const monthZeroIndexed = dateInfo.month - 1;

    // Constructing the full date string
    const dateString = `${dateInfo.year}-${String(
      monthZeroIndexed + 1
    ).padStart(2, "0")}-${String(dateInfo.day_of_month).padStart(2, "0")}`;

    // Constructing the time string
    const timeString = dateInfo.time;

    // Creating Day.js objects for date and time
    const date = dayjs(dateString);
    const time = dayjs(timeString, "HH:mm");

    // Setting the states
    setTempDate(date);
    setTempTime(time);
  }

  const handleChangeRONSession = async () => {
    try {
      setAvailableLoading(true);
      if (tempDate && tempTime) {
        setScheduleError("");
        setUnavailableText("");
        setSlots([]);
        setSlotIndex(-1);
        const dateObj = {
          day_of_month: tempDate.date(), // Get the day of the month
          month: tempDate.month() + 1, // Months are zero-indexed, add 1 for 1-12 format
          year: tempDate.year(),
          time: tempTime.format("HH:mm"), // Formats the time in 'HH:mm' format
        };

        //! CALL AXIOS
        const resp = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/nocApi/notary/change-ron-sessions-date`,
          {
            task_id: props?.taskId,
            user_zone: props?.userZone,
            ask_date: dateObj,
            currentSession: currentSession,
          },
          { withCredentials: true }
        );
        if (resp?.data?.success === false) {
          setScheduleError(resp?.data?.message);
        } else {
          if (resp?.data?.slots?.length > 0) {
            setSlots(resp?.data?.slots);
            setSlotIndex(-1);
            setScheduleError("");
            setUnavailableText(
              "The selected date and time is unavailable. Please select another date and time."
            );
            setTempDate(null);
            setTempTime(null);
          } else {
            //? SUCCESS
            setDateChanged(true);
            props?.setBookedISO(resp?.data?.iso_date);
            props?.setMustReschedule(false);
            props?.setUiTexts((prev) => ({
              ...prev,
              description:
                "We have scheduled a notarization session for you. You can join the session through the button provided on our platform 5 minutes before the scheduled time. Before joining the session, you will need to complete the identity verification process. This includes biometric face authentication using your mobile phone and validating your ID card. Once both steps are completed, you will be redirected to the signing session to meet your notary. Please note that there is a 15-minute grace period after the scheduled time, after which the notarization session will no longer be available, and a new appointment will need to be scheduled.",
              idWarn:
                "In the meantime, make sure you have your identification documents ready.",
            }));
          }
        }
      } else {
        setScheduleError("Please select a date and time for your notarization");
      }
    } catch (error) {
      if (error.response.status === 401) {
        //? UNAUTHORIZED
        dispatch(setExpiredSession(true));
        dispatch(disconnectWebsocket(currentSession.replace("Session ", ""))); // Disconnect the websocket);
        navigate("/login");
      }
    } finally {
      setAvailableLoading(false);
    }
  };

  return (
    <Modal open={props?.open} sx={{ overflow: "auto" }}>
      <Box
        sx={{
          overflowY: "none",
          position: "relative",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          maxHeight: "90%",
          maxWidth: "70rem",
          bgcolor: "background.paper",
          borderRadius: "18px",
          boxShadow: 24,
          px: 4,
          py: 2,
          animation: "fadeIn 0.5s ease-in-out",
        }}
      >
        <Typography
          id="modal-modal-title"
          variant="h4"
          align="center"
          component="h2"
          color="primary"
        >
          Change your Remote Online Notarization (RON) Appointment
        </Typography>
        <Typography
          align="center"
          variant="body2"
          color="primary"
          fontWeight="bold"
          mb={2}
        >
          Choose a date that is convenient for you. Please note, contractors can
          reschedule your appointment if needed.
        </Typography>
        <Box sx={{ position: "absolute", right: 10, top: 10 }}>
          <IconButton
            disabled={availableLoading}
            onClick={() => {
              props?.setOpen(false);
              setDateChanged(false);
              setTempDate(null);
              setTempTime(null);
              setSlotIndex(-1);
              setSlots([]);
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Box
          sx={{
            maxHeight: "35.3rem", // Adjust the max height as needed
            overflowY: "auto",
            height: "calc(90vh - 410px)",
            border: "2px solid #d3d3d3",
            mb: 5,
            p: 2,
            borderRadius: "18px",
            boxShadow: "3px 4px 12px rgba(0, 0, 0, 0.3)", // Added box shadow
          }}
        >
          <Box sx={{ mb: 2, px: 0.5 }}></Box>

          {/* FURTHER APPOINTMENT */}
          <Collapse in={true}>
            <div ref={expandableRef}>
              <Grid container spacing={mobileSize ? 1 : 2} sx={{ mb: 2 }}>
                <Grid
                  size={{ xs: 12, lg: 6 }}
                  sx={{ mx: mobileSize ? "0px" : "auto" }}
                >
                  <Card elevation={mobileSize ? 0 : 4}>
                    <CardContent
                      sx={{ pt: mobileSize ? 0 : 2, px: tabletSize ? 1 : 2 }}
                    >
                      <Typography
                        variant={mobileSize ? "h6" : "h5"}
                        align="center"
                        sx={{
                          mb:
                            props?.schedule?.isAvailable || mobileSize ? 2 : 3,
                        }}
                      >
                        Select a date and time for your notarization
                      </Typography>

                      <Typography align="center" color="error">
                        {scheduleError}
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          gap: 2,
                          justifyContent: "center",
                        }}
                      >
                        <DatePicker
                          label="Select Date"
                          value={tempDate}
                          onChange={handleDateChange}
                          disabled={availableLoading || dateChanged}
                          disablePast
                        />
                        <TimePicker
                          viewRenderers={{
                            hours: renderTimeViewClock,
                            minutes: renderTimeViewClock,
                          }}
                          closeOnSelect={false}
                          label="Select Time"
                          sx={{
                            borderRadius: "10px",
                          }}
                          value={tempTime}
                          onChange={handleTimeChange}
                          // renderInput={(params) => <TextField {...params} />}
                          disabled={
                            availableLoading || !tempDate || dateChanged
                          }
                          minutesStep={5}
                        />
                      </Box>

                      <FormHelperText
                        sx={{ textAlign: "center", color: "error.main" }}
                      >
                        {unavailableText}
                      </FormHelperText>
                      <Grid container spacing={1}>
                        <Grid
                          size={{ sm: 6, xs: 12 }}
                          sx={{ order: mobileSize ? 2 : 1 }}
                        >
                          <Button
                            onClick={() => {
                              setSlotIndex(-1);
                              setSlots([]);
                              setUnavailableText("");
                              setScheduleError("");
                            }}
                            disabled={slots?.length === 0 || dateChanged}
                            fullWidth
                            sx={{ mt: mobileSize ? 0 : 2 }}
                            variant="contained"
                            color="error"
                          >
                            Cancel
                          </Button>
                        </Grid>
                        <Grid
                          size={{ sm: 6, xs: 12 }}
                          sx={{ order: mobileSize ? 1 : 2 }}
                        >
                          <LoadingButton
                            loading={availableLoading}
                            onClick={handleChangeRONSession}
                            disabled={!tempDate || !tempTime || dateChanged}
                            fullWidth
                            size="small"
                            variant="contained"
                            sx={{ mt: mobileSize ? 1 : 2 }}
                            color={"success"}
                            startIcon={
                              <InputAdornment position="start">
                                <CheckCircleIcon style={{ color: "white" }} />
                              </InputAdornment>
                            }
                          >
                            Change Appointment
                          </LoadingButton>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
              {dateChanged && (
                <>
                  <Typography
                    mt={6}
                    align="center"
                    variant="h5"
                    sx={{ color: "#10b981" }}
                  >
                    Appointment changed successfully!
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      mt: 2,
                    }}
                  >
                    <Button
                      onClick={() => {
                        props?.setOpen(false);
                        setDateChanged(false);
                        setTempDate(null);
                        setTempTime(null);
                        setSlotIndex(-1);
                        setSlots([]);
                      }}
                      sx={{ width: "10rem" }}
                      variant="contained"
                    >
                      Close
                    </Button>
                  </Box>
                </>
              )}
            </div>
          </Collapse>

          <Grid
            container
            spacing={largeTabletSize ? 1 : 2}
            px={1}
            align="center"
          >
            {slots.length !== 0 && (
              <Grid size={{ xs: 12 }} sx={{ mt: 2 }}>
                <Typography variant="h6" color="primary">
                  Optimal Scheduling Options
                </Typography>
              </Grid>
            )}

            {slots?.map((slot, index) => (
              <Grid size={{ xs: 12, md: 4, sm: 6 }} key={index}>
                <Button
                  onClick={() => {
                    if (slotIndex === index) {
                      setTempDate(null);
                      setTempTime(null);
                      setSlotIndex(-1);
                    } else {
                      convertObjectToDayjs(slot);

                      setSlotIndex(index);
                    }
                  }}
                  fullWidth
                  variant={slotIndex === index ? "contained" : "outlined"}
                >
                  {formatSlotToUSDateTime(slot)}
                </Button>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </Modal>
  );
};

export default ChangeRonComponent;
