import { configureStore, combineReducers } from "@reduxjs/toolkit";
import createWebSocketMiddleware from "./middleware/createWebSocketMiddleware";
import { thunk } from "redux-thunk";

import sessionsReducer from "./reducers/sessionsReducer";

export const initialReducer = combineReducers({
  sessions: sessionsReducer, // Minimal setup
});

const store = configureStore({
  reducer: initialReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      createWebSocketMiddleware("userSession"),
      createWebSocketMiddleware("notarySession"),
      createWebSocketMiddleware("guestSession"),
      createWebSocketMiddleware("subUserSession"),
      thunk
    ),
});

export default store;
