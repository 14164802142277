// PromoDialogContent.js
import React from "react";
import {
  Box,
  Button,
  DialogTitle,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

const PurchaseDownload = ({
  textAnimationState,
  paymentState,
  setPaymentModalOpen,
  setOpen,
}) => {
  const navigate = useNavigate();
  return (
    <>
      <DialogTitle
        color="primary"
        variant="h4"
        id="responsive-dialog-title"
        sx={{ textAlign: "center", mt: 1 }}
      >
        Download Your Permit Package Forms
      </DialogTitle>
      <Box sx={{ display: "flex", justifyContent: "center", mt: -2 }}>
        <img
          alt="avatar_circle"
          src="/img/website_img/pp_avatar_circle.png"
          style={{ width: "15rem", height: "auto", zIndex: 2 }}
        />
      </Box>
      <DialogContent sx={{ px: 2 }}>
        <Box className={textAnimationState}>
          {paymentState ? <></> : <></>}
          <DialogContentText sx={{ mb: 1, mt: -3, textAlign: "center" }}>
            You've reached your free download limit of 5 permit packages. To
            continue, you can purchase access to download all the forms in this
            permit package for only{" "}
            <span style={{ fontWeight: "bold", fontSize: "20px" }}>$5</span>.
          </DialogContentText>

          <DialogContentText
            sx={{ mb: 3, textAlign: "center" }}
            color="primary"
          >
            This includes all forms in the current permit package.
          </DialogContentText>

          <DialogContentText
            variant={"h5"}
            sx={{
              mb: 3,
              px: 1,
              textAlign: "center",
              fontWeight: "bold",
              color: "#004976",
            }}
          >
            Want to save even more? Upgrade your subscription for unlimited
            downloads and greater savings!
          </DialogContentText>
        </Box>

        <Box
          sx={{
            gap: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
            mt: 1,
          }}
        >
          <Button
            color="success"
            onClick={() => {
              setPaymentModalOpen(true);
            }}
            fullWidth
            variant="contained"
          >
            PAY $5 TO DOWNLOAD
          </Button>
          <Button
            onClick={() => {
              navigate("/subscription?change=true");
            }}
            color="primary"
            fullWidth
            variant="contained"
          >
            UPGRADE PLAN AND SAVE MORE
          </Button>
          <Button onClick={() => setOpen(false)} color="error" fullWidth>
            Cancel
          </Button>
        </Box>
      </DialogContent>
    </>
  );
};

export default PurchaseDownload;
