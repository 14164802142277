import {
  Select,
  InputLabel,
  MenuItem,
  FormControl,
  Card,
  CardContent,
  CardHeader,
  Box,
  Grid,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LoadingButton } from "@mui/lab";

import ApexChart from "react-apexcharts";
import { styled } from "@mui/material/styles";
const Chart = styled(ApexChart)``;

const useChartOptions = (labels) => {
  const theme = useTheme();

  return {
    chart: {
      background: "transparent",
      stacked: false,
      toolbar: {
        show: false,
      },
    },
    colors: ["#FFD700"],
    dataLabels: {
      enabled: false,
    },
    fill: {
      opacity: 1,
      type: "solid",
    },
    grid: {
      borderColor: theme.palette.divider,
      strokeDashArray: 2,
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    legend: {
      show: true,
    },
    plotOptions: {
      bar: {
        columnWidth: "25rem",
      },
    },
    stroke: {
      colors: ["transparent"],
      show: true,
      width: 2,
    },
    theme: {
      mode: theme.palette.mode,
    },
    xaxis: {
      axisBorder: {
        color: theme.palette.divider,
        show: true,
      },
      axisTicks: {
        color: theme.palette.divider,
        show: true,
      },
      categories: labels,
      labels: {
        offsetY: 5,
        style: {
          colors: theme.palette.text.secondary,
          fontSize: "15px",
        },
        rotate: -45,
        rotateAlways: true,
      },
    },
    yaxis: {
      labels: {
        formatter: (value) => (value > 0 ? `${value}` : `${value}`),
        offsetX: -10,
        style: {
          colors: theme.palette.text.secondary,
          fontSize: "15px",
        },
      },
    },
  };
};

const TotalUsersByCategory = (props) => {
  const {
    chartSeries,
    sx,
    setCategorySelect,
    handleCategoryValues,
    categorySelect,
    labels,
    userFromDate,
    userToDate,
    userLoading,
    userDisabled,
    setUserFromDate,
    setUserToDate,
    clearUserDate,
    handleCustomUserChart,
  } = props;
  const chartOptions = useChartOptions(labels);
  const categoriesSelect = ["Day", "Week", "Month"];
  const handleChange = (event) => {
    setCategorySelect(event.target.value);
    handleCategoryValues();
  };

  return (
    <Card sx={sx} style={{ position: "relative" }}>
      <CardHeader title="Total Users by Timeframe" />
      <CardContent>
        <Box
          sx={{
            position: "absolute",
            top: 30,
            right: 30,
            width: "10rem",
          }}
        >
          <FormControl fullWidth variant="filled">
            <InputLabel>Select Timeframe</InputLabel>
            <Select
              value={categorySelect}
              label="select"
              onChange={handleChange}
            >
              {categoriesSelect.map(function (item, i) {
                return (
                  <MenuItem key={i} value={item}>
                    {item}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Box>
        <Chart
          height={350}
          options={chartOptions}
          series={chartSeries}
          type="bar"
          width="100%"
        />
        <Grid container spacing={2}>
          <Grid item sm={12} lg={4}>
            <DatePicker
              sx={{ width: "100%" }}
              label="From Date"
              value={userFromDate}
              onChange={(val) => setUserFromDate(val)}
            />
          </Grid>
          <Grid item sm={12} lg={4}>
            <DatePicker
              sx={{ width: "100%" }}
              label="To Date"
              value={userToDate}
              onChange={(val) => setUserToDate(val)}
            />
          </Grid>
          <Grid item sm={12} lg={2}>
            <LoadingButton
              loading={userLoading}
              disabled={userDisabled}
              fullWidth
              onClick={handleCustomUserChart}
              size="large"
              sx={{ mt: 0 }}
              variant="contained"
              color="primary"
            >
              SELECT
            </LoadingButton>
          </Grid>
          <Grid item sm={12} lg={2}>
            <LoadingButton
              loading={userLoading}
              disabled={userDisabled}
              fullWidth
              onClick={clearUserDate}
              size="large"
              sx={{ mt: 0 }}
              variant="contained"
              color="warning"
            >
              CLEAR
            </LoadingButton>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default TotalUsersByCategory;
