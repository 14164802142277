export const loadNotifications = (data) => {
  return {
    type: "LOAD_NOTIFICATION",
    payload: data,
  };
};

export const setCurrentPaginationData = (page, notificationType, data) => {
  return {
    type: "SET_CURRENT_PAGINATION_DATA",
    payload: { page, notificationType, data },
  };
};

export const updateNocNotification = (data, notificationCounts) => {
  return {
    type: "UPDATE_NOC_NOTIFICATION",
    payload: { data, notificationCounts },
  };
};

export const updateAllNotifications = (notifications) => {
  return {
    type: "UPDATE_ALL_NOTIFICATIONS",
    payload: notifications,
  };
};

export const setNewNotificationRead = (data) => {
  return {
    type: "SET_NEW_NOTIFICATION_READ",
    payload: data,
  };
};

export const clearForwardNotification = () => {
  return {
    type: "CLEAR_FORWARD_NOTIFICATIONS",
  };
};

export const setForwardNotification = (data) => {
  return {
    type: "SET_FORWARD_NOTIFICATIONS",
    payload: data,
  };
};
