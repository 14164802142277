import { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { Box, Container, Grid2 as Grid, CircularProgress } from "@mui/material";
import { disconnectWebsocket } from "../../../store/actions/websocketActions";
import { useNavigate } from "react-router-dom";
import AdminNocUsage from "../admin/AdminNocUsage";
import Analytics from "../../admin/Analytics";
import UserOverview from "../../admin/UserOverview";
import NocByCounties from "../../admin/NocByCounties";
import NocSuccessFail from "../../admin/NocSuccessFail";
import TasksByCategory from "../../admin/TasksByCategory";
import FailedNOC from "../../admin/FailedNOC";
import TotalUsersByCategory from "../../admin/TotalUsersByCategory";

import NotaryOwerviewCard from "./NotaryOwerviewCard";
import {
  Group,
  EventAvailable,
  EventBusy,
  AssignmentLate,
} from "@mui/icons-material";
import TotalNotarizationByCounty from "../../admin/TotalNotarizationByCounty";
import NotarizationsByCategory from "../../admin/NotarizationsByCategory";

const Overview = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentSession = useSelector((state) => state.sessions.currentSession);
  const currentUser = useSelector((state) => state[currentSession].user);
  const [analyticsData, setAnalyticsData] = useState();
  const [dataLoading, setDataLoading] = useState(true);
  const [year, setYear] = useState(String(new Date().getFullYear()));
  const [userYear, setUserYear] = useState(String(new Date().getFullYear()));

  const [sortValue, setSortValue] = useState("Default");
  const [sortData, setSortData] = useState([]);
  const [sortLabels, setSortLabels] = useState([]);

  const [notaryCountySortValue, setNotaryCountySortValue] = useState("Default");
  const [notarySortData, setNotarySortData] = useState([]);
  const [notarySortLabels, setNotarySortLabels] = useState([]);

  const [categorySelect, setCategorySelect] = useState("Day");
  const [chartLabels, setChartLabels] = useState({});
  const [currentChartLabel, setCurrentChartLabel] = useState("");

  const [notarySelect, setNotarySelect] = useState("Day");
  const [notaryChartLabels, setNotaryChartLabels] = useState({});
  const [notaryCurrentChartLabel, setNotaryCurrentChartLabel] = useState("");

  const [failedCategorySelect, setFailedCategorySelect] = useState("Day");
  const [failedChartLabels, setFailedChartLabels] = useState({});
  const [currentFailedChartLabel, setCurrentFailedChartLabel] = useState("");

  const [userCategorySelect, setUserCategorySelect] = useState("Day");
  const [userChartLabels, setUserChartLabels] = useState({});
  const [currentUserChartLabel, setCurrentUserChartLabel] = useState("");
  const [notaryData, setNotaryData] = useState();

  const [userFromDate, setUserFromDate] = useState(null);
  const [userToDate, setUserToDate] = useState(null);
  const [userDisabled, setUserDisabled] = useState(true);
  const [userLoading, setUserLoading] = useState(false);
  const [isUserCustom, setIsUserCustom] = useState(false);
  const [customUserData, setCustomUserData] = useState([]);
  const [customUserChartLabel, setCustomUserChartLabel] = useState({});
  const [customCurrentUserChartLabel, setCustomCurrentUserChartLabel] =
    useState({});

  const [failedNocFromDate, setFailedNocFromDate] = useState(null);
  const [failedNocToDate, setFailedNocToDate] = useState(null);
  const [failedNocDisabled, setFailedNocDisabled] = useState(true);
  const [failedNocLoading, setFailedNocLoading] = useState(false);
  const [isFailedNocCustom, setIsFailedNocCustom] = useState(false);
  const [customFailedNocData, setCustomFailedNocData] = useState([]);
  const [customFailedNocChartLabel, setCustomFailedNocChartLabel] = useState(
    {}
  );
  const [
    customCurrentFailedNocChartLabel,
    setCustomCurrentFailedNocChartLabel,
  ] = useState({});

  const [successNocFromDate, setSuccessNocFromDate] = useState(null);
  const [successNocToDate, setSuccessNocToDate] = useState(null);
  const [successNocDisabled, setSuccessNocDisabled] = useState(true);
  const [successNocLoading, setSuccessNocLoading] = useState(false);
  const [isSuccessNocCustom, setIsSuccessNocCustom] = useState(false);
  const [customSuccessNocData, setCustomSuccessNocData] = useState([]);
  const [customSuccessNocChartLabel, setCustomSuccessNocChartLabel] = useState(
    {}
  );
  const [
    customCurrentSuccessNocChartLabel,
    setCustomCurrentSuccessNocChartLabel,
  ] = useState({});

  const [notaryFromDate, setNotaryFromDate] = useState(null);
  const [notaryToDate, setNotaryToDate] = useState(null);
  const [notaryDisabled, setNotaryDisabled] = useState(true);
  const [notaryLoading, setNotaryLoading] = useState(false);
  const [isNotaryCustom, setIsNotaryCustom] = useState(false);
  const [customNotaryData, setCustomNotaryData] = useState([]);
  const [customNotaryChartLabel, setCustomNotaryChartLabel] = useState({});
  const [customCurrentNotaryChartLabel, setCustomCurrentNotaryChartLabel] =
    useState({});

  let dataCount = 0;
  let fetchCount = 0;
  const dispatchCount = useRef(0);

  useEffect(() => {
    if (currentUser) {
      if (currentUser?.user?.role !== "admin" || !currentUser?.user?.admin) {
        if (dispatchCount.current < 1) {
          dispatch(disconnectWebsocket(currentSession));
          navigate("/login");
          dispatchCount.current += 1; // Modify ref value
        }
      }
    }
  }, [currentUser]);

  const clearUserDate = () => {
    setUserToDate(null);
    setUserFromDate(null);
    setIsUserCustom(false);
    setUserCategorySelect("Day");
  };
  const clearFailedNocDate = () => {
    setFailedNocToDate(null);
    setFailedNocFromDate(null);
    setIsFailedNocCustom(false);
    setFailedCategorySelect("Day");
  };
  const clearSuccessNocDate = () => {
    setSuccessNocToDate(null);
    setSuccessNocFromDate(null);
    setIsSuccessNocCustom(false);
    setCategorySelect("Day");
  };

  const clearNotaryDate = () => {
    setNotaryToDate(null);
    setNotaryFromDate(null);
    setIsNotaryCustom(false);
    setNotarySelect("Day");
  };

  const handleCustomUserChart = async () => {
    try {
      setUserDisabled(true);
      setUserLoading(true);
      const values = { fromDate: userFromDate, toDate: userToDate };

      const resp = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/data/getCustomUserCharts`,
        { values, currentSession: currentSession },
        {
          withCredentials: true,
        }
      );

      if (resp.data.success === true) {
        setIsUserCustom(true);
        setCustomUserData(resp.data.data);

        setUserCategorySelect("Day");
        setCustomUserChartLabel(resp.data.data.labels);
        setCustomCurrentUserChartLabel(resp.data.data.labels.day);

        setUserDisabled(false);
        setUserLoading(false);
      }
    } catch (e) {
      clearUserDate();
      setUserDisabled(true);
      setUserLoading(false);
    }
  };

  const handleCustomFailedNocChart = async () => {
    try {
      setFailedNocDisabled(true);
      setFailedNocLoading(true);
      const values = { fromDate: failedNocFromDate, toDate: failedNocToDate };

      const resp = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/data/getCustomFailedNocCharts`,
        { values, currentSession: currentSession },
        {
          withCredentials: true,
        }
      );

      if (resp.data.success === true) {
        setIsFailedNocCustom(true);
        setCustomFailedNocData(resp.data.data);

        setFailedCategorySelect("Day");
        setCustomFailedNocChartLabel(resp.data.data.labels);
        setCustomCurrentFailedNocChartLabel(resp.data.data.labels.day);

        setFailedNocDisabled(false);
        setFailedNocLoading(false);
      }
    } catch (e) {
      clearFailedNocDate();
      setFailedNocDisabled(true);
      setFailedNocLoading(false);
    }
  };
  const handleCustomSuccessNocChart = async () => {
    try {
      setSuccessNocDisabled(true);
      setSuccessNocLoading(true);
      const values = { fromDate: successNocFromDate, toDate: successNocToDate };

      const resp = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/data/getCustomSuccessNocCharts`,
        { values, currentSession: currentSession },
        {
          withCredentials: true,
        }
      );

      if (resp.data.success === true) {
        setIsSuccessNocCustom(true);
        setCustomSuccessNocData(resp.data.data);

        setCategorySelect("Day");
        setCustomSuccessNocChartLabel(resp.data.data.labels);
        setCustomCurrentSuccessNocChartLabel(resp.data.data.labels.day);

        setSuccessNocDisabled(false);
        setSuccessNocLoading(false);
      }
    } catch (e) {
      clearSuccessNocDate();
      setSuccessNocDisabled(true);
      setSuccessNocLoading(false);
    }
  };

  const handleNotarizedChart = async () => {
    try {
      setNotaryDisabled(true);
      setNotaryLoading(true);
      const values = { fromDate: notaryFromDate, toDate: notaryToDate };

      const resp = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/data/getCustomNotaryCharts`,
        { values, currentSession: currentSession },
        {
          withCredentials: true,
        }
      );

      if (resp.data.success === true) {
        setIsNotaryCustom(true);
        setCustomNotaryData(resp.data.data);

        setNotarySelect("Day");
        setCustomNotaryChartLabel(resp.data.data.labels);
        setCustomCurrentNotaryChartLabel(resp.data.data.labels.day);

        setNotaryDisabled(false);
        setNotaryLoading(false);
      }
    } catch (e) {
      clearNotaryDate();
      setNotaryDisabled(true);
      setNotaryLoading(false);
    }
  };

  useEffect(() => {
    if (userFromDate !== null || userToDate !== null) {
      setUserDisabled(false);
    } else {
      setUserDisabled(true);
    }

    if (failedNocFromDate !== null || failedNocToDate !== null) {
      setFailedNocDisabled(false);
    } else {
      setFailedNocDisabled(true);
    }

    if (successNocFromDate !== null || successNocToDate !== null) {
      setSuccessNocDisabled(false);
    } else {
      setSuccessNocDisabled(true);
    }

    if (notaryFromDate !== null || notaryToDate !== null) {
      setNotaryDisabled(false);
    } else {
      setNotaryDisabled(true);
    }
  }, [
    userFromDate,
    userToDate,
    failedNocFromDate,
    failedNocToDate,
    successNocFromDate,
    successNocToDate,
    notaryFromDate,
    notaryToDate,
  ]);

  useEffect(() => {
    const getData = async () => {
      try {
        const resp = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/nocApi/data/analytics_admin`,
          {
            currentSession: currentSession,
          },
          {
            withCredentials: true,
          }
        );

        setAnalyticsData(resp.data.data);

        setSortData(resp.data.data.nocOverview.countiesCount);
        setSortLabels(resp.data.data.nocOverview.counties);

        setNotarySortData(resp.data.data.notaryOverView.notaries_count);
        setNotarySortLabels(resp.data.data.notaryOverView.counties);

        setChartLabels(resp.data.data.nocCategories.categoryLabels);
        setCurrentChartLabel(resp.data.data.nocCategories.categoryLabels.day);

        setNotaryChartLabels(resp.data.data.notaryCategories.categoryLabels);
        setNotaryCurrentChartLabel(
          resp.data.data.notaryCategories.categoryLabels.day
        );

        setFailedChartLabels(
          resp.data.data.failedNocCategories.failedCategoryLabels
        );
        setCurrentFailedChartLabel(
          resp.data.data.failedNocCategories.failedCategoryLabels.day
        );

        setUserChartLabels(resp.data.data.totalUsersCategory.categoryLabels);
        setCurrentUserChartLabel(
          resp.data.data.totalUsersCategory.categoryLabels.day
        );
        setDataLoading(false);
      } catch (e) {}
    };

    if (
      !currentUser ||
      currentUser?.user?.role !== "admin" ||
      !currentUser?.user?.admin
    ) {
      return;
    } else {
      if (dataCount < 1) {
        dataCount += 1;
        getData();
      }
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const resp = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/nocApi/data/notary-overview-data`,
          { currentSession: currentSession },
          {
            withCredentials: true,
          }
        );
        if (resp.data.success === true) {
          setNotaryData(resp.data.data);
        }
      } catch (error) {
        // Handle error appropriately
        console.error("Error fetching data:", error);
      }
    };

    if (
      !currentUser ||
      currentUser?.user?.role !== "admin" ||
      !currentUser?.user?.admin
    ) {
      return;
    } else {
      if (fetchCount < 1) {
        fetchCount += 1;
        fetchData();
      }
    }
  }, []);

  useEffect(() => {
    if (sortValue === "Default") {
      setSortLabels(analyticsData?.nocOverview.counties);
      setSortData(analyticsData?.nocOverview.countiesCount);
    } else if (sortValue === "Ascending") {
      setSortLabels(analyticsData?.nocOverview.asc_label);
      setSortData(analyticsData?.nocOverview.asc_count);
    } else if (sortValue === "Descending") {
      setSortLabels(analyticsData?.nocOverview.desc_label);
      setSortData(analyticsData?.nocOverview.desc_count);
    }
  }, [sortValue]);

  useEffect(() => {
    if (notaryCountySortValue === "Default") {
      setNotarySortLabels(analyticsData?.notaryOverView.counties);
      setNotarySortData(analyticsData?.notaryOverView.notaries_count);
    } else if (notaryCountySortValue === "Ascending") {
      setNotarySortLabels(analyticsData?.notaryOverView.asc_label);
      setNotarySortData(analyticsData?.notaryOverView.asc_count);
    } else if (notaryCountySortValue === "Descending") {
      setNotarySortLabels(analyticsData?.notaryOverView.desc_label);
      setNotarySortData(analyticsData?.notaryOverView.desc_count);
    }
  }, [notaryCountySortValue]);

  useEffect(() => {
    if (isNotaryCustom) {
      if (notarySelect === "Day") {
        setCustomCurrentNotaryChartLabel(customNotaryChartLabel.day);
      } else if (notarySelect === "Week") {
        setCustomCurrentNotaryChartLabel(customNotaryChartLabel.week);
      } else if (notarySelect === "Month") {
        setCustomCurrentNotaryChartLabel(customNotaryChartLabel.month);
      }
    } else {
      if (notarySelect === "Day") {
        setNotaryCurrentChartLabel(notaryChartLabels.day);
      } else if (notarySelect === "Week") {
        setNotaryCurrentChartLabel(notaryChartLabels.week);
      } else if (notarySelect === "Month") {
        setNotaryCurrentChartLabel(notaryChartLabels.month);
      }
    }

    if (isSuccessNocCustom) {
      if (categorySelect === "Day") {
        setCustomCurrentSuccessNocChartLabel(customSuccessNocChartLabel.day);
      } else if (categorySelect === "Week") {
        setCustomCurrentSuccessNocChartLabel(customSuccessNocChartLabel.week);
      } else if (categorySelect === "Month") {
        setCustomCurrentSuccessNocChartLabel(customSuccessNocChartLabel.month);
      }
    } else {
      if (categorySelect === "Day") {
        setCurrentChartLabel(chartLabels.day);
      } else if (categorySelect === "Week") {
        setCurrentChartLabel(chartLabels.week);
      } else if (categorySelect === "Month") {
        setCurrentChartLabel(chartLabels.month);
      }
    }

    if (isFailedNocCustom) {
      if (failedCategorySelect === "Day") {
        setCustomCurrentFailedNocChartLabel(customFailedNocChartLabel.day);
      } else if (failedCategorySelect === "Week") {
        setCustomCurrentFailedNocChartLabel(customFailedNocChartLabel.week);
      } else if (failedCategorySelect === "Month") {
        setCustomCurrentFailedNocChartLabel(customFailedNocChartLabel.month);
      }
    } else {
      if (failedCategorySelect === "Day") {
        setCurrentFailedChartLabel(failedChartLabels.day);
      } else if (failedCategorySelect === "Week") {
        setCurrentFailedChartLabel(failedChartLabels.week);
      } else if (failedCategorySelect === "Month") {
        setCurrentFailedChartLabel(failedChartLabels.month);
      }
    }

    if (isUserCustom) {
      if (userCategorySelect === "Day") {
        setCustomCurrentUserChartLabel(customUserChartLabel.day);
      } else if (userCategorySelect === "Week") {
        setCustomCurrentUserChartLabel(customUserChartLabel.week);
      } else if (userCategorySelect === "Month") {
        setCustomCurrentUserChartLabel(customUserChartLabel.month);
      }
    } else {
      if (userCategorySelect === "Day") {
        setCurrentUserChartLabel(userChartLabels.day);
      } else if (userCategorySelect === "Week") {
        setCurrentUserChartLabel(userChartLabels.week);
      } else if (userCategorySelect === "Month") {
        setCurrentUserChartLabel(userChartLabels.month);
      }
    }
  }, [
    categorySelect,
    failedCategorySelect,
    isFailedNocCustom,
    userCategorySelect,
    isUserCustom,
    isSuccessNocCustom,
    notarySelect,
    isNotaryCustom,
  ]);

  const handleChartValues = () => {
    let chartList = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    analyticsData?.nocOverview?.full_noc_list.map((item) => {
      if (item.year === year) {
        chartList[item.month] = item.noc;
      }
      return null;
    });

    return chartList;
  };

  const handleUserChartValues = () => {
    let chartList = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

    analyticsData?.usersStatistics?.full_users_list.map((item) => {
      if (String(item.year) === userYear) {
        chartList[item.month] = item.userCount;
      }
      return null;
    });

    return chartList;
  };

  const handleNotaryValues = () => {
    const chartListLength = 20; // Set the maximum length for chartList
    let chartList = Array(chartListLength).fill(0); // Initialize chartList with 0 values

    if (isNotaryCustom) {
      if (notarySelect === "Day") {
        customNotaryData?.daily_data?.forEach((item, index) => {
          const count = item.count;
          const chartIndex = index; // Calculate index from first to last
          if (chartIndex < chartListLength) {
            chartList[chartIndex] += count;
          }
        });
      } else if (notarySelect === "Week") {
        customNotaryData?.weekly_data?.forEach((item, index) => {
          const count = item.count;
          const chartIndex = index; // Calculate index from first to last
          if (chartIndex < chartListLength) {
            chartList[chartIndex] += count;
          }
        });
      } else if (notarySelect === "Month") {
        customNotaryData?.monthly_data?.forEach((item, index) => {
          const count = item.count;
          const chartIndex = index; // Calculate index from first to last
          if (chartIndex < chartListLength) {
            chartList[chartIndex] += count;
          }
        });
      }

      return chartList;
    } else {
      if (notarySelect === "Day") {
        analyticsData?.notaryCategories?.daily_data.forEach((item, index) => {
          const count = item.count;
          const chartIndex = index; // Calculate index from first to last
          if (chartIndex < chartListLength) {
            chartList[chartIndex] += count;
          }
        });
      } else if (notarySelect === "Week") {
        analyticsData?.notaryCategories?.weekly_data.forEach((item, index) => {
          const count = item.count;
          const chartIndex = index; // Calculate index from first to last
          if (chartIndex < chartListLength) {
            chartList[chartIndex] += count;
          }
        });
      } else if (notarySelect === "Month") {
        analyticsData?.notaryCategories?.monthly_data.forEach((item, index) => {
          const count = item.count;
          const chartIndex = index; // Calculate index from first to last
          if (chartIndex < chartListLength) {
            chartList[chartIndex] += count;
          }
        });
      }
      return chartList;
    }
  };

  const handleCategoryValues = () => {
    const chartListLength = 20; // Set the maximum length for chartList
    let chartList = Array(chartListLength).fill(0); // Initialize chartList with 0 values

    if (isSuccessNocCustom) {
      if (categorySelect === "Day") {
        customSuccessNocData.daily_data.forEach((item, index) => {
          const count = item.count;
          const chartIndex = index; // Calculate index from first to last
          if (chartIndex < chartListLength) {
            chartList[chartIndex] += count;
          }
        });
      } else if (categorySelect === "Week") {
        customSuccessNocData.weekly_data.forEach((item, index) => {
          const count = item.count;
          const chartIndex = index; // Calculate index from first to last
          if (chartIndex < chartListLength) {
            chartList[chartIndex] += count;
          }
        });
      } else if (categorySelect === "Month") {
        customSuccessNocData.monthly_data.forEach((item, index) => {
          const count = item.count;
          const chartIndex = index; // Calculate index from first to last
          if (chartIndex < chartListLength) {
            chartList[chartIndex] += count;
          }
        });
      }

      return chartList;
    } else {
      if (categorySelect === "Day") {
        analyticsData?.nocCategories?.daily_data.forEach((item, index) => {
          const count = item.count;
          const chartIndex = index; // Calculate index from first to last
          if (chartIndex < chartListLength) {
            chartList[chartIndex] += count;
          }
        });
      } else if (categorySelect === "Week") {
        analyticsData?.nocCategories?.weekly_data.forEach((item, index) => {
          const count = item.count;
          const chartIndex = index; // Calculate index from first to last
          if (chartIndex < chartListLength) {
            chartList[chartIndex] += count;
          }
        });
      } else if (categorySelect === "Month") {
        analyticsData?.nocCategories?.monthly_data.forEach((item, index) => {
          const count = item.count;
          const chartIndex = index; // Calculate index from first to last
          if (chartIndex < chartListLength) {
            chartList[chartIndex] += count;
          }
        });
      }

      return chartList;
    }
  };

  const handleFailedCategoryValues = () => {
    const chartListLength = 20; // Set the maximum length for chartList
    let chartList = Array(chartListLength).fill(0); // Initialize chartList with 0 values

    if (isFailedNocCustom) {
      if (failedCategorySelect === "Day") {
        customFailedNocData.daily_data.forEach((item, index) => {
          const count = item.count;
          const chartIndex = index; // Calculate index from first to last
          if (chartIndex < chartListLength) {
            chartList[chartIndex] += count;
          }
        });
      } else if (failedCategorySelect === "Week") {
        customFailedNocData.weekly_data.forEach((item, index) => {
          const count = item.count;
          const chartIndex = index; // Calculate index from first to last
          if (chartIndex < chartListLength) {
            chartList[chartIndex] += count;
          }
        });
      } else if (failedCategorySelect === "Month") {
        customFailedNocData.monthly_data.forEach((item, index) => {
          const count = item.count;
          const chartIndex = index; // Calculate index from first to last
          if (chartIndex < chartListLength) {
            chartList[chartIndex] += count;
          }
        });
      }

      return chartList;
    } else {
      if (failedCategorySelect === "Day") {
        analyticsData?.failedNocCategories?.failedDaily_data.forEach(
          (item, index) => {
            const count = item.count;
            const chartIndex = index; // Calculate index from first to last
            if (chartIndex < chartListLength) {
              chartList[chartIndex] += count;
            }
          }
        );
      } else if (failedCategorySelect === "Week") {
        analyticsData?.failedNocCategories?.failedWeekly_data.forEach(
          (item, index) => {
            const count = item.count;
            const chartIndex = index; // Calculate index from first to last
            if (chartIndex < chartListLength) {
              chartList[chartIndex] += count;
            }
          }
        );
      } else if (failedCategorySelect === "Month") {
        analyticsData?.failedNocCategories?.failedMonthly_data.forEach(
          (item, index) => {
            const count = item.count;
            const chartIndex = index; // Calculate index from first to last
            if (chartIndex < chartListLength) {
              chartList[chartIndex] += count;
            }
          }
        );
      }

      return chartList;
    }
  };

  const handleUserCategoryValues = () => {
    const chartListLength = 20; // Set the maximum length for chartList
    let chartList = Array(chartListLength).fill(0); // Initialize chartList with 0 values

    if (isUserCustom) {
      if (userCategorySelect === "Day") {
        customUserData.daily_data.forEach((item, index) => {
          const count = item.count;
          const chartIndex = index; // Calculate index from first to last
          if (chartIndex < chartListLength) {
            chartList[chartIndex] += count;
          }
        });
      } else if (userCategorySelect === "Week") {
        customUserData.weekly_data.forEach((item, index) => {
          const count = item.count;
          const chartIndex = index; // Calculate index from first to last
          if (chartIndex < chartListLength) {
            chartList[chartIndex] += count;
          }
        });
      } else if (userCategorySelect === "Month") {
        customUserData.monthly_data.forEach((item, index) => {
          const count = item.count;
          const chartIndex = index; // Calculate index from first to last
          if (chartIndex < chartListLength) {
            chartList[chartIndex] += count;
          }
        });
      }

      return chartList;
    } else {
      if (userCategorySelect === "Day") {
        analyticsData?.totalUsersCategory?.daily_data.forEach((item, index) => {
          const count = item.count;
          const chartIndex = index; // Calculate index from first to last
          if (chartIndex < chartListLength) {
            chartList[chartIndex] += count;
          }
        });
      } else if (userCategorySelect === "Week") {
        analyticsData?.totalUsersCategory?.weekly_data.forEach(
          (item, index) => {
            const count = item.count;
            const chartIndex = index; // Calculate index from first to last
            if (chartIndex < chartListLength) {
              chartList[chartIndex] += count;
            }
          }
        );
      } else if (userCategorySelect === "Month") {
        analyticsData?.totalUsersCategory?.monthly_data.forEach(
          (item, index) => {
            const count = item.count;
            const chartIndex = index; // Calculate index from first to last
            if (chartIndex < chartListLength) {
              chartList[chartIndex] += count;
            }
          }
        );
      }

      return chartList;
    }
  };

  return (
    <>
      {dataLoading ? (
        <>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              height: "100vh",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <CircularProgress color="warning" size="10rem" />
          </Box>
        </>
      ) : (
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            py: 1,
            backgroundColor: "#EEF2F6",
          }}
        >
          <Container maxWidth="xl">
            <Grid container spacing={5} sx={{ my: 2 }}>
              <Grid
                size={{ xs: 12, sm: 6, md: 4, lg: 2.3 }}
                sx={{ ml: "auto", mb: 2 }}
              >
                <Analytics
                  title="Total Users"
                  count={analyticsData?.totalUsers?.count}
                  percentage={analyticsData?.totalUsers?.percentage}
                  lastMonthCount={analyticsData?.avgNOC?.lastMonthCount}
                  extra={
                    analyticsData?.totalUsers?.count -
                    analyticsData?.totalUsers?.lastMonthCount
                  }
                  isMore={analyticsData?.totalUsers?.isMore}
                  texts={[
                    "You've gained",
                    "new users this month",
                    "You've a loss of",
                    "users this month",
                    "You've",
                    "new users this month",
                  ]}
                  color={
                    analyticsData?.totalUsers?.isMore === true
                      ? "success"
                      : analyticsData?.totalUsers?.isMore === false
                      ? "error"
                      : "default"
                  }
                />
              </Grid>
              <Grid size={{ xs: 12, sm: 6, md: 4, lg: 2.3 }}>
                <Analytics
                  title="Total Subusers"
                  count={analyticsData?.subusers?.count}
                  percentage={analyticsData?.subusers?.percentage}
                  lastMonthCount={analyticsData?.avgNOC?.lastMonthCount}
                  extra={
                    analyticsData?.subusers?.count -
                    analyticsData?.subusers?.lastMonthCount
                  }
                  isMore={analyticsData?.subusers?.isMore}
                  texts={[
                    "You have gained",
                    "new subusers this month",
                    "You've gained",
                    "You've a loss of",
                    "You've",
                    "new subusers this month",
                  ]}
                  color={
                    analyticsData?.subusers?.isMore === true
                      ? "success"
                      : analyticsData?.subusers?.isMore === false
                      ? "error"
                      : "default"
                  }
                />
              </Grid>
              <Grid size={{ xs: 12, sm: 6, md: 4, lg: 2.3 }}>
                <Analytics
                  title="Total NOC Generated"
                  count={analyticsData?.successNOC?.count}
                  lastMonthCount={analyticsData?.avgNOC?.lastMonthCount}
                  percentage={analyticsData?.successNOC?.percentage}
                  extra={
                    analyticsData?.successNOC?.count -
                    analyticsData?.successNOC?.lastMonthCount
                  }
                  isMore={analyticsData?.successNOC?.isMore}
                  texts={[
                    "Users Created",
                    "NOC more this month",
                    "Users Created",
                    "NOC less this month",
                    "There was",
                    "NOC created this month",
                  ]}
                  color={
                    analyticsData?.successNOC?.isMore === true
                      ? "success"
                      : analyticsData?.successNOC?.isMore === false
                      ? "error"
                      : "default"
                  }
                />
              </Grid>
              <Grid size={{ xs: 12, sm: 6, md: 4, lg: 2.3 }}>
                <Analytics
                  title="Total NOC Failed"
                  count={analyticsData?.failedNOC?.count}
                  percentage={analyticsData?.failedNOC?.percentage}
                  lastMonthCount={analyticsData?.avgNOC?.lastMonthCount}
                  extra={
                    analyticsData?.failedNOC?.count -
                    analyticsData?.failedNOC?.lastMonthCount
                  }
                  isMore={analyticsData?.failedNOC?.isMore}
                  texts={[
                    "Users Failed to Created",
                    "NOC more this month",
                    "Users Failed to Created",
                    "NOC less this month",
                    "There was",
                    "failed NOC this month",
                  ]}
                  color={
                    analyticsData?.failedNOC?.isMore === true
                      ? "success"
                      : analyticsData?.failedNOC?.isMore === false
                      ? "error"
                      : "default"
                  }
                />
              </Grid>

              <Grid
                size={{ xs: 12, sm: 6, md: 4, lg: 2.3 }}
                sx={{ mr: "auto" }}
              >
                <Analytics
                  title="Average NOC Creation Time"
                  count={analyticsData?.avgNOC?.count}
                  percentage={analyticsData?.avgNOC?.percentage}
                  lastMonthCount={analyticsData?.avgNOC?.lastMonthCount}
                  extra={
                    analyticsData?.avgNOC?.count -
                    analyticsData?.avgNOC?.lastMonthCount
                  }
                  isMore={analyticsData?.avgNOC?.isMore}
                  texts={[
                    "Last Month Avg NOC Creation time was shorter, ",
                    "seconds",
                    "Last Month Avg NOC Creation time was longer, ",
                    "seconds",
                    "There was",
                    "seconds different from last month",
                  ]}
                  color={
                    analyticsData?.avgNOC?.isMore === true
                      ? "success"
                      : analyticsData?.avgNOC?.isMore === false
                      ? "error"
                      : "default"
                  }
                />
              </Grid>

              <Grid size={{ xs: 12, sm: 12, lg: 3 }}>
                <NotaryOwerviewCard
                  title="Registered Notaries"
                  value={notaryData?.notary_user_count}
                  icon={Group}
                  color="primary.main"
                  tooltip="Requests awaiting your action"
                />
              </Grid>
              <Grid size={{ xs: 12, sm: 12, lg: 3 }}>
                <NotaryOwerviewCard
                  title="Open Appointment Slots"
                  value={notaryData?.open_slot_count}
                  icon={EventAvailable}
                  color="success.main"
                  tooltip="Jobs successfully completed"
                />
              </Grid>
              <Grid size={{ xs: 12, sm: 12, lg: 3 }}>
                <NotaryOwerviewCard
                  title="Confirmed Appointments"
                  value={notaryData?.confirmed_appointment_count}
                  icon={EventBusy}
                  color="warning.main"
                  tooltip="Your Pending Jobs"
                />
              </Grid>
              <Grid size={{ xs: 12, sm: 12, lg: 3 }}>
                <NotaryOwerviewCard
                  title="Pending Notary Assignments"
                  value={notaryData?.pending_assignment_count}
                  icon={AssignmentLate}
                  color="error.main"
                  tooltip="Time for your next notarization"
                />
              </Grid>

              <Grid size={{ xs: 12, lg: 6 }}>
                <AdminNocUsage
                  year={year}
                  setYear={setYear}
                  handleChartValues={handleChartValues}
                  chartSeries={[
                    {
                      name: "Number of NOCs Generated",
                      data: handleChartValues(),
                    },
                  ]}
                  sx={{ height: "100%" }}
                />
              </Grid>
              <Grid size={{ xs: 12, lg: 6 }}>
                <UserOverview
                  userYear={userYear}
                  setUserYear={setUserYear}
                  handleUserChartValues={handleUserChartValues}
                  chartSeries={[
                    {
                      name: "Users",
                      data: handleUserChartValues(),
                    },
                  ]}
                  sx={{ height: "100%" }}
                />
              </Grid>
              <Grid size={{ xs: 12, md: 8 }}>
                {analyticsData ? (
                  <NocByCounties
                    sortValue={sortValue}
                    setSortValue={setSortValue}
                    chartSeries={sortData}
                    labels={sortLabels}
                    sx={{ height: "100%" }}
                  />
                ) : (
                  <div></div>
                )}
              </Grid>
              <Grid size={{ xs: 12, md: 4 }}>
                {analyticsData ? (
                  <NocSuccessFail
                    chartSeries={[
                      parseFloat(
                        analyticsData?.successNocVsFailed.failed_noc_percent
                      ),
                      parseFloat(
                        analyticsData?.successNocVsFailed.success_noc_percent
                      ),
                    ]}
                    labels={["Failure", "Success"]}
                    sx={{ height: "100%" }}
                  />
                ) : (
                  <div></div>
                )}
              </Grid>
              <Grid size={{ xs: 12 }}>
                {analyticsData ? (
                  <TotalNotarizationByCounty
                    notaryCountySortValue={notaryCountySortValue}
                    setNotaryCountySortValue={setNotaryCountySortValue}
                    chartSeries={notarySortData}
                    labels={notarySortLabels}
                    sx={{ height: "100%" }}
                  />
                ) : (
                  <div></div>
                )}
              </Grid>
              <Grid size={{ xs: 12, lg: 12 }}>
                <NotarizationsByCategory
                  handleNotarizedChart={handleNotarizedChart}
                  clearNotaryDate={clearNotaryDate}
                  notaryFromDate={notaryFromDate}
                  notaryToDate={notaryToDate}
                  notaryLoading={notaryLoading}
                  notaryDisabled={notaryDisabled}
                  setNotaryFromDate={setNotaryFromDate}
                  setNotaryToDate={setNotaryToDate}
                  labels={
                    isNotaryCustom
                      ? customCurrentNotaryChartLabel
                      : notaryCurrentChartLabel
                  } // Pass the labels as a prop
                  setNotarySelect={setNotarySelect}
                  notarySelect={notarySelect}
                  handleNotaryValues={handleNotaryValues}
                  chartSeries={[
                    {
                      name: "Number of NOC Notarized",
                      data: handleNotaryValues(),
                    },
                  ]}
                  sx={{ height: "100%" }}
                />
              </Grid>
              <Grid size={{ xs: 12, lg: 12 }}>
                <TasksByCategory
                  handleCustomSuccessNocChart={handleCustomSuccessNocChart}
                  clearSuccessNocDate={clearSuccessNocDate}
                  successNocFromDate={successNocFromDate}
                  successNocToDate={successNocToDate}
                  successNocLoading={successNocLoading}
                  successNocDisabled={successNocDisabled}
                  setSuccessNocFromDate={setSuccessNocFromDate}
                  setSuccessNocToDate={setSuccessNocToDate}
                  labels={
                    isSuccessNocCustom
                      ? customCurrentSuccessNocChartLabel
                      : currentChartLabel
                  } // Pass the labels as a prop
                  setCategorySelect={setCategorySelect}
                  categorySelect={categorySelect}
                  handleCategoryValues={handleCategoryValues}
                  chartSeries={[
                    {
                      name: "Number of NOCs Generated",
                      data: handleCategoryValues(),
                    },
                  ]}
                  sx={{ height: "100%" }}
                />
              </Grid>
              <Grid size={{ xs: 12, lg: 12 }}>
                <FailedNOC
                  handleCustomFailedNocChart={handleCustomFailedNocChart}
                  clearFailedNocDate={clearFailedNocDate}
                  failedNocFromDate={failedNocFromDate}
                  failedNocToDate={failedNocToDate}
                  failedNocLoading={failedNocLoading}
                  failedNocDisabled={failedNocDisabled}
                  setFailedNocFromDate={setFailedNocFromDate}
                  setFailedNocToDate={setFailedNocToDate}
                  labels={
                    isFailedNocCustom
                      ? customCurrentFailedNocChartLabel
                      : currentFailedChartLabel
                  } // Pass the labels as a prop
                  setCategorySelect={setFailedCategorySelect}
                  categorySelect={failedCategorySelect}
                  handleCategoryValues={handleFailedCategoryValues}
                  chartSeries={[
                    {
                      name: "Number of Failed NOC",
                      data: handleFailedCategoryValues(),
                    },
                  ]}
                  sx={{ height: "100%" }}
                />
              </Grid>
              <Grid size={{ xs: 12, lg: 12 }}>
                <TotalUsersByCategory
                  handleCustomUserChart={handleCustomUserChart}
                  clearUserDate={clearUserDate}
                  userFromDate={userFromDate}
                  userToDate={userToDate}
                  userLoading={userLoading}
                  userDisabled={userDisabled}
                  setUserFromDate={setUserFromDate}
                  setUserToDate={setUserToDate}
                  labels={
                    isUserCustom
                      ? customCurrentUserChartLabel
                      : currentUserChartLabel
                  } // Pass the labels as a prop
                  setCategorySelect={setUserCategorySelect}
                  categorySelect={userCategorySelect}
                  handleCategoryValues={handleUserCategoryValues}
                  chartSeries={[
                    {
                      name: "Number of Users",
                      data: handleUserCategoryValues(),
                    },
                  ]}
                  sx={{ height: "100%" }}
                />
              </Grid>
            </Grid>
          </Container>
        </Box>
      )}
    </>
  );
};

export default Overview;
