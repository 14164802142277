import { useLocation, useNavigate } from "react-router-dom";
import { Box, Button, Modal, Typography, useMediaQuery } from "@mui/material";
import { useTour } from "@reactour/tour";
import { tourSteps, subUserTourSteps } from "./steps";
import { useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { userFirstLoginOff } from "../../store/actions/userActions";
import { subUserFirstLoginOff } from "../../store/actions/subUserActions";
import { LoadingButton } from "@mui/lab";

const DemoMainPage = (props) => {
  const currentSession = useSelector((state) => state.sessions.currentSession);

  const dispatch = useDispatch();
  const location = useLocation(); // Get the current location (path)
  const navigate = useNavigate();
  const { setIsOpen } = useTour();
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"));
  const [loading, setLoading] = useState(false);

  const handleClose = async () => {
    try {
      setLoading(true);
      await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/data/first-login-off`,
        {
          currentSession: currentSession,
        },
        { withCredentials: true }
      );

      if (currentSession === "userSession") {
        await dispatch(userFirstLoginOff());
      } else if (currentSession === "subUserSession") {
        await dispatch(subUserFirstLoginOff());
      }
      navigate("/dashboard");
    } catch (e) {
      setLoading(false);
    }
  };

  return (
    <Modal
      open={props?.open}
      //   onClose={handleClose}
      sx={{ overflow: "auto" }}
      aria-labelledby="welcome-modal-title"
      aria-describedby="welcome-modal-description"
    >
      <Box
        sx={{
          textAlign: "center",
          overflowY: "auto",
          position: "relative",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          maxHeight: "90%",
          width: 600,
          bgcolor: "background.paper",
          //   border: "2px solid #000",
          borderRadius: "18px",
          boxShadow: 24,
          px: 4,
          py: 2,
        }}
      >
        <img
          alt="Permit Rockstar Logo"
          src="/img/website_img/PermitRockstar.png"
          style={{ maxWidth: "10rem", height: "auto" }}
        />
        <Typography variant="h4" mt={2} color="primary">
          Welcome aboard, Let's get started
        </Typography>
        <Box mb={5}>
          <Typography
            id="welcome-modal-description"
            sx={{ mt: 2, fontWeight: "bold" }}
          >
            We are excited to have you on board!
          </Typography>
          <Typography
            id="welcome-modal-description"
            sx={{ fontWeight: "bold" }}
          >
            We will walk you through the key features to help you get started.
          </Typography>
        </Box>
        <Box mb={5}>
          <Typography
            id="welcome-modal-description"
            sx={{ fontWeight: "bold" }}
          >
            Hints:
          </Typography>
          <Typography id="welcome-modal-description" sx={{}} color="primary">
            The guided demo consists of{" "}
            {currentSession === "userSession"
              ? tourSteps?.length - 1
              : subUserTourSteps?.length - 1}{" "}
            steps. Follow the instructions to navigate through the application,
            and a Next button will appear after each completed step. You can
            exit anytime by clicking the "X" at the top and restart the demo as
            needed.
          </Typography>

          <Typography
            variant="body1"
            align="center"
            color="error"
            fontWeight="bold"
            mt={1}
          >
            {!lgUp
              ? "Our guided tour is best experienced on larger screens. For the optimal experience, please switch to a device with a larger display to continue."
              : "The demo is optimized for your screen's original resolution. If scaling has been changed or the demo buttons seem misaligned, adjust your screen by pressing CTRL and - or CTRL and + to zoom in or out for better visibility."}
          </Typography>
        </Box>
        <span style={{ fontWeight: "bold" }}></span>
        <Box sx={{ mb: 1 }}>
          <Button
            disabled={loading}
            sx={{ mr: 2 }}
            onClick={() => props?.setOpen(false)} // Close the tour if user doesn't want to continue
          >
            Skip
          </Button>
          <Button
            disabled={!lgUp || loading}
            variant="contained"
            onClick={() => {
              if (location.pathname !== "/dashboard") {
                navigate("/dashboard"); // Navigate to "/dashboard" if not already there
              }
              if (!props?.openNav) {
                props?.setOpenNav(true);
              }
              props?.setOpen(false);
              setTimeout(() => {
                setIsOpen(true);
              }, 200);
            }}
          >
            Start Tour
          </Button>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <LoadingButton
            size="small"
            loading={loading}
            color="error"
            onClick={handleClose}
          >
            Don't show again
          </LoadingButton>
        </Box>
      </Box>
    </Modal>
  );
};

export default DemoMainPage;
