import { SvgIcon } from "@mui/material";
import BarChartIcon from "@mui/icons-material/BarChart";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import PublicIcon from "@mui/icons-material/Public";
import DriveFileRenameOutlineOutlinedIcon from "@mui/icons-material/DriveFileRenameOutlineOutlined";
import PersonSearchOutlinedIcon from "@mui/icons-material/PersonSearchOutlined";
import PaymentIcon from "@mui/icons-material/Payment";
import GroupIcon from "@mui/icons-material/Group";
import PeopleIcon from "@mui/icons-material/People";
import EmailIcon from "@mui/icons-material/Email";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import ThreePOutlinedIcon from "@mui/icons-material/ThreePOutlined";
import ChartBarIcon from "@heroicons/react/24/solid/ChartBarIcon";
import BusinessIcon from "@mui/icons-material/Business";
import { AssignmentTurnedIn } from "@mui/icons-material";
import SubscriptionsIcon from "@mui/icons-material/Subscriptions";
import DescriptionIcon from "@mui/icons-material/Description";
import BadgeIcon from "@mui/icons-material/Badge";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";
import BuildingOffice2Icon from "@heroicons/react/24/solid/BuildingOffice2Icon";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import SettingsIcon from "@mui/icons-material/Settings";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import EngineeringIcon from "@mui/icons-material/Engineering";

export const notaryItems = [
  {
    title: "Dashboard",
    path: "/notary/dashboard",
    icon: (
      <SvgIcon fontSize="small">
        <BarChartIcon />
      </SvgIcon>
    ),
  },
  {
    title: "Job Management",
    path: "/notary/history",
    icon: (
      <SvgIcon fontSize="small">
        <WorkOutlineIcon />
      </SvgIcon>
    ),
  },
  // {
  //   title: "Customer Service",
  //   path: "/notary/contact",
  //   icon: (
  //     <SvgIcon fontSize="small">
  //       <ThreePOutlinedIcon />
  //     </SvgIcon>
  //   ),
  // },
  {
    title: "Profile Settings",
    path: "/notary/settings",
    icon: (
      <SvgIcon fontSize="small">
        <SettingsIcon />
      </SvgIcon>
    ),
  },
];

export const items = [
  {
    title: "Dashboard",
    path: "/dashboard",
    icon: (
      <SvgIcon fontSize="small">
        <BarChartIcon />
      </SvgIcon>
    ),
  },

  {
    title: "Select Counties",
    path: "/selectcounties",
    icon: (
      <SvgIcon fontSize="small">
        <PublicIcon />
      </SvgIcon>
    ),
  },
  {
    title: "Permits Hub",
    path: "",
    icon: (
      <SvgIcon fontSize="small">
        <FactCheckIcon />
      </SvgIcon>
    ),
    // /createnoc
    children: [
      {
        title: "Create Permit Package",
        path: "/permits/create",
        icon: (
          <SvgIcon fontSize="small">
            <PictureAsPdfIcon />
          </SvgIcon>
        ),
      },
      {
        title: "My Permit Packages",
        path: "/permits/my-permits",
        icon: (
          <SvgIcon fontSize="small">
            <InsertDriveFileIcon />
          </SvgIcon>
        ),
      },
      {
        title: "Notarization Requests",
        path: "/permits/invitations",
        icon: (
          <SvgIcon fontSize="small">
            <InsertDriveFileIcon />
          </SvgIcon>
        ),
      },
    ],
  },
  {
    title: "Preset Configurations",
    path: "/defaults",
    icon: (
      <SvgIcon fontSize="small">
        <DriveFileRenameOutlineOutlinedIcon />
      </SvgIcon>
    ),
  },
  // {
  //   title: "Document Uploader",
  //   path: "/document-uploader",
  //   icon: (
  //     <SvgIcon fontSize="small">
  //       <CloudUploadIcon />
  //     </SvgIcon>
  //   ),
  // },
  // {
  //   title: "Document Test Uploader",
  //   path: "/document-uploader-test",
  //   icon: (
  //     <SvgIcon fontSize="small">
  //       <CloudUploadIcon />
  //     </SvgIcon>
  //   ),
  // },
  {
    title: "Payment",
    path: "/payment",
    icon: (
      <SvgIcon fontSize="small">
        <PaymentIcon />
      </SvgIcon>
    ),
  },
  {
    title: "Users",
    path: "/subusers",
    icon: (
      <SvgIcon fontSize="small">
        <GroupIcon />
      </SvgIcon>
    ),
  },
  {
    title: "Manage Subscription",
    path: "/subscription",
    icon: (
      <SvgIcon fontSize="small">
        <SubscriptionsIcon />
      </SvgIcon>
    ),
  },

  {
    title: "Profile Settings",
    path: "/profilesettings",
    icon: (
      <SvgIcon fontSize="small">
        <PersonSearchOutlinedIcon />
      </SvgIcon>
    ),
  },
  {
    title: "Licenses",
    path: "/contractors",
    icon: (
      <SvgIcon fontSize="small">
        <EngineeringIcon />
      </SvgIcon>
    ),
  },
  // {
  //   title: "Customer Service",
  //   path: "/contact",
  //   icon: (
  //     <SvgIcon fontSize="small">
  //       <ThreePOutlinedIcon />
  //     </SvgIcon>
  //   ),
  // },
];

export const adminItems = [
  {
    title: "Overview",
    path: "/admin/overview",
    icon: (
      <SvgIcon fontSize="small">
        <ChartBarIcon />
      </SvgIcon>
    ),
  },
  {
    title: "Customers",
    path: "/admin/customers",
    icon: (
      <SvgIcon fontSize="small">
        <PeopleIcon />
      </SvgIcon>
    ),
  },
  {
    title: "Notarization Management",
    path: "/admin/notaries",
    icon: (
      <SvgIcon fontSize="small">
        <BadgeIcon />
      </SvgIcon>
    ),
  },
  {
    title: "NOC Management",
    path: "/admin/tasks",
    icon: (
      <SvgIcon fontSize="small">
        <AssignmentTurnedIn />
      </SvgIcon>
    ),
  },
  {
    title: "DBPR Licenses",
    path: "/admin/licenses",
    icon: (
      <SvgIcon fontSize="small">
        <DescriptionIcon />
      </SvgIcon>
    ),
  },
  {
    title: "Configurations",
    path: "/admin/management",
    icon: (
      <SvgIcon fontSize="small">
        <BusinessIcon />
      </SvgIcon>
    ),
  },
  {
    title: "New Messages",
    path: "/admin/messages",
    icon: (
      <SvgIcon fontSize="small">
        <EmailIcon />
      </SvgIcon>
    ),
  },
];
