import React, { useEffect, useState } from "react";
import { DndProvider, useDrag } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { pdfjs, Document, Page } from "react-pdf";
import { PDFDocument, PDFName } from "pdf-lib";
import { Box, Button, Pagination, Slider, Typography } from "@mui/material";
import UploadBox from "../fileUploader/UploadBox";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

// Drag-and-Drop Constants
const ItemTypes = { FIELD: "field" };

const TestUploader = () => {
  const [selectedTool, setSelectedTool] = useState("");
  const [uploadError, setUploadError] = useState("");
  const [currentDocument, setCurrentDocument] = useState({
    base64File: null,
    fileName: "",
    files: [],
    numPages: 0,
    pageNumber: 1,
    pdfSize: 1000,
    scaleFactor: 1,
  });
  const [formFields, setFormFields] = useState([]);

  // Function to extract form fields
  const extractFormFields = async (base64PDF) => {
    const pdfDoc = await PDFDocument.load(base64ToUint8Array(base64PDF));
    const form = pdfDoc.getForm();
    const fields = form.getFields();

    // Map page references to indices
    const pageRefsToIndices = new Map();
    pdfDoc.getPages().forEach((page, index) => {
      const pageRef = page.ref;
      pageRefsToIndices.set(
        `${pageRef.objectNumber} ${pageRef.generationNumber}`,
        index + 1 // 1-based index
      );
    });

    const extractedFields = fields.map((field) => {
      const acroField = field.acroField;

      // Field metadata
      const name = field.getName() || ""; // Ensure name is defined
      const type = field.constructor.name;

      // Determine value
      let value = "";
      if (type === "PDFTextField") {
        value = field.getText() || ""; // Get text value
      } else if (type === "PDFCheckBox") {
        const rawValue = acroField.dict.get(PDFName.of("V")); // Get the checkbox value
        value = rawValue?.encodedName === "/Yes"; // Interpret "/Yes" as true
      }

      // Extract rect and page
      let rect = null;
      let pageIndex = 0;

      if (acroField?.dict) {
        const rectEntry = acroField.dict.get(PDFName.of("Rect"));
        const pageRef = acroField.dict.get(PDFName.of("P"));

        if (rectEntry?.array) {
          const [x1, y1, x2, y2] = rectEntry.array.map(
            (num) => num.numberValue || 0
          );
          rect = {
            x: Math.min(x1, x2),
            y: Math.min(y1, y2),
            width: Math.abs(x2 - x1),
            height: Math.abs(y2 - y1),
          };
        }

        if (pageRef) {
          const pageKey = `${pageRef.objectNumber} ${pageRef.generationNumber}`;
          pageIndex = pageRefsToIndices.get(pageKey) || 0;
        }
      }

      // Fallbacks for missing `rect` or `page`
      if (!rect) {
        console.warn(`Field "${name}" is missing a valid rect.`);
      }
      if (pageIndex === 0 && pdfDoc.getPages().length === 1) {
        pageIndex = 1; // Default to page 1 for single-page documents
      }

      return {
        name,
        type,
        value,
        x: rect?.x || 0,
        y: rect?.y || 0,
        width: rect?.width || 0,
        height: rect?.height || 0,
        page: pageIndex,
      };
    });

    setFormFields(extractedFields);
  };

  // Utility function to convert base64 to Uint8Array
  const base64ToUint8Array = (base64) => {
    const binaryString = atob(base64.split(",")[1]);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes;
  };

  const onDocumentLoadSuccess = (pdf) => {
    const { numPages } = pdf;
    setCurrentDocument((prev) => ({
      ...prev,
      numPages,
    }));

    // Fetch the dimensions of the first page
    pdf.getPage(1).then((page) => {
      const viewport = page.getViewport({ scale: 1 }); // Scale of 1 gives actual dimensions
      setCurrentDocument((prev) => ({
        ...prev,
        actualWidth: viewport.width,
        actualHeight: viewport.height,
      }));
    });
  };

  useEffect(() => {
    if (currentDocument.base64File) {
      extractFormFields(currentDocument.base64File);
    }
  }, [currentDocument.base64File]);

  return (
    <DndProvider backend={HTML5Backend}>
      <Box
        sx={{
          height: "calc(100vh - 64px)", // Full height
          display: "flex",
          overflow: "auto", // Prevent global scrolling
          backgroundColor: "#e4e4e4",
        }}
      >
        {/* Left Toolbar */}
        <Box
          sx={{
            width: "20%",
            maxWidth: "20rem",
            minWidth: "15rem",
            px: "1rem",
            pt: "1rem",
            background: "#f4f4f4",
            display: "flex",
            flexDirection: "column", // Ensure child elements are stacked vertically
            position: "sticky", // Make the sidebar sticky
            top: 0, // Stick it to the top of the viewport
            height: "calc(100vh - 84px)", // Full height minus header/footer
            overflowY: "auto", // Scrollable content for overflow
            overflowX: "hidden", // Hide horizontal overflow
          }}
        >
          {/* Toolbar Header */}
          <Box>
            <Typography
              color="primary"
              align="center"
              variant={"h5"}
              mb={1}
              sx={{ zIndex: 200 }}
            >
              Document Uploader
            </Typography>
            <Box sx={{ borderBottom: "3px solid #d3d3d3" }}></Box>

            {/* Toolbox */}
            <Box sx={{ height: "calc(100vh - 350px)", overflowY: "auto" }}>
              <h3>Drag & Drop Toolbox</h3>
              <Box sx={{ pl: 2 }}>
                <Toolbox setSelectedTool={setSelectedTool} />
              </Box>
            </Box>
          </Box>

          {!currentDocument.base64File && (
            <Box
              sx={{
                position: "absolute",
                bottom: 0,
                left: 0,
                width: "100%",
                height: "94%",
                backgroundColor: "rgba(0, 0, 0, 0.1)", // Slightly transparent overlay
                zIndex: 2, // Ensure overlay is on top
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            ></Box>
          )}

          {/* Bottom Content */}
          <Box sx={{ mt: "auto", py: 0.5, mb: 1 }}>
            <Box sx={{ borderBottom: "3px solid #d3d3d3", mt: 3 }}></Box>
            {/* PDF Scaling Slider */}
            <Box sx={{ mt: 1 }}>
              <h3 style={{ marginBottom: 0 }}>Scale PDF</h3>
              <Slider
                value={currentDocument.pdfSize}
                min={500}
                max={1500}
                step={10} // Adjust step as needed
                valueLabelDisplay="auto" // Display the current value as a tooltip
                onChange={(e, newValue) =>
                  setCurrentDocument((prev) => ({
                    ...prev,
                    pdfSize: newValue,
                  }))
                }
                sx={{
                  color: "primary.main", // Customize the slider color (optional)
                }}
              />
            </Box>

            <Box sx={{ borderBottom: "3px solid #d3d3d3", mt: 2 }}></Box>
            {/* Pagination */}
            <h3>Navigate Pages</h3>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Pagination
                size="small"
                variant="outlined"
                shape="rounded"
                count={currentDocument?.numPages} // Number of pages
                page={currentDocument?.pageNumber} // Current page
                color="primary"
                onChange={(e, page) => {
                  setCurrentDocument((prev) => ({ ...prev, pageNumber: page }));
                }}
              />
            </Box>
          </Box>
        </Box>

        {/* Main Content Area */}
        <Box
          sx={{
            my: "auto",
            flex: 1, // Take remaining space
            display: "flex",
            flexDirection: "column", // Arrange content vertically
            overflowY: "auto", // Allow scrolling for the main content
          }}
        >
          {currentDocument.base64File ? (
            <>
              {/* Action Buttons */}
              <Box
                sx={{
                  mt: 1,
                  mr: 3,
                  display: "flex",
                  justifyContent: "flex-end",
                  right: 0,
                  position: "fixed",
                  zIndex: 100,
                }}
              >
                <Button
                  variant="outlined"
                  color="error"
                  onClick={() => {
                    if (
                      window.confirm(
                        "Are you sure you want to discard this PDF?"
                      )
                    ) {
                      setCurrentDocument({
                        base64File: null,
                        fileName: "",
                        files: [],
                        numPages: 0,
                        pageNumber: 1,
                        pdfSize: 1000,
                      });
                    }
                  }}
                >
                  Discard PDF
                </Button>
                <Button variant="contained" color="success" sx={{ ml: 2 }}>
                  Finalize PDF
                </Button>
              </Box>

              {/* PDF Viewer */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  background: "#e4e4e4",
                }}
              >
                <Box
                  sx={{
                    position: "relative", // Ensure fields are placed relative to this container
                    display: "inline-block", // Align tightly to the PDF content
                    my: 2,
                    width: `${currentDocument.pdfSize}px`, // Match PDF width dynamically
                    maxHeight: "100%", // Ensure height scales
                    border: "2px solid #d3d3d3",
                    borderRadius: "18px",
                    boxShadow: "3px 4px 12px rgba(0, 0, 0, 0.3)",
                    background: "#fff",
                  }}
                >
                  <Document
                    file={currentDocument.base64File} // Pass Data URL directly
                    onLoadSuccess={onDocumentLoadSuccess}
                    onLoadError={(error) =>
                      console.error("Error rendering PDF:", error)
                    }
                  >
                    <Page
                      pageNumber={currentDocument?.pageNumber}
                      renderAnnotationLayer={true}
                      renderTextLayer={true}
                      width={currentDocument?.pdfSize}
                    />
                  </Document>

                  {/* Overlay Form Fields */}
                  {formFields
                    .filter(
                      (field) => field.page === currentDocument?.pageNumber
                    ) // Only fields for the current page
                    .map((field, index) => {
                      const scaleFactor =
                        currentDocument.pdfSize / currentDocument.actualWidth;
                      if (field.type === "PDFCheckBox") {
                        return (
                          <input
                            key={index}
                            type="checkbox"
                            checked={field.value} // True for checked, false for unchecked
                            onChange={(e) => {
                              const isChecked = e.target.checked;
                              setFormFields((prevFields) =>
                                prevFields.map((f) =>
                                  f.name === field.name
                                    ? { ...f, value: isChecked } // Update checkbox value
                                    : f
                                )
                              );
                            }}
                            style={{
                              position: "absolute",
                              left: `${field.x * scaleFactor}px`,
                              top: `${
                                (currentDocument.actualHeight -
                                  field.y -
                                  field.height) *
                                scaleFactor
                              }px`,
                              width: `${field.width * scaleFactor}px`,
                              height: `${field.height * scaleFactor}px`,
                              zIndex: 10,
                            }}
                          />
                        );
                      }

                      // For text fields or other field types
                      return (
                        <input
                          key={index}
                          type="text"
                          value={field.value || ""}
                          onChange={(e) => {
                            const newValue = e.target.value;
                            setFormFields((prevFields) =>
                              prevFields.map((f) =>
                                f.name === field.name
                                  ? { ...f, value: newValue } // Update text field value
                                  : f
                              )
                            );
                          }}
                          style={{
                            position: "absolute",
                            left: `${field.x * scaleFactor}px`,
                            top: `${
                              (currentDocument.actualHeight -
                                field.y -
                                field.height) *
                              scaleFactor
                            }px`,
                            width: `${field.width * scaleFactor}px`,
                            height: `${field.height * scaleFactor}px`,
                            border: "1px solid #ccc",
                            background: "rgba(255, 255, 255, 0.9)",
                            zIndex: 10,
                          }}
                        />
                      );
                    })}
                </Box>
              </Box>
            </>
          ) : (
            <Box
              sx={{
                flex: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "2rem",
                maxHeight: "10rem",
                my: "auto",
              }}
            >
              <UploadBox
                uploadError={uploadError}
                setUploadError={setUploadError}
                setCurrentDocument={setCurrentDocument}
              />
            </Box>
          )}
        </Box>
      </Box>
    </DndProvider>
  );
};

// Toolbox Component
const Toolbox = ({ setSelectedTool }) => {
  const [, dragText] = useDrag(() => ({
    type: ItemTypes.FIELD,
    item: { tool: "text" },
    end: (item) => setSelectedTool(item.tool),
  }));

  const [, dragSignature] = useDrag(() => ({
    type: ItemTypes.FIELD,
    item: { tool: "signature" },
    end: (item) => setSelectedTool(item.tool),
  }));

  return (
    <>
      <div>
        <h3>General</h3>
        <div ref={dragText} style={{ margin: "1rem", cursor: "move" }}>
          <h4>📝 Text Field</h4>
        </div>
        <div ref={dragSignature} style={{ margin: "1rem", cursor: "move" }}>
          <h4>☑️ Checkbox</h4>
        </div>
        <div ref={dragSignature} style={{ margin: "1rem", cursor: "move" }}>
          <h4>🔘 Radio</h4>
        </div>
        <div ref={dragSignature} style={{ margin: "1rem", cursor: "move" }}>
          <h4>📅 Date</h4>
        </div>
      </div>

      <div>
        <h3>Notary</h3>
        <div ref={dragText} style={{ margin: "1rem", cursor: "move" }}>
          <h4>🖋️ Notary Seal</h4>
        </div>
        <div ref={dragSignature} style={{ margin: "1rem", cursor: "move" }}>
          <h4>✍️ Notary Signature</h4>
        </div>
      </div>

      <div>
        <h3>Signatures</h3>
        <div ref={dragText} style={{ margin: "1rem", cursor: "move" }}>
          <h4>✍️ Signature A</h4>
        </div>
        <div ref={dragSignature} style={{ margin: "1rem", cursor: "move" }}>
          <h4>✍️ Signature B</h4>
        </div>
        <div ref={dragSignature} style={{ margin: "1rem", cursor: "move" }}>
          <h4>✍️ Signature C</h4>
        </div>
      </div>
    </>
  );
};

export default TestUploader;
