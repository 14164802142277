import axios from "axios";
import { invalidateSession } from "./sessionsActions";

////! UPDATE ENTIRE QUEUE JOBS
export const updateNotaryJobs = (userData) => {
  return {
    type: "UPDATE_NOTARY_JOBS",
    payload: userData,
  };
};
////! BROADCASTS INSERT ONE OJECT JOB INTO QUEUE
export const addJobToQueue = (queueData) => {
  return {
    type: "ADD_JOB_TO_QUEUE",
    payload: queueData,
  };
};
////! BROADCASTS REMOVE ONE OJECT JOB FROM QUEUE
export const removeJobFromQueue = (queueData) => {
  return {
    type: "REMOVE_JOB_FROM_QUEUE",
    payload: queueData,
  };
};
////! ADD ACCEPTED QUEUE JOB FROM QUEUE - PERSONAL
export const addAcceptedQueueJob = (queueData) => {
  return {
    type: "ADD_ACCEPTED_QUEUE_JOB",
    payload: queueData,
  };
};
////! REMOVE ACCEPTED QUEUE JOB - PERSONAL
export const removeAcceptedQueueJob = (queueData) => {
  return {
    type: "REMOVE_ACCEPTED_QUEUE_JOB",
    payload: queueData,
  };
};

export const addNotaryUser = (data) => {
  return {
    type: "ADD_NOTARY_USER_DATA",
    payload: data,
  };
};

export const updateNotaryUser = (data) => {
  return {
    type: "UPDATE_NOTARY_USER_DATA",
    payload: data,
  };
};

export const addRealTimeRequest = (data) => {
  return {
    type: "ADD_REAL_TIME_REQUEST",
    payload: data,
  };
};

export const dismissRonRequest = (id) => {
  return {
    type: "DISMISS_RON_REQUEST",
    payload: id,
  };
};

export const dismissAllRonRequest = () => {
  return {
    type: "DISMISS_ALL_RON_REQUEST",
  };
};

export const fetchNotaryData = (sessionName) => async (dispatch, getState) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/nocApi/auth/get-notary-user`,
      { sessionName: sessionName },
      { withCredentials: true }
    );

    // Dispatch the action to update user data

    dispatch(addNotaryUser(res.data));
    return res.data;
  } catch (error) {
    const { sessions } = getState();
    dispatch(invalidateSession(sessions.currentSession));
    return;
  }
};
