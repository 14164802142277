import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Dialog,
  useMediaQuery,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  ListItemText,
  Checkbox,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { LoadingButton } from "@mui/lab";

const RemoveExtraCounty = ({
  open,
  setOpen,
  handleRemoveCounty,
  removeCountyLoading,
  removeCountyValue,
  setRemoveCountyValue,
  removeCounties,
  setRemoveCounties,
}) => {
  const currentSession = useSelector((state) => state.sessions.currentSession);
  const currentUser = useSelector((state) => state[currentSession].user);
  const navigate = useNavigate();
  const tabletSize = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const [totalCounties, setTotalCounties] = useState(0);
  const [selectedCounties, setSelectedCounties] = useState(0);
  const [filteredSelectedCountiesList, setFilteredSelectedCountiesList] =
    useState([]);
  const [unusedSubscriptionCounties, setUnusedSubscriptionCounties] =
    useState(0);
  const [isFreeToRemove, setIsFreeToRemove] = useState(false);
  const [needRemoveCountyQty, setNeedRemoveCountyQty] = useState(0);

  useEffect(() => {
    const countTotalCounties =
      (parseInt(currentUser?.tier?.additions?.county?.qty) || 0) +
      parseInt(currentUser?.tier?.county);
    const countSelectedCounties = currentUser?.user?.selectedCountiesNOC.length;
    const countUnusedSubscriptionCounties =
      countTotalCounties - countSelectedCounties;
    const checkIsFreeToRemove =
      removeCountyValue <= countUnusedSubscriptionCounties;

    setNeedRemoveCountyQty(
      Math.max(0, removeCountyValue - countUnusedSubscriptionCounties)
    );
    setTotalCounties(countTotalCounties);
    setSelectedCounties(countSelectedCounties);
    setUnusedSubscriptionCounties(countUnusedSubscriptionCounties);
    setIsFreeToRemove(checkIsFreeToRemove);

    const unlockedCounties = currentUser?.user?.selectedCountiesNOC.filter(
      (county) => !county.locked
    );
    const lockedCounties = currentUser?.user?.selectedCountiesNOC.filter(
      (county) => county.locked
    );
    const neededCounties = Math.max(
      0,
      removeCountyValue - countUnusedSubscriptionCounties
    );

    // Determine which counties to include in the filtered list
    let filteredSelectedCounties;

    if (neededCounties <= unlockedCounties.length) {
      // If unlocked counties are sufficient, use only the unlocked ones
      filteredSelectedCounties = unlockedCounties;
    } else {
      // If unlocked counties are not enough, add some locked counties to meet the requirement
      const remainingCount = neededCounties - unlockedCounties.length;
      filteredSelectedCounties = [
        ...unlockedCounties,
        ...lockedCounties.slice(0, remainingCount),
      ];
    }
    // Set the filtered selected counties list
    setFilteredSelectedCountiesList(filteredSelectedCounties);
  }, [currentUser?.tier, currentUser?.user, removeCountyValue]);

  const handleIncrement = () => {
    if (
      removeCountyValue < parseInt(currentUser?.tier?.additions?.county?.qty)
    ) {
      setRemoveCountyValue(removeCountyValue + 1);
    }
  };

  const handleDecrement = () => {
    if (removeCountyValue > 0) {
      setRemoveCountyValue(removeCountyValue - 1);
      if (removeCounties.length > removeCountyValue - 1) {
        removeCounties.splice(-1, 1);
      }
    }
  };

  const handleChange = (e) => {
    const inputValue = e.target.value;
    if (
      !isNaN(inputValue) &&
      inputValue >= 0 &&
      inputValue <= parseInt(currentUser?.tier?.additions?.county?.qty)
    ) {
      setRemoveCountyValue(parseInt(inputValue, 10));
    }
  };

  return (
    <div>
      <Dialog
        open={open}
        fullWidth
        maxWidth={tabletSize ? false : undefined}
        aria-labelledby="responsive-dialog-title"
        PaperProps={{
          className: "popupGrow", // Apply the animation class dynamically
          style: tabletSize
            ? {
                width: "100%", // Set Paper width to 100%
                maxWidth: "100%", // Ensure it does not exceed 100%
                height: "auto", // Optional: in case you also want full height
                margin: 0, // Remove margins to ensure it fits to the edge
              }
            : {},
        }}
      >
        <DialogTitle
          color="primary"
          variant="h4"
          id="responsive-dialog-title"
          sx={{ textAlign: "center", mt: 1 }}
        >
          Remove Counties from Your Subscription
        </DialogTitle>
        <Box sx={{ display: "flex", justifyContent: "center", mt: -2 }}>
          <img
            alt="avatar_circle"
            src="/img/website_img/pp_avatar_circle.png"
            style={{ width: "10rem", height: "auto", zIndex: 2 }}
          />
        </Box>
        <DialogContent sx={{ px: 2 }}>
          <Box>
            <DialogContentText sx={{ mb: 0, mt: -3, textAlign: "center" }}>
              You are about to remove counties from your subscription. Once
              removed, these counties will no longer be accessible under your
              monthly subscription, and the corresponding charges will be
              adjusted accordingly.
            </DialogContentText>

            <DialogContentText
              sx={{ mb: 1, textAlign: "center" }}
              color="primary"
            >
              You can remove up to{" "}
              <span
                style={{
                  fontSize: "24px",
                  color:
                    parseInt(currentUser?.tier?.additions?.county?.qty) -
                      (isNaN(removeCountyValue) ? 0 : removeCountyValue) >
                    0
                      ? "#0a885e"
                      : "#7a211b",
                  fontWeight: "bold",
                }}
              >
                {parseInt(currentUser?.tier?.additions?.county?.qty) -
                  (isNaN(removeCountyValue) ? 0 : removeCountyValue)}
              </span>{" "}
              counties from your plan.
            </DialogContentText>
            <Typography
              align="center"
              variant="body2"
              fontWeight="bold"
              color="primary"
              mb={0.5}
            >
              Number of Counties to Remove:
            </Typography>
            <Box
              sx={{ display: "flex", gap: 1, justifyContent: "center", mb: 0 }}
            >
              {/* Decrement Button */}
              <Button
                variant="contained"
                size="small"
                onClick={handleDecrement}
                disabled={removeCountyValue <= 0 || removeCountyLoading} // Disable when value is 0
                sx={{
                  minWidth: "40px",
                  height: "40px",
                  fontSize: "18px",
                  fontWeight: "bold",
                  backgroundColor: "#004976",
                  color: "#fff",
                  "&:hover": {
                    backgroundColor: "#003456",
                  },
                }}
              >
                -
              </Button>
              <TextField
                onClick={() => {}}
                variant="outlined"
                type="number"
                value={removeCountyValue}
                onChange={handleChange}
                slotProps={{
                  input: {
                    readOnly: true,
                    tabIndex: -1,
                    min: 0,
                    max: parseInt(currentUser?.tier?.additions?.county?.qty),
                    step: 1,
                  },
                }}
                sx={{
                  width: "58px",
                  textAlign: "center",
                  pointerEvents: "none",
                  "& .MuiInputBase-input": {
                    fontWeight: "bold",
                    fontSize: "28px",
                    textAlign: "center",
                    cursor: "default",
                    backgroundColor: "transparent",
                  },
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: "transparent", // Prevent hover background change
                    "&:hover": {
                      backgroundColor: "transparent", // Ensure hover background is transparent
                    },
                    "& fieldset": {
                      border: "4px solid rgba(0, 73, 118, 1)",
                    },
                    "&:hover fieldset": {
                      border: "4px solid rgba(0, 73, 118, 1)",
                    },
                    "&.Mui-focused fieldset": {
                      border: "2px solid rgba(0, 73, 118, 1) !important", // Prevent focus border change
                    },
                  },
                }}
              />
              {/* Increment Button */}
              <Button
                variant="contained"
                size="small"
                onClick={handleIncrement}
                disabled={
                  removeCountyValue >=
                    parseInt(currentUser?.tier?.additions?.county?.qty) ||
                  removeCountyLoading
                } // Disable when max reached
                sx={{
                  minWidth: "40px",
                  height: "40px",
                  fontSize: "18px",
                  fontWeight: "bold",
                  backgroundColor: "#004976",
                  color: "#fff",
                  "&:hover": {
                    backgroundColor: "#003456",
                  },
                }}
              >
                +
              </Button>
            </Box>
            {!isFreeToRemove ? (
              <Box>
                <Typography
                  variant="body2"
                  color="primary"
                  fontWeight="bold"
                  align="center"
                  px={2}
                  pb={1}
                >
                  You need to deselect{" "}
                  <span
                    style={{
                      fontSize: "24px",
                      color:
                        needRemoveCountyQty - removeCounties.length > 0
                          ? "#7a211b"
                          : "#0a885e",
                      fontWeight: "bold",
                    }}
                  >
                    {needRemoveCountyQty - removeCounties.length}
                  </span>{" "}
                  selected{" "}
                  {needRemoveCountyQty - removeCounties.length <= 1
                    ? "county"
                    : "counties"}{" "}
                  to continue. This is required because you are canceling your
                  paid subscription, but some of these counties are already in
                  use
                </Typography>

                <Box>
                  <FormControl variant="filled" fullWidth>
                    <InputLabel id="county-select-label">
                      Select Counties
                    </InputLabel>
                    <Select
                      labelId="county-select-label"
                      id="county-select"
                      multiple
                      value={removeCounties}
                      onChange={() => {}} // Prevent the default behavior of Select's onChange
                      MenuProps={{
                        PaperProps: {
                          style: {
                            marginTop: "1.5px",
                            maxHeight: 170, // Set the desired max height for the dropdown menu
                            width: "auto",
                            maxWidth: "100%",
                          },
                        },
                      }}
                      sx={{
                        maxWidth: "100%", // Set the desired max-width for the input element
                        "& .MuiTypography-body2": {
                          whiteSpace: "wrap", // Prevent text from wrapping or shrinking
                        },
                      }}
                      renderValue={(selected) =>
                        selected
                          .map(
                            (name) =>
                              filteredSelectedCountiesList.find(
                                (c) => c.name === name
                              )?.name
                          )
                          .join(", ")
                      }
                    >
                      {filteredSelectedCountiesList.map((county) => (
                        <MenuItem
                          key={county.name}
                          value={county.name}
                          onClick={() => {
                            const isSelected = removeCounties.includes(
                              county.name
                            );

                            // Toggle the selection
                            if (isSelected) {
                              setRemoveCounties(
                                removeCounties.filter(
                                  (name) => name !== county.name
                                )
                              );
                            } else if (
                              removeCounties.length < needRemoveCountyQty
                            ) {
                              setRemoveCounties([
                                ...removeCounties,
                                county.name,
                              ]);
                            }
                          }}
                        >
                          <Checkbox
                            checked={removeCounties.includes(county.name)} // Reflect the current state
                          />
                          <ListItemText primary={county.name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </Box>
            ) : (
              <></>
            )}
          </Box>

          <Box
            sx={{
              gap: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "center",
              mt: 2,
            }}
          >
            <LoadingButton
              loading={removeCountyLoading}
              disabled={
                removeCountyValue <= 0 ||
                needRemoveCountyQty - removeCounties.length > 0
              }
              color="error"
              onClick={() => {
                handleRemoveCounty();
              }}
              fullWidth
              variant="contained"
            >
              {removeCountyValue > 1
                ? "REMOVE ADDITIONAL COUNTIES"
                : "REMOVE ADDITIONAL COUNTY"}
            </LoadingButton>
            <Button
              disabled={removeCountyLoading}
              onClick={() => {
                setOpen(false);
                setRemoveCountyValue(0);
                setRemoveCounties([]);
              }}
              color="error"
              fullWidth
            >
              Cancel
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default RemoveExtraCounty;
