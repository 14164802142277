import React from "react";
import { Modal, Box, TextField, Button } from "@mui/material";

const AdminLoginModal = ({
  title,
  open,
  setOpen,
  onClose,
  adminEmail,
  setAdminEmail,
  adminSecret,
  setAdminSecret,
}) => {
  const handleEmailChange = (event) => setAdminEmail(event.target.value);
  const handleSecretChange = (event) => setAdminSecret(event.target.value);

  const handleSubmit = () => {
    setOpen(false);
  };

  return (
    <Modal open={open} onClose={onClose} aria-labelledby="admin-login-modal">
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
        }}
      >
        <h2 id="admin-login-modal">{title}</h2>

        {/* Email Input */}
        <TextField
          fullWidth
          margin="normal"
          label="Email"
          variant="filled"
          value={adminEmail}
          onChange={handleEmailChange}
        />

        {/* Secret Key Input */}
        <TextField
          fullWidth
          margin="normal"
          label="Secret Key"
          variant="filled"
          type="password"
          value={adminSecret}
          onChange={handleSecretChange}
        />

        {/* Submit Button */}
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          sx={{ mt: 2 }}
          fullWidth
        >
          Submit
        </Button>
      </Box>
    </Modal>
  );
};

export default AdminLoginModal;
