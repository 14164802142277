import { LoadingButton } from "@mui/lab";
import { useFormikContext } from "formik";
import {
  Stack,
  Typography,
  Button,
  Grid,
  useMediaQuery,
  useTheme,
  Box,
} from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const ChangePlanFormNavigation = (props) => {
  const handleLoading = () => {
    if (props?.isLastStep) {
      return (
        <LoadingButton
          disabled={
            props?.currentUser?.user?.contractors.length -
              props?.deleteLicenses.length >
            parseInt(props?.selectedPlan?.license)
          }
          fullWidth={props?.mobileSize}
          type="submit"
          color={props?.isLastStep && !props?.isExpired ? "success" : "primary"}
          sx={{ minWidth: "10rem" }}
          variant="contained"
          loading={props?.isLoad}
          size={props?.mobileSize ? "small" : "Large"}
          endIcon={
            !props?.isLastStep ? (
              <ArrowForwardIosIcon sx={{ fontSize: "18px" }} />
            ) : undefined
          }
        >
          {handleLastStep()}
        </LoadingButton>
      );
    } else {
      return (
        <LoadingButton
          disabled={
            (props?.type === "change" &&
              props?.selectedName === props?.oldPlan &&
              props?.checked === props?.oldChecked) ||
            !props?.selectedPlan ||
            props?.isLoad
          }
          loading={props?.nextLoad}
          type="submit"
          color={props?.isLastStep && !props?.isExpired ? "success" : "primary"}
          fullWidth={props?.mobileSize}
          sx={{ minWidth: "10rem" }}
          variant="contained"
          size={props?.mobileSize ? "small" : "Large"}
          endIcon={
            !props?.isLastStep ? (
              <ArrowForwardIosIcon sx={{ fontSize: "18px" }} />
            ) : undefined
          }
        >
          {handleLastStep()}
        </LoadingButton>
      );
    }
  };

  const handleLastStep = () => {
    if (props?.isLastStep) {
      if (props?.isExpired) {
        return "PAY";
      } else {
        // return "SIGN UP AND PAY NOTHING TODAY";
        return "PROCEED";
      }
    } else {
      return "Next";
    }
  };

  return (
    <>
      <Box
        sx={{
          position: "sticky",
          bottom: 0,
          lef: 0,
          pb: 3,
          pt: 0.2,
          backgroundColor: "#fff",
          zIndex: 9999,
        }}
      >
        <Typography
          variant="body1"
          fontWeight="bold"
          textAlign="center"
          color="#F04438"
          mb={1}
          sx={{ minHeight: props?.mobileSize ? "0rem" : "1.5rem" }}
        >
          {props?.errorText}
        </Typography>
        <Box
          gap={1}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <>
            <Button
              variant="contained"
              disabled={!props?.hasPrevious || props?.isLoad}
              onClick={props?.hasPrevious ? props?.onBackClick : undefined}
              type="button"
              sx={{ minWidth: "10rem" }}
              size={props?.mobileSize ? "small" : "Large"}
              startIcon={
                props?.hasPrevious ? (
                  <ArrowBackIosNewIcon sx={{ fontSize: "18px" }} />
                ) : undefined
              }
            >
              Back
            </Button>

            {props?.cardRemain !== 0 &&
            props?.stepNumber === 2 &&
            props?.isSSOLogin === true ? (
              <Button
                disabled={
                  (props?.isLastStep &&
                    props?.deleteLicenses?.includes(props?.currLicense)) ||
                  props?.isLoad
                }
                type="submit"
                color={
                  props?.isLastStep && !props?.isExpired ? "success" : "primary"
                }
                variant="contained"
                size={props?.mobileSize ? "small" : "Large"}
                sx={{ minWidth: "10rem" }}
                endIcon={
                  !props?.isLastStep ? (
                    <ArrowForwardIosIcon sx={{ fontSize: "18px" }} />
                  ) : undefined
                }
              >
                {handleLastStep()}
              </Button>
            ) : (
              handleLoading()
            )}
          </>
        </Box>
      </Box>
    </>
  );
};

export default ChangePlanFormNavigation;
