import React from "react";
import { Grid2 as Grid } from "@mui/material";
import NewRegister from "../auth/registerSteps/NewRegister";

const Register = () => {
  return (
    <Grid container>
      <Grid size={{ xs: 12 }}>
        <NewRegister />
      </Grid>
    </Grid>
  );
};

export default Register;
